import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export const LocationController: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { t } = useTranslation(['common'])
  const context = useLeafletContext()
  const container = context.layerContainer || context.map
  const locationControl = new L.Control({ position: 'topleft' })
  locationControl.onAdd = function () {
    const div = L.DomUtil.create('div', 'leaflet-control-layers leaflet-control')
    div.innerHTML = '<img style="width: 30px; height: 30px; padding: 7px;cursor: pointer;" src="/icons/marker.svg"/>'
    div.onclick =
      onClick ??
      (() => {
        toast('Not implemented yet', { type: 'info' })
      })
    return div
  }
  useEffect(() => {
    locationControl.addTo(container as any)
  }, [])
  return <></>
}
