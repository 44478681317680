import { Collapse, IconButton, Menu, MenuItem, styled, Tooltip, Typography, useTheme } from '@mui/material'
import { SvgIconComponent } from '@mui/icons-material'
import { Box } from '@mui/material'
import { IconType } from 'react-icons/lib'
import classNames from 'classnames'
import { IMenuItem } from 'src/screens/localities/LocalitiesSidebar'
import { Fragment, useEffect, useState } from 'react'
import { Chevron } from './CustomerHeader'
import { Flex } from 'src/styles/flexComponent'
import { ReactComponent as ArrayDown } from 'src/assets/svg/array-drop-down-h.svg'
import { useLocation } from 'react-router-dom'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { Spinner } from '../Spinner'
import { formatNumber } from 'src/utils/function'
import { useTranslation } from 'react-i18next'

interface MenuItemTemplateProps {
  title: string
  Icon:
    | SvgIconComponent
    | IconType
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string
        }
      >
  isMinified: boolean
  onClick?: (e) => void
  active?: boolean
  disabled?: boolean
  className?: string
  count?: number
  countBgColor?: string
  children?: IMenuItem[]
  isLoading?: boolean
}

export const MenuItemTemplate = ({
  title,
  Icon,
  active,
  onClick,
  isMinified,
  count,
  countBgColor,
  children,
  isLoading,
}: MenuItemTemplateProps) => {
  const theme = useTheme()
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(active)
  const { t } = useTranslation()

  useEffect(() => {
    setIsSubMenuOpen(active)
  }, [active])

  return (
    <>
      {!isMinified && (
        <Fragment>
          <MenuItemCard
            active={active ?? false}
            className={classNames(!active && 'hover:bg-gray-200')}
            onClick={
              children && children.length > 0
                ? e => {
                    if (!active) {
                      onClick && onClick(e)
                    }
                    setIsSubMenuOpen(!isSubMenuOpen)
                  }
                : onClick
            }
          >
            <Flex.Row justifyContent={'space-between'}>
              <Flex.Row>
                <Icon className={title === t('services', { ns: 'sidebar' }) && active ? 'sidebar-service-icon' : ''} />
                <Typography
                  sx={{ paddingLeft: '12px', fontWeight: 600, minWidth: 202 }}
                  lineHeight={'26px'}
                  variant="subtitle1"
                >
                  {title}
                </Typography>
              </Flex.Row>
              <Box sx={{ marginRight: '12px', marginLeft: 'auto' }}>
                {count !== undefined &&
                  (isLoading ? (
                    <Spinner size={'15px'} />
                  ) : count != 0 ? (
                    <Box
                      height={'28px'}
                      minWidth={'32px'}
                      paddingX={'4px'}
                      sx={{
                        backgroundColor: countBgColor,
                        borderRadius: '6px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant={count.toString().length > 3 ? 'body2' : 'subtitle1'}
                        color={getThemeColor(theme, MUIThemeColors.white)}
                      >
                        {formatNumber(count)}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  ))}
              </Box>
              {children && children.length > 0 && (
                <Chevron
                  onClick={() => {
                    setIsSubMenuOpen(!isSubMenuOpen)
                  }}
                  sx={{ paddingX: '12px' }}
                >
                  {isSubMenuOpen ? <ArrayDown /> : <ArrayDown transform="rotate(-90)" />}
                </Chevron>
              )}
            </Flex.Row>
          </MenuItemCard>
          {children && children.length > 0 && (
            <Collapse in={isSubMenuOpen}>
              <ChildrenItems items={children} />
            </Collapse>
          )}
        </Fragment>
      )}

      {isMinified && (
        <MiniFiedItem
          title={title}
          Icon={Icon}
          onClick={onClick}
          active={active}
          count={count}
          countBgColor={countBgColor}
          subItems={children}
        />
      )}
    </>
  )
}

const ChildrenItems = ({ items }) => {
  const { pathname } = useLocation()
  const basePath = !!pathname && pathname.split('/')[7]
  const theme = useTheme()
  return (
    <>
      {items.map((child, index) => {
        return (
          <Flex.Row
            key={index}
            className={classNames(!(basePath == child?.path) && 'hover:bg-gray-200')}
            sx={{
              padding: '15px 0px 14px 30px',
              backgroundColor:
                basePath == child?.path ? theme.palette.secondaryLight[theme.palette.mode] : 'transparent',
              color:
                basePath == child?.path
                  ? theme.palette.secondaryDark[theme.palette.mode]
                  : getThemeColor(theme, MUIThemeColors.primaryDark),
            }}
            onClick={child.onClick}
            style={{ cursor: 'pointer' }}
            justifyContent={'space-between'}
          >
            <Typography style={{ paddingLeft: '22px', fontWeight: 600 }} variant="subtitle1">
              {child.title}
            </Typography>
          </Flex.Row>
        )
      })}
    </>
  )
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  overflow: 'visible',
  '& .MuiMenu-list': {
    padding: 0,
  },
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(50%) rotate(45deg)',
    zIndex: 0,
  },
}))

const MiniFiedItem = ({ title, Icon, onClick, active, count, countBgColor, subItems }) => {
  const { pathname } = useLocation()
  const basePath = !!pathname && pathname.split('/')[7]
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const { t } = useTranslation()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        width={'40px'}
        height={'40px'}
        bgcolor={active ? getThemeColor(theme, MUIThemeColors.primaryLight) : 'transparent'}
      >
        <Tooltip title={title}>
          <IconButton
            id="children-menu-button"
            aria-controls={open ? 'children-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={subItems && subItems.length > 0 ? handleClick : onClick}
          >
            <Icon
              fontSize="medium"
              className={
                active
                  ? title === t('services', { ns: 'sidebar' })
                    ? 'sidebar-service-icon'
                    : 'text-white'
                  : 'text-black'
              }
            ></Icon>
          </IconButton>
        </Tooltip>
      </Box>
      <StyledMenu
        id="children-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <Box
          className={classNames(!active && 'hover:bg-gray-200')}
          sx={{
            paddingX: '12px',
            color: getThemeColor(theme, MUIThemeColors.primaryDark),
            backgroundColor: active
              ? getThemeColor(theme, MUIThemeColors.primaryLight)
              : getThemeColor(theme, MUIThemeColors.white),
            ':hover': {
              backgroundColor: active
                ? getThemeColor(theme, MUIThemeColors.primaryLight)
                : getThemeColor(theme, MUIThemeColors.white),
            },
          }}
          height={'40px'}
        >
          <Flex.Row justifyContent={'space-between'} alignItems={'center'}>
            <Flex.Row justifyContent={'space-between'} alignItems={'center'} sx={{ minWidth: 180 }} paddingTop={'4px'}>
              <Typography
                variant="subtitle1"
                color={
                  active ? getThemeColor(theme, MUIThemeColors.white) : getThemeColor(theme, MUIThemeColors.primaryMain)
                }
              >
                {title}
              </Typography>
            </Flex.Row>
            {count !== undefined && (
              <Box
                height={28}
                width={32}
                sx={{
                  backgroundColor: countBgColor,
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle1" color={getThemeColor(theme, MUIThemeColors.primaryMain)}>
                  {count}
                </Typography>
              </Box>
            )}
          </Flex.Row>
        </Box>
        {subItems &&
          subItems.length > 0 &&
          subItems.map((child, index) => {
            return (
              <MenuItem
                sx={{
                  paddingX: '12px',
                  backgroundColor:
                    basePath == child?.path
                      ? getThemeColor(theme, MUIThemeColors.secondaryLight)
                      : getThemeColor(theme, MUIThemeColors.white),
                }}
                key={index}
                onClick={e => {
                  child.onClick(e)
                  handleClose()
                }}
              >
                <Typography variant="subtitle1">{child?.title}</Typography>
              </MenuItem>
            )
          })}
      </StyledMenu>
    </>
  )
}

export default MenuItemTemplate

//styled components
export const MenuItemCard = styled(Box)<{ active: boolean }>`
  padding: 10px 0px 9px 12px;
  background-color: ${props =>
    props.active
      ? getThemeColor(props.theme, MUIThemeColors.primaryMain)
      : getThemeColor(props.theme, MUIThemeColors.backgroundMain)};
  color: ${props =>
    props.active
      ? getThemeColor(props.theme, MUIThemeColors.white)
      : getThemeColor(props.theme, MUIThemeColors.primaryDark)};
  border: ${props => '1px solid ' + getThemeColor(props.theme, MUIThemeColors.secondaryLight)};
  cursor: pointer;
  border-radius: 4px;
  height: 48px;
`
