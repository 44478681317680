import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FC } from 'react'
import { t } from 'i18next'
import { MdNavigateNext } from 'react-icons/md'
import { AppRoutesNavigation } from '../../../app-routes'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { permissionBinding, permissionLevel } from '../../../constant'
import { FacilityTypes, RoleTypes } from '../../../enums'
import { UseQueryResult } from 'react-query'
import { invalidateLocalityGroupsQueries } from '../../../api/locality-groups'
import { invalidateServiceStationCache } from '../../../api/service-station'
import { invalidateServiceStationGroupsQueries } from '../../../api/service-station-group'
import {
  invalidateAccessGroups,
  useLocalities,
  useLocalityGroups,
  useServiceStationGroups,
  useServiceStations,
} from '../../../api/access-group'
import { FacilitySection } from './FacilitySection'
import { invalidateLocalityCache } from '../../../api/localities'
import { AssignUsers } from '@lovoldsystem/authorization.client'
import { FunctionElementsParentsIds } from '../../../enums/auth'

interface INavigationState {
  name: string
  isGlobalServiceProvider: boolean
}

export const UserResources: FC<{ isAdminLayout?: boolean }> = ({ isAdminLayout }) => {
  const { id, userId } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { showDialog } = useDialogCtx()

  const { name, isGlobalServiceProvider } = state as INavigationState

  const breadcrumbsItems = [
    <Link
      sx={{ cursor: 'pointer' }}
      underline="hover"
      key="1"
      color="inherit"
      onClick={e => {
        e.stopPropagation()
        isAdminLayout ? navigate(-1) : navigate(AppRoutesNavigation.Customer.users(id as EntityId))
      }}
    >
      {t('users', { ns: 'users' })}
    </Link>,
    <Typography key="4" color="text.primary">
      {name}
    </Typography>,
  ]

  const handleResourceAdd = (
    title: string,
    dropDownText: string,
    type: FacilityTypes,
    roleTypes: Array<RoleTypes>,
    assignCompleted: () => void,
    useGetAssignedTo: ({ clientId }: { clientId: any }) => UseQueryResult<any, Error>,
    functionElementParents: number[],
  ) => {
    showDialog(
      AssignUsers,
      {
        title: title,
        componentProps: {
          initialValues: {
            userName: name,
            userId: userId,
            type: type,
          },
          clientId: id as any,
          assignToText: dropDownText,
          roleTypes: roleTypes,
          functionElementParents: functionElementParents,
          assignCompleted: assignCompleted,
          permissionLevel: permissionLevel,
          permissionBinding: permissionBinding,
          useGetAssignedTo: useGetAssignedTo,
          assignToAlert: t('permission-types', { ns: 'user-resources' }),
          isFacilityRequired: true,
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
    )
  }

  const handleLocalityAdd = () => {
    handleResourceAdd(
      t('assign-localities', { ns: 'user-resources' }),
      t('select-localities', { ns: 'user-resources' }),
      FacilityTypes.locality,
      [RoleTypes.Locality],
      () => {
        invalidateLocalityCache.getLocalities(id as EntityId)
        invalidateAccessGroups.getFacilities({
          clientId: id as EntityId,
          facilityType: FacilityTypes.locality,
          userId: userId,
        })
      },
      useLocalities,
      [FunctionElementsParentsIds.LocalityPortal, FunctionElementsParentsIds.SystemPortal],
    )
  }

  const handleLocalityGroupAdd = () => {
    handleResourceAdd(
      t('assign-locality-groups', { ns: 'user-resources' }),
      t('select-locality-groups', { ns: 'user-resources' }),
      FacilityTypes.localityGroup,
      [RoleTypes.Locality],
      () => {
        invalidateLocalityGroupsQueries.invalidateGetLocalityGroups(id as EntityId)
        invalidateAccessGroups.getFacilities({
          clientId: id as EntityId,
          facilityType: FacilityTypes.localityGroup,
          userId: userId,
        })
      },
      useLocalityGroups,
      [FunctionElementsParentsIds.LocalityPortal, FunctionElementsParentsIds.SystemPortal],
    )
  }

  const handleServiceStationAdd = () => {
    handleResourceAdd(
      t('assign-service-stations', { ns: 'user-resources' }),
      t('select-service-stations', { ns: 'user-resources' }),
      FacilityTypes.serviceStation,
      [RoleTypes.ServiceStation],
      () => {
        invalidateServiceStationCache.getAllTypeServiceStations(id as EntityId)
        invalidateAccessGroups.getFacilities({
          clientId: id as EntityId,
          facilityType: FacilityTypes.serviceStation,
          userId: userId,
        })
      },
      useServiceStations,
      [FunctionElementsParentsIds.ServiceStationPortal, FunctionElementsParentsIds.SystemPortal],
    )
  }

  const handleServiceStationGroupAdd = () => {
    handleResourceAdd(
      t('assign-service-station-groups', { ns: 'user-resources' }),
      t('select-service-station-groups', { ns: 'user-resources' }),
      FacilityTypes.serviceStationGroup,
      [RoleTypes.ServiceStation],
      () => {
        invalidateServiceStationGroupsQueries.getServiceStationGroups(id as EntityId)
        invalidateAccessGroups.getFacilities({
          clientId: id as EntityId,
          facilityType: FacilityTypes.serviceStationGroup,
          userId: userId,
        })
      },
      useServiceStationGroups,
      [FunctionElementsParentsIds.ServiceStationPortal, FunctionElementsParentsIds.SystemPortal],
    )
  }

  return (
    <Box className="p-3 mb-2 bg-white shadow-md" borderRadius={2}>
      <Box className="flex items-center justify-between px-4 py-2 mb-2" borderRadius={2}>
        <Breadcrumbs separator={<MdNavigateNext size={20} />} aria-label="breadcrumb">
          {breadcrumbsItems}
        </Breadcrumbs>
      </Box>

      {userId && (
        <>
          {!isGlobalServiceProvider && (
            <>
              <FacilitySection
                title={t('localities', { ns: 'user-resources' })}
                handleAdd={handleLocalityAdd}
                clientId={id as EntityId}
                userId={userId}
                facilityType={FacilityTypes.locality}
                roleTypes={[RoleTypes.Locality]}
                functionElementParents={[
                  FunctionElementsParentsIds.LocalityPortal,
                  FunctionElementsParentsIds.SystemPortal,
                ]}
              />
              <FacilitySection
                title={t('locality-groups', { ns: 'user-resources' })}
                handleAdd={handleLocalityGroupAdd}
                clientId={id as EntityId}
                userId={userId}
                facilityType={FacilityTypes.localityGroup}
                roleTypes={[RoleTypes.Locality]}
                functionElementParents={[
                  FunctionElementsParentsIds.LocalityPortal,
                  FunctionElementsParentsIds.SystemPortal,
                ]}
              />
            </>
          )}

          <FacilitySection
            title={t('service-stations', { ns: 'user-resources' })}
            handleAdd={handleServiceStationAdd}
            clientId={id as EntityId}
            userId={userId}
            facilityType={FacilityTypes.serviceStation}
            roleTypes={[RoleTypes.ServiceStation]}
            functionElementParents={[
              FunctionElementsParentsIds.ServiceStationPortal,
              FunctionElementsParentsIds.SystemPortal,
            ]}
          />
          <FacilitySection
            title={t('service-station-groups', { ns: 'user-resources' })}
            handleAdd={handleServiceStationGroupAdd}
            clientId={id as EntityId}
            userId={userId}
            facilityType={FacilityTypes.serviceStationGroup}
            roleTypes={[RoleTypes.ServiceStation]}
            functionElementParents={[
              FunctionElementsParentsIds.ServiceStationPortal,
              FunctionElementsParentsIds.SystemPortal,
            ]}
          />
        </>
      )}
    </Box>
  )
}
