import { IMooring, IPositionType } from '../generated-types'
import {
  ICreateLocalityFrequency,
  IUpdateLocalityFrequency,
  ILocalityFrequency,
  IPositionService,
  IUpdatePositionService,
  ILocalityFrequencyGroup,
  ILastAndNextLocalityFrequencies,
} from '../generated-types/locality-frequency'
import { queryClient } from '../query-client'
import { localityFrequencyQueryKeys } from '../query-keys'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePatch, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId, localityId: EntityId) =>
  `customers/${customerId}/localities/${localityId}/locality-frequencies`

export const localityFrequenciesEndpoints = {
  getLocalityFrequencies(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}`
  },
  getAllForLocality(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/getAllForLocality`
  },
  getLocalityFrequency(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId, localityId)}/${id}`
  },
  getLocalityFrequencyGroup(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/group`
  },
  getLocalityFrequencyMapPositions(
    customerId: EntityId,
    localityId: EntityId,
    localityFrequencyIds: Array<number | undefined>,
  ) {
    return `${baseUrl(customerId, localityId)}/get-map-positions?localityFrequencyIds=${localityFrequencyIds.join(
      '&localityFrequencyIds=',
    )}`
  },
  getLastAndNextLocalityFrequencies(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId, localityId)}/get-last-and-next-locality-services?mooringId=${mooringId}`
  },
  getNextLocalityFrequencies(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/get-next-locality-services`
  },
  createLocalityFrequency(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}`
  },
  deleteLocalityFrequency(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}`
  },
  updateLocalityFrequency(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}`
  },
  getLocalityFrequencyPositions(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/positions`
  },
  getLocalityFrequencyPositionTypes(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/positionTypes`
  },
  groupLocalityFrequencies(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/group-locality-frequencies`
  },
  splitLocalityFrequenciesGroup(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/split-locality-frequencies`
  },
  updateLocalityFrequencyPositions(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId, localityId)}/positions`
  },
}

export const invalidateLocalityFrequencyCache = {
  getAllLocalityFrequencies(customerId?: EntityId, localityId?: EntityId) {
    queryClient.invalidateQueries(localityFrequencyQueryKeys.GET_LOCALITY_FREQUENCIES(customerId, localityId))
  },
  useGetLocalityFrequency(
    customerId?: EntityId,
    localityId?: EntityId,
    queryParams?: { id?: number | EntityId; groupId?: string },
  ) {
    queryClient.invalidateQueries(
      localityFrequencyQueryKeys.GET_LOCALITY_FREQUENCY(customerId, localityId, queryParams),
    )
  },
  getNextLocalityFrequencies(customerId: EntityId, localityId: EntityId) {
    queryClient.invalidateQueries([localityFrequenciesEndpoints.getNextLocalityFrequencies(customerId, localityId)])
  },
  getLastAndNextFrequencies(customerId : EntityId, localityId: EntityId , mooringId: EntityId){
  queryClient.invalidateQueries([localityFrequenciesEndpoints.getLastAndNextLocalityFrequencies(customerId, localityId,mooringId)])
  },
  getLocalityFrequenciesAndDetails(customerId?: EntityId, localityId?: EntityId) {
    queryClient.invalidateQueries(localityFrequencyQueryKeys.GET_LOCALITY_FREQUENCIES(customerId, localityId))
    queryClient.invalidateQueries(localityFrequencyQueryKeys.GET_LOCALITY_FREQUENCY(customerId, localityId))
  }
}

export const useGetLocalityFrequencys = (
  customerId: EntityId,
  localityId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
  queryParams?: {
    isIgnoreQueryFilter: boolean
    isOnlyCompleted: boolean
    isOnlyOngoing: boolean
  },
  isCustomQueryKey?: boolean,
) =>
  useGet<IPaginatedResult<ILocalityFrequency>>(
    localityFrequenciesEndpoints.getLocalityFrequencies(customerId, localityId),
    {
      page,
      pageSize,
      ...queryParams,
    },
    {
      queryKey: isCustomQueryKey
        ? localityFrequencyQueryKeys.GET_LOCALITY_FREQUENCIES(customerId, localityId, {
            page,
            pageSize: pageSize,
            ...queryParams,
          })
        : localityFrequencyQueryKeys.GET_LOCALITY_FREQUENCIES(customerId),
    },
  )

export const useGetLocalityFrequency = (
  customerId: EntityId,
  localityId: EntityId,
  id: EntityId,
  queryParams?: { isIgnoreQueryFilter?: boolean },
) =>
  useGet<ILocalityFrequency>(
    localityFrequenciesEndpoints.getLocalityFrequency(customerId, localityId, id),
    queryParams,
    { queryKey: localityFrequencyQueryKeys.GET_LOCALITY_FREQUENCY(customerId, localityId, { id }) },
  )

export const useGetLocalityFrequencyGroup = (
  customerId: EntityId,
  localityId: EntityId,
  queryParams: { groupId?: string },
) =>
  useGet<ILocalityFrequencyGroup>(
    localityFrequenciesEndpoints.getLocalityFrequencyGroup(customerId, localityId),
    queryParams,
    {
      queryKey: localityFrequencyQueryKeys.GET_LOCALITY_FREQUENCY(customerId, localityId, {
        groupId: queryParams.groupId,
      }),
    },
  )
export const useGetLocalityFrequencyMapPositions = (
  customerId: EntityId,
  localityId: EntityId,
  localityFrequencyIds: Array<number | undefined>,
) =>
  useGet<IMooring>(
    localityFrequenciesEndpoints.getLocalityFrequencyMapPositions(customerId, localityId, localityFrequencyIds),
  )

export const useAddLocalityFrequency = (customerId: EntityId, localityId: EntityId) =>
  usePost<ILocalityFrequency, ICreateLocalityFrequency>(
    localityFrequenciesEndpoints.createLocalityFrequency(customerId, localityId),
  )

export const useGroupLocalityFrequencies = (customerId: EntityId, localityId: EntityId) =>
  usePatch<ILocalityFrequency, Array<number>>(
    localityFrequenciesEndpoints.groupLocalityFrequencies(customerId, localityId),
  )

export const useSplitLocalityFrequenciesGroup = (customerId: EntityId, localityId: EntityId) =>
  usePatch<ILocalityFrequency, { groupId: string }>(
    localityFrequenciesEndpoints.splitLocalityFrequenciesGroup(customerId, localityId),
  )

export const useDeleteLocalityFrequency = (customerId: EntityId, localityId: EntityId) =>
  useDelete<ILocalityFrequency>(localityFrequenciesEndpoints.deleteLocalityFrequency(customerId, localityId))

export const useGetLocalityFrequencyPositions = (
  customerId: EntityId,
  localityId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
  queryParams?: { groupId?: string; localityFrequencyId?: EntityId },
) =>
  useGet<IPaginatedResult<IPositionService>>(
    localityFrequenciesEndpoints.getLocalityFrequencyPositions(customerId, localityId),
    {
      ...queryParams,
      page,
      pageSize,
    },
  )

export const useGetAllFrequencyPositionTypes = (
  customerId: EntityId,
  localityId: EntityId,
  queryParams: { groupId?: string; localityFrequencyId?: EntityId },
) =>
  useGet<IPositionType[]>(
    localityFrequenciesEndpoints.getLocalityFrequencyPositionTypes(customerId, localityId),
    queryParams,
  )

export const useUpdateLocalityFrequencyPositions = (customerId: EntityId, localityId: EntityId) => {
  return usePut<IUpdatePositionService, IUpdatePositionService>(
    localityFrequenciesEndpoints.updateLocalityFrequencyPositions(customerId, localityId),
  )
}

export const useUpdateLocalityFrequency = (customerId: EntityId, localityId: EntityId) =>
  usePut<ILocalityFrequency, IUpdateLocalityFrequency>(
    localityFrequenciesEndpoints.updateLocalityFrequency(customerId, localityId),
  )

export const useGetLastAndNextLocalityFrequencies = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
  useGet<ILastAndNextLocalityFrequencies>(
    localityFrequenciesEndpoints.getLastAndNextLocalityFrequencies(customerId, localityId, mooringId),
    undefined,
    { enabled: mooringId != null },
  )

export const useGetNextLocalityFrequencies = (customerId: EntityId, localityId: EntityId) =>
  useGet<ILastAndNextLocalityFrequencies>(
    localityFrequenciesEndpoints.getNextLocalityFrequencies(customerId, localityId),
  )
