import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { ILocality, IMooring, IMooringType, IPosition } from '../../../../../generated-types'
import { LatLngExpression } from 'leaflet'
import { LocalityLayers } from './LocalityLayers'
import { FleetLayers } from './FleetLayers'
import { AquacultureLayers } from './AquacultureLayers'
import { BarentsWatchLayers } from './BarentsWatchLayers'
import { DeviationLayers } from './DeviationLayers'
import { MapTileLayers } from './tile-layers/MapTileLayers'
import { maxZoom } from '../constraints'
import { LayersControl, useMap } from 'react-leaflet'
import { IGroupSelector } from '../MapComponent'
import { getClusterArray } from '../utils'

export const ControlLayer: React.FC<{
  groupSelector: IGroupSelector
  positions?: IPosition[]
  moorings?: IMooring[]
  localities?: ILocality[]
  center?: LatLngExpression
  navigateLocalityWhenClick?: boolean
  zoom?: number
  mooringType?: IMooringType
  isModalView: boolean
  showCageStatusPopOver?: boolean
  enableCluster?: boolean
  onPositionSelect?: (positionId: number, positionName: string) => void
  enableLocality?: boolean
}> = ({
  center,
  mooringType,
  positions,
  moorings,
  localities,
  groupSelector,
  navigateLocalityWhenClick,
  isModalView,
  showCageStatusPopOver,
  onPositionSelect,
  enableCluster,
  enableLocality,
}) => {
  const map = useMap()
  const [clusterRange, setClusterRange] = useState(enableCluster ? 1.2 / map.getZoom() : 0)

  const clusters = useMemo(() => {
    return clusterRange < 0.2 || !enableCluster ? [] : [...getClusterArray(localities ?? [], clusterRange / 3)]
  }, [localities, map, moorings, positions, clusterRange])

  useLayoutEffect(() => {
    if (enableCluster) {
      map.addEventListener('zoom', () => {
        const zoomLevel = map.getZoom()
        setClusterRange(
          zoomLevel < 16 ? (zoomLevel > 6 ? (zoomLevel > 10 ? 0.05 / zoomLevel : 1.5 / zoomLevel) : 25 / zoomLevel) : 0,
        )
      })
    }
  }, [])

  const getLayers = useCallback(() => {
    return (
      <>
        {(mooringType == IMooringType.FrameCage ||
          mooringType == IMooringType.SteelCage ||
          mooringType == IMooringType.LadderCage ||
          mooringType == undefined) && (
          <LocalityLayers
            positions={positions}
            moorings={moorings}
            localities={localities}
            mapPosition={center as LatLngExpression}
            mooringType={mooringType}
            groupSelector={groupSelector}
            navigateLocalityWhenClick={navigateLocalityWhenClick}
            isModalView={isModalView}
            showCageStatusPopOver={showCageStatusPopOver}
            onPositionSelect={onPositionSelect}
            clusters={clusters}
          />
        )}
        {(mooringType == IMooringType.Barge || mooringType == undefined) && (
          <FleetLayers
            positions={positions}
            moorings={moorings}
            localities={localities}
            mapPosition={center as LatLngExpression}
            isFleet={true}
            mooringType={mooringType}
            groupSelector={groupSelector}
            navigateLocalityWhenClick={navigateLocalityWhenClick}
            isModalView={isModalView}
            onPositionSelect={onPositionSelect}
            clusters={clusters}
          />
        )}
      </>
    )
  }, [mooringType, positions, moorings, positions, groupSelector.facility, groupSelector.fleet, localities, clusters])

  return (
    <LayersControl>
      {enableLocality && getLayers()}
      <DeviationLayers
        positions={positions as IPosition[]}
        moorings={moorings as IMooring[]}
        localities={localities as ILocality[]}
        groupSelector={groupSelector}
      />
      <BarentsWatchLayers groupSelector={groupSelector} />
      <AquacultureLayers groupSelector={groupSelector} />
      <MapTileLayers maxZoom={maxZoom} />
    </LayersControl>
  )
}
