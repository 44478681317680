import { useTranslation } from 'react-i18next'
import { LayerGroup, LayersControl } from 'react-leaflet'
import { BarentsWatch } from '../BarentsWatch'
import { barentsWatchZones } from '../constraints'
import { IGroupSelector } from '../MapComponent'

export const BarentsWatchLayers: React.FC<{ groupSelector: IGroupSelector }> = ({ groupSelector }) => {
  const { t } = useTranslation(['map', 'common'])
  return (
    <>
      <LayersControl.Overlay name={`<b><u>${t('barentswatch')}</u></b>`} checked={groupSelector.barentsWatch}>
        <LayerGroup></LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.Overlay name={t('ila-combat')} checked={groupSelector.barentsWatch}>
        <LayerGroup>
          <BarentsWatch
            url={barentsWatchZones.ila_combat}
            style={{
              color: '#ffbb4f',
              weight: 1,
              pane: 'barents-watch-zones',
              attribution: `${t('ila-combat')} &copy; Barentswatch`,
            }}
            tooltipTitle={t('ila-combat')}
          />
        </LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.Overlay name={t('ila-surveillance')} checked={groupSelector.barentsWatch}>
        <LayerGroup>
          <BarentsWatch
            url={barentsWatchZones.ila_monitoring}
            style={{
              color: '#f46842',
              weight: 1,
              pane: 'barents-watch-zones',
              attribution: `${t('ila-surveillance')} &copy; Barentswatch`,
            }}
            tooltipTitle={t('ila-surveillance')}
          />
        </LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.Overlay name={t('pd-protection')} checked={groupSelector.barentsWatch}>
        <LayerGroup>
          <BarentsWatch
            url={barentsWatchZones.pd_protection}
            style={{
              color: 'darkgreen',
              weight: 1,
              pane: 'barents-watch-zones',
              attribution: `${t('pd-protection')} &copy; Barentswatch`,
            }}
            tooltipTitle={t('pd-protection')}
          />
        </LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.Overlay name={t('pd-monitoring')} checked={groupSelector.barentsWatch}>
        <LayerGroup>
          <BarentsWatch
            url={barentsWatchZones.pd_monitoring}
            style={{
              color: 'lightgreen',
              weight: 1,
              pane: 'barents-watch-zones',
              attribution: `${t('pd-monitoring')} &copy; Barentswatch`,
            }}
            tooltipTitle={t('pd-monitoring')}
          />
        </LayerGroup>
      </LayersControl.Overlay>
    </>
  )
}
