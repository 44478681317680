import React from 'react'
import { ShowDialogProps } from '../../../show-dialog'
import { Autocomplete, MenuItem } from '@mui/material'
import { invalidateMainComponentCache, useChangeCageInMainComponent } from '../../../api/main-component'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { AppForm, FormSubmitButton } from '../../../components/FormCore'
import { useZodForm } from '../../../hooks/zod-form'
import { toast } from 'react-toastify'
import { getCageName } from '../../../utils/function'
import { invalidatePositionCache, useGetCagePositionsByLocality } from '../../../api/positions'
import { IPosition } from '../../../generated-types'
import { invalidationActivityLogCache } from 'src/api/activity-log'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { AppTextField } from 'src/components/AppTextField'

interface ChangeCageDialogProps extends ShowDialogProps {
  id: EntityId
  customerId: EntityId
  localityId: EntityId
  positionId: EntityId
}

const ChangeCageDialog: React.FC<ChangeCageDialogProps> = ({ pop, id, customerId, localityId, positionId }) => {
  const { mutateAsync } = useChangeCageInMainComponent(customerId as EntityId, id as EntityId)
  const { data } = useGetCagePositionsByLocality(customerId, localityId)
  const { t } = useTranslation(['common', 'main-component'])

  const handleSubmit = async form => {
    await mutateAsync(form, {
      onSuccess: () => {
        toast.success(t('cage-change-message', { ns: 'main-component' }))
        pop()
        invalidateMainComponentCache.getMainComponentById(customerId, id)
        invalidateMainComponentCache.getMainComponents(
          customerId as EntityId,
          localityId.toString(),
          form.mooring.id.toString(),
        )
        invalidationActivityLogCache.getCageActivityLogs()
        invalidatePositionCache.getCagePositionDeviations(customerId, localityId, form.position.id)
        invalidatePositionCache.getCagePositionDeviations(customerId, localityId, positionId)
      },
      onError: error => {
        if (error['data'] == 'ExceedMaxCountForCage') {
          toast.error(t('exceedMaxCountForCage', { ns: 'main-component' }))
        }
      },
    })
  }

  const updateSchema = z.object({
    positionId: z.number(),
    positionName: z.string(),
  })

  const form = useZodForm(updateSchema, {
    defaultValues: {
      ...{ positionId: positionId },
    },
  })

  return (
    <AppForm form={form} onSubmit={handleSubmit}>
      <GenericDialogLayout
        title={t('change-cage', { ns: 'common' })}
        pop={pop}
        removedCloseButton={false}
        actions={[<FormSubmitButton key="update" createText={t('update', { ns: 'common' })} />]}
      >
        <Autocomplete
          onChange={(_, value) => {
            form.setValue('positionId', (value as IPosition)?.id),
              form.setValue('positionName', (value as IPosition)?.name)
          }}
          groupBy={option => option.mooringName ?? ''}
          options={(data ?? []) as IPosition[]}
          autoHighlight
          getOptionLabel={o => getCageName(o.name)}
          renderOption={(props, o) => (
            <MenuItem key={o.id} value={o.id} {...props}>
              {getCageName(o.name)}
            </MenuItem>
          )}
          renderInput={params => (
            <AppTextField
              {...params}
              label={t('selectCage', { ns: 'main-component' })}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </GenericDialogLayout>
    </AppForm>
  )
}

export default ChangeCageDialog
