import React, { useLayoutEffect, useMemo, useState } from 'react'
import { getClusterArray } from '../map/utils'
import { ILocality } from 'src/generated-types'
import { useMap } from 'react-leaflet'
import ClusterLayer from '../map/layers/ClusterLayer'
import LocalityMarker from './LocalityMarker'

interface Props {
  localities?: ILocality[]
  enableCluster: boolean
}

const LocalityLayer: React.FC<Props> = ({ localities, enableCluster }) => {
  const map = useMap()
  const [clusterRange, setClusterRange] = useState(enableCluster ? 1.2 / map.getZoom() : 0)

  const clusters = useMemo(() => {
    return clusterRange < 1 || !enableCluster ? [] : [...getClusterArray(localities ?? [], clusterRange / 3)]
  }, [localities, map, clusterRange])

  useLayoutEffect(() => {
    if (enableCluster) {
      map.addEventListener('zoom', () => {
        const zoomLevel = map.getZoom()
        let clusterValue = 0
        if (zoomLevel < 16) {
          if (zoomLevel > 6) {
            if (zoomLevel > 10) {
              clusterValue = 0.1 / zoomLevel
            } else {
              clusterValue = 8 / zoomLevel
            }
          } else {
            clusterValue = 25 / zoomLevel
          }
        }
        setClusterRange(clusterValue)
      })
    }
  }, [])

  return (
    <>
      {localities?.map(locality => {
        return locality?.moorings && locality?.moorings?.[0] && locality?.moorings?.[0]?.positions?.[0] ? (
          <LocalityMarker locality={locality} clusters={clusters} />
        ) : (
          <></>
        )
      })}
      {enableCluster &&
        clusters?.map((cluster, index) => {
          return <ClusterLayer key={index} cluster={cluster} />
        })}
    </>
  )
}

export default LocalityLayer
