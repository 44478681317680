import { useTranslation } from 'react-i18next'
import { LayerGroup, LayersControl } from 'react-leaflet'
import { HeatLayer } from '../components/HeatLayer'
import { LocalityDeviations } from '../components/LocalityDeviations'
import { deviationPositions } from '../constraints'
import { ILocality, IMooring, IPosition } from '../../../../../generated-types'
import { IGroupSelector } from '../MapComponent'

export const DeviationLayers: React.FC<{
  positions?: IPosition[]
  moorings?: IMooring[]
  localities?: ILocality[]
  groupSelector: IGroupSelector
}> = ({ positions, moorings, localities, groupSelector }) => {
  const { t } = useTranslation(['map', 'common'])
  return (
    <>
      <LayersControl.Overlay name={`<b><u>${t('deviation-overview')}</u></b>`} checked={groupSelector.deviation}>
        <LayerGroup></LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.Overlay name={t('selected-locality')} checked={groupSelector.deviation}>
        <LayerGroup>
          <HeatLayer deviationPositions={deviationPositions} />
        </LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.Overlay name={t('all-localities')} checked={groupSelector.deviation}>
        <LayerGroup>
          <LocalityDeviations
            positions={positions as IPosition[]}
            moorings={moorings as IMooring[]}
            localities={localities as ILocality[]}
          />
        </LayerGroup>
      </LayersControl.Overlay>
    </>
  )
}
