import { Tooltip, Typography, TypographyProps, styled } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'

export interface IDynamicTextTypographyProps extends TypographyProps {
  text: string
  maxLength?: number
  overWriteText?: string
}

const StyledDiv = styled('div')(() => ({
  cursor: 'pointer',
  fontSize: 'inherit',
}))

export const DynamicTextTypography: FC<IDynamicTextTypographyProps> = ({
  text,
  maxLength,
  overWriteText = '...',
  children,
  ...rest
}) => {
  const shouldTruncate = maxLength != null && text?.length > maxLength
  const truncatedText = shouldTruncate ? text?.slice(0, maxLength) + overWriteText : text

  return (
    <Typography {...rest} display="flex" flexDirection="row">
      {shouldTruncate ? (
        <Tooltip title={text}>
          <StyledDiv>{truncatedText}</StyledDiv>
        </Tooltip>
      ) : (
        text
      )}
      {children}
    </Typography>
  )
}

export const DynamicTypography: FC<IDynamicTextTypographyProps> = ({
  text,
  overWriteText = '...',
  children,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [shouldTruncate, setShouldTruncate] = useState(false)

  useEffect(() => {
    if (ref.current) {
      const e = ref.current
      setShouldTruncate(e.offsetWidth < e.scrollWidth)
    }
  }, [])

  return (
    <Typography {...rest} display="flex" flexDirection="row" textAlign={'end'}>
      <Tooltip title={text} disableHoverListener={!shouldTruncate}>
        <StyledDiv ref={ref} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {text}
        </StyledDiv>
      </Tooltip>
      {children}
    </Typography>
  )
}
