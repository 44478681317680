import { mainComponentQueryKeys } from 'src/query-keys'
import { Entity } from '../generated-types/entity'
import { IExportRequest } from '../generated-types/excel-export-request'
import { IMainComponent, IMainComponentUpdate } from '../generated-types/main-component'
import { IMainComponentStatusCount } from '../generated-types/main-component-status-count'
import { MainComponentsRequestDto } from '../generated-types/main-components-request-dto'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'
import { IDeviationCounts } from 'src/generated-types'
import { EquipmentRequestDto } from 'src/generated-types/equipment-request-dto'
import { IMainComponentType } from 'src/generated-types/main-component-type'

const mainComponentBaseURL = (customerId: EntityId) => `customers/${customerId}/main-components`

export const mainEndpoints = {
  getMainComponents(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}`
  },
  getEquipment(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}/equipment`
  },
  getEquipmentTypes(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}/equipment-types`
  },
  getMainComponentsForLocality(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}/primitive-type-maincomponents-for-locality`
  },
  getMainComponentByIdWithAllData(customerId: EntityId, id: EntityId) {
    return `${mainComponentBaseURL(customerId)}/${id}/with-all-data`
  },
  getMainComponentsList(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}`
  },
  deleteMainComponent(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}`
  },
  getOutgoingMainComponents(customerId: EntityId, localityId: EntityId) {
    return `${mainComponentBaseURL(customerId)}/localities/${localityId}/outgoing`
  },
  getAdditionalEquipments(customerId: EntityId, localityId: EntityId) {
    return `${mainComponentBaseURL(customerId)}/localities/${localityId}/get-additional-equipment-for-locality`
  },
  addMainComponentToLocality(customerId: EntityId, positionId: EntityId | undefined) {
    return `${mainComponentBaseURL(customerId)}/positions/${positionId}`
  },
  createMainComponent(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}`
  },
  getMainComponentsByComponentType(customerId: EntityId, componentTypeId) {
    return `${mainComponentBaseURL(customerId)}/componentTypes/${componentTypeId}`
  },
  getByMainComponentId(customerId: EntityId, id: EntityId) {
    return `${mainComponentBaseURL(customerId)}/${id}`
  },
  updateMainComponent(customerId: EntityId, id: EntityId) {
    return `${mainComponentBaseURL(customerId)}/${id}`
  },
  deleteMainComponentById(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}`
  },
  getMainComponentStatusCount(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}/mainComponentStatusCount`
  },
  getDiscardedMainComponents(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}/discardedMainComponents`
  },
  exportToExcel(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}/export`
  },
  changeCage(customerId: EntityId, id: EntityId) {
    return `${mainComponentBaseURL(customerId)}/${id}/change-cage`
  },
  sendToServiceStation(customerId: EntityId, id: EntityId) {
    return `${mainComponentBaseURL(customerId)}/${id}/send-to-service-station`
  },
  sendToOutgoing(customerId: EntityId, id: EntityId) {
    return `${mainComponentBaseURL(customerId)}/${id}/send-to-locality`
  },
  getDeviationCounts(customerId: EntityId, id: EntityId) {
    return `${mainComponentBaseURL(customerId)}/${id}/get-deviation-counts`
  },
  releaseMainComponent(customerId) {
    return `${mainComponentBaseURL(customerId)}/release-main-component`
  },
  bringBackMainComponent(customerId: EntityId) {
    return `${mainComponentBaseURL(customerId)}/bring-back-main-component`
  },
}

export const invalidateMainComponentCache = {
  getEquipment(customerId?: EntityId, queryParams?: EquipmentRequestDto) {
    queryClient.invalidateQueries(mainComponentQueryKeys.GET_EQUIPMENT(customerId, { ...queryParams }))
  },
  getEquipmentTypes(customerId?: EntityId, queryParams?: EquipmentRequestDto) {
    queryClient.invalidateQueries(mainComponentQueryKeys.GET_EQUIPMENT_TYPES(customerId, { ...queryParams }))
  },
  getMainComponents(customerId: EntityId, localityId?: EntityId, mooringId?: EntityId, positionId?: EntityId) {
    queryClient.invalidateQueries(
      mainComponentQueryKeys.GET_MAIN_COMPONENTS(customerId, { localityId, mooringId, positionId }),
    )
  },
  getOutgoingMainComponents(customerId: EntityId, localityId: EntityId) {
    queryClient.invalidateQueries(mainEndpoints.getOutgoingMainComponents(customerId, localityId))
  },
  getMainComponentsList(customerId: EntityId) {
    queryClient.invalidateQueries(mainComponentQueryKeys.GET_MAIN_COMPONENTS_LIST(customerId))
  },
  getMainComponentById(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(mainEndpoints.getMainComponentByIdWithAllData(customerId, id))
  },
  getMainComponentByIdAdmin(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(mainEndpoints.getByMainComponentId(customerId, id))
  },
  getMainComponentStatusCount(customerId: EntityId) {
    queryClient.invalidateQueries(mainEndpoints.getMainComponentStatusCount(customerId))
  },
  getMainComponentsByComponentType(customerId: EntityId, componentTypeId: EntityId) {
    queryClient.invalidateQueries(mainEndpoints.getMainComponentsByComponentType(customerId, componentTypeId))
  },
  getDiscardedMainComponents(customerId: EntityId) {
    queryClient.invalidateQueries(mainEndpoints.getDiscardedMainComponents(customerId))
  },
  getDeviationCounts(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(mainEndpoints.getDeviationCounts(customerId, id))
  },
  invalidateEquipment(customerId?: EntityId, queryParams?: EquipmentRequestDto) {
    invalidateMainComponentCache.getEquipment(customerId, queryParams)
    invalidateMainComponentCache.getEquipmentTypes(customerId, queryParams)
  },
}

export const useGetMainComponents = (
  customerId: EntityId,
  queryParams: MainComponentsRequestDto,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IMainComponent>>(
    mainEndpoints.getMainComponents(customerId),
    {
      ...queryParams,
      page,
      pageSize,
    },
    {
      queryKey: mainComponentQueryKeys.GET_MAIN_COMPONENTS(customerId, queryParams),
    },
  )

  export const useGetEquipment = (
    customerId: EntityId,
    queryParams: EquipmentRequestDto,
    page: number = apiConsts.Page,
    pageSize: number = apiConsts.PageSize,
  ) =>
    useGet<IPaginatedResult<IMainComponent>>(mainEndpoints.getEquipment(customerId), {
      ...queryParams,
      page,
      pageSize,
    },
      { queryKey: mainComponentQueryKeys.GET_EQUIPMENT(customerId, queryParams) },
  )
    
  export const useGetEquipmentTypes = (
    customerId: EntityId,
    queryParams: EquipmentRequestDto,
  ) =>
    useGet<Array<IMainComponentType>>(mainEndpoints.getEquipmentTypes(customerId), {
      ...queryParams},
      { queryKey: mainComponentQueryKeys.GET_EQUIPMENT_TYPES(customerId, queryParams) },
    )

  export const useGetMainComponentsForLocality = (
    customerId: EntityId,
    queryParams: { localityId: EntityId },
  ) =>
    useGet<Array<IMainComponent>>(mainEndpoints.getMainComponentsForLocality(customerId), {
      ...queryParams,
    })

export const useReleaseMainComponent = (customerId: EntityId) =>
  usePut<boolean, Entity>(mainEndpoints.releaseMainComponent(customerId))

export const useBringBackMainComponent = (customerId: EntityId) =>
  usePut<boolean, object>(mainEndpoints.bringBackMainComponent(customerId))

export const useGetMainComponentById = (customerId: EntityId, id: EntityId) =>
  useGet<IMainComponent>(mainEndpoints.getMainComponentByIdWithAllData(customerId, id))

export const useGetMainComponentsList = (
  customerId: EntityId,
  positionId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IMainComponent>>(
    mainEndpoints.getMainComponentsList(customerId),
    {
      positionId,
      page,
      pageSize,
    },
    { queryKey: mainComponentQueryKeys.GET_MAIN_COMPONENTS_LIST(customerId) },
  )

export const useGetOutgoingMainComponents = (customerId: EntityId, localityId: EntityId) =>
  useGet<any>(mainEndpoints.getOutgoingMainComponents(customerId, localityId))

export const useGetAdditionalEquipments = (customerId: EntityId, localityId: EntityId) =>
  useGet<any>(mainEndpoints.getAdditionalEquipments(customerId, localityId))

export const useChangeCageInMainComponent = (customerId: EntityId, mainComponentId: EntityId) =>
  usePut<boolean, any>(mainEndpoints.changeCage(customerId, mainComponentId))

export const useGetMainComponentsByMainComponentId = (customerId: EntityId, id: EntityId) =>
  useGet<any>(mainEndpoints.getByMainComponentId(customerId, id))

export const useGetMainComponentsByComponentType = (customerId: EntityId, componentTypeId) =>
  useGet<any>(mainEndpoints.getMainComponentsByComponentType(customerId, componentTypeId))

export const useCreateMainComponent = (customerId: EntityId) =>
  usePost<IMainComponent, IMainComponent>(mainEndpoints.createMainComponent(customerId))

export const useUpdateMainComponent = (customerId: EntityId, id: EntityId) =>
  usePut<IMainComponent, IMainComponentUpdate>(mainEndpoints.updateMainComponent(customerId, id))

export const useUpdateMainComponentToOutgoing = (customerId: EntityId, id: EntityId) =>
  usePut<boolean, object>(mainEndpoints.sendToOutgoing(customerId, id))

export const useDeleteMainComponentById = (customerId: EntityId) =>
  useDelete<IMainComponent>(mainEndpoints.deleteMainComponentById(customerId))

export const useGetMainComponentStatusCount = (customerId: EntityId) =>
  useGet<{ result: IMainComponentStatusCount }>(mainEndpoints.getMainComponentStatusCount(customerId))

export const useGetDiscardedMainComponents = (customerId: EntityId) =>
  useGet<IPaginatedResult<IMainComponent>>(mainEndpoints.getDiscardedMainComponents(customerId))

export const useDeleteMainComponent = (customerId: EntityId) =>
  useDelete<IMainComponent>(mainEndpoints.deleteMainComponent(customerId))

export const useAssignMainComponentToServiceStation = (customerId: EntityId, id: EntityId) =>
  usePut<IMainComponent, IMainComponent>(mainEndpoints.sendToServiceStation(customerId, id))

export const useAddMainComponentToLocality = (customerId: EntityId, positionId: EntityId | undefined) => {
  return usePut<boolean, object>(mainEndpoints.addMainComponentToLocality(customerId, positionId))
}

export const useGetExportToExcel = (customerId: EntityId) =>
  usePost<any, IExportRequest>(mainEndpoints.exportToExcel(customerId), {}, { responseType: 'blob' })

export const useGetMainComponentDeviationCount = (customerId: EntityId, id: EntityId) =>
  useGet<IDeviationCounts>(mainEndpoints.getDeviationCounts(customerId, id), {}, { enabled: id ? true : false })
