import { useTranslation } from 'react-i18next'
import { LayerGroup, LayersControl } from 'react-leaflet'
import { ILocality, IMooring, IMooringType, IPosition, IPositionType } from '../../../../../generated-types'
import { Fleet } from '../components/Fleet'
import { useCallback } from 'react'
import { IGroupSelector } from '../MapComponent'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../../../../../app-routes'
import { CreateMooring } from './CreateMooring'
import { ICluster } from 'src/generated-types/cluster'

interface IFleetLayersProps {
  positions?: IPosition[]
  moorings?: IMooring[]
  localities?: ILocality[]
  mooringType?: IMooringType
  mapPosition: L.LatLngExpression
  isFleet: boolean
  groupSelector: IGroupSelector
  navigateLocalityWhenClick?: boolean
  navigate?: (path: string) => void
  isModalView: boolean
  clusters?: ICluster[]
  onPositionSelect?: (positionId: number, positionName: string) => void
}

export const FleetLayers: React.FC<IFleetLayersProps> = ({ isModalView, ...props }) => {
  return isModalView ? (
    <GenericFleetLayers {...props} isModalView={isModalView} />
  ) : (
    <FleetLayersWithNavigation {...props} isModalView={isModalView} />
  )
}

const FleetLayersWithNavigation: React.FC<IFleetLayersProps> = props => {
  const navigate = useNavigate()
  return <GenericFleetLayers {...props} navigate={navigate} />
}

const GenericFleetLayers: React.FC<IFleetLayersProps> = ({
  positions,
  moorings,
  localities,
  mooringType,
  mapPosition,
  groupSelector,
  navigateLocalityWhenClick,
  navigate,
  isModalView,
  onPositionSelect,
  clusters,
}) => {
  const { t } = useTranslation(['map', 'common'])
  const createFleetLayerGroup = useCallback(
    (type: IPositionType) => {
      if (positions && mooringType == IMooringType.Barge) {
        return (
          <LayerGroup key="fleet-group-positions">
            <CreateMooring
              mooring={
                {
                  positions: positions,
                  type: mooringType,
                  deviationsCount: positions.map(x => x.deviationsCount).reduce((a, b) => a + b),
                  name: t('fleet'),
                } as IMooring
              }
              type={type}
              mapPosition={mapPosition}
              isFleet={true}
              removeType={IMooringType.FrameCage}
              isModalView={isModalView}
              onPositionSelect={onPositionSelect}
            />
          </LayerGroup>
        )
      }
      if (moorings) {
        return (
          <LayerGroup key="fleet-group-moorings">
            {moorings.map((currentMooring, index) => {
              if (currentMooring.type == IMooringType.Barge) {
                return (
                  <CreateMooring
                    mooring={currentMooring}
                    type={type}
                    key={index}
                    mapPosition={mapPosition}
                    isFleet={true}
                    removeType={IMooringType.FrameCage}
                    isModalView={isModalView}
                    onPositionSelect={onPositionSelect}
                  />
                )
              }
            })}
          </LayerGroup>
        )
      }
      if (localities) {
        return (
          <LayerGroup key="fleet-group-locality">
            {localities.map(locality =>
              locality.moorings.map((currentMooring, index) => {
                if (currentMooring.type == IMooringType.Barge) {
                  return (
                    <CreateMooring
                      mooring={currentMooring}
                      type={type}
                      key={index}
                      mapPosition={mapPosition}
                      isFleet={true}
                      clusters={clusters}
                      removeType={IMooringType.FrameCage}
                      navigateLocality={
                        navigateLocalityWhenClick
                          ? () => {
                            navigate &&
                              navigate(AppRoutesNavigation.Localities.locality(locality?.customerId, locality?.id))
                          }
                          : undefined
                      }
                      isModalView={isModalView}
                      onPositionSelect={onPositionSelect}
                    />
                  )
                }
              }),
            )}
          </LayerGroup>
        )
      }
    },
    [positions, moorings, localities, navigateLocalityWhenClick, onPositionSelect, clusters],
  )

  const createFleet = useCallback(
    (mooringType: IMooringType) => {
      if (positions && mooringType == IMooringType.Barge) {
        return (
          <LayerGroup key="fleets-ps">
            <Fleet positions={positions} />
          </LayerGroup>
        )
      }
      if (moorings) {
        return moorings.map((currentMooring, index) => {
          if (currentMooring.type == IMooringType.Barge) {
            return (
              <LayerGroup key={`fleets-ms ${index}`}>
                <Fleet positions={currentMooring.positions} key={index} />
              </LayerGroup>
            )
          }
        })
      }
      if (localities) {
        return localities.map(locality =>
          locality.moorings.map((currentMooring, index) => {
            if (currentMooring.type == IMooringType.Barge) {
              return (
                <LayerGroup key={`fleets-ls ${index}`}>
                  <Fleet positions={currentMooring.positions} key={index} />
                </LayerGroup>
              )
            }
          }),
        )
      }
    },
    [positions, moorings, localities],
  )

  return (
    <>
      <LayersControl.Overlay name={`<b><u>${t('fleets')}</u></b>`} checked={groupSelector.fleet}>
        <LayerGroup></LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.Overlay name={`<font color='black'>${t('fleet')}</font>`} checked={groupSelector.fleet}>
        <LayerGroup>{createFleet(mooringType as IMooringType)}</LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.Overlay name={`<font color='green'>${t('frame-lines')}</font>`} checked={groupSelector.fleet}>
        {createFleetLayerGroup(IPositionType.FrameLines)}
      </LayersControl.Overlay>
      <LayersControl.Overlay name={`<font color='gold'>${t('mooring-lines')}</font>`} checked={groupSelector.fleet}>
        {createFleetLayerGroup(IPositionType.MooringLine)}
      </LayersControl.Overlay>
      <LayersControl.Overlay name={`<font color='goldenrod'>${t('corners')}</font>`} checked={groupSelector.fleet}>
        {createFleetLayerGroup(IPositionType.Corner)}
      </LayersControl.Overlay>
      <LayersControl.Overlay
        name={`<font color='grey'>${t('inner-mooring-lines')}</font>`}
        checked={groupSelector.fleet}
      >
        <LayerGroup>{createFleetLayerGroup(IPositionType.Bridle)}</LayerGroup>
      </LayersControl.Overlay>
    </>
  )
}
