import { IPosition } from '../../../../../generated-types'
import { Polyline, useMap, useMapEvent } from 'react-leaflet'
import { LatLngExpression } from 'leaflet'
import { minZoom, opacity } from '../constraints'
import { useEffect, useState } from 'react'

export const ElectricityLine: React.FC<{ polygonPositions: IPosition[] }> = ({ polygonPositions }) => {
  const [latLongs, setLatLongs] = useState<number[][]>([])

  const map = useMap()
  const [zoomLevel, setZoomLevel] = useState(Math.round(map.getZoom()))

  const handleZoomEnd = event => {
    const newZoomLevel = event.target.getZoom()
    setZoomLevel(Math.round(newZoomLevel))
  }
  useMapEvent('zoomend', handleZoomEnd)

  const pathOptions = {
    weight: zoomLevel < minZoom ? 1 : zoomLevel - minZoom,
    opacity: opacity,
    color: 'green',
    pane: 'electricityLine',
  }

  useEffect(() => {
    const extractPositions = async () => {
      await polygonPositions.forEach(function (position) {
        latLongs.push([position.latitude, position.longitude])
      })
      setLatLongs(latLongs)
    }
    extractPositions()
  }, [polygonPositions])

  return (
    <Polyline
      key={polygonPositions[0].id}
      positions={latLongs as LatLngExpression[]}
      pathOptions={pathOptions}
      pane={pathOptions.pane}
    />
  )
}
