import { Box, Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetMainComponentById } from '../../../api/main-component'
import { CenteredSpinner } from '../../../components/Spinner'
// import ComponentCardView from './ComponentCardView'
import DeviationTab from './components/DeviationTab'
import { IDeviationFormWizardType } from '../../../generated-types/form-wizard'
import { CreateCertificateDialog } from '../Certificates/Components/CertificatesContainer'
import CertificateTab from './components/CertificateTab'
import { Context, FC, useCallback, useMemo } from 'react'
import { CreateDocumentDialog, ICreateDocumentsParams } from '../documents/components/CreateDocumentDialog'
import { IDeviationType } from '../../../generated-types/deviation-type'
import { useGetLocality } from '../../../api/localities'
import { AccordionDocumentsTable } from '../documents/components/AccordionDocumentsTable'
import { MainComponentSendModalComponent } from '../positions/components/MainComponentSendModal'
import ChangeCageDialog from './ChangeCageDialog'
import OverallReport from '../../../components/overallReport/OverallReport'
import { formSchemaReportTypes } from '../../../generated-types/form-schema'
import { formFieldsDefaultValues } from '../../../generated-types/form-field'
import { IMainComponentType, MainComponentStatus } from '../../../enums'
import { CreatePositionDeviationDialog, ICreateDeviationDialogProps } from '../deviations/CreatePositionDeviationDialog'
import { UpdateMainComponentDialog } from '../../customer/mainComponents/UpdateMainComponentDialog'
import { AuthorizationProvider } from '../../../components/common/Authorization'
import { permissionValue } from '../../../enums/permissionValue'
import { AuthorizationType } from '../../../enums/auth'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { invalidateDocumentCategory } from 'src/api/users/document-category'
import { Padding } from 'src/styles/paddingComponent'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline, Edit } from '@mui/icons-material'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { MUIThemeColors } from 'src/mui-theme'
import { getThemeColor } from 'src/utils'
import { useGetMooring } from 'src/api/moorings'
import { useGetPosition } from 'src/api/positions'
import { MainComponentHistoriesTable } from './MainComponentHistoriesTable'

export interface IMainComponentContainerProps {
  context?: Context<any> | null
  permission?: number
}

export const MainComponentContainer: FC<IMainComponentContainerProps> = ({ context, permission }) => {
  const { t } = useTranslation(['main-component', 'common'])
  const { customerId: cId, id: adminCustomerId, localityId, mainComponentId, serviceStationId } = useParams()
  const customerId = useMemo(() => cId ?? adminCustomerId, [cId, adminCustomerId])
  const { data, isLoading } = useGetMainComponentById(customerId as EntityId, mainComponentId as EntityId)
  const { data: locality } = useGetLocality(
    customerId as EntityId,
    data?.selectedLocalityId ? (data?.selectedLocalityId as EntityId) : (0 as EntityId),
    { mainComponentId },
  )
  const { data: mooringDetails, isLoading: mooringLoading } = useGetMooring(
    customerId as EntityId,
    localityId || (data?.selectedLocalityId as EntityId),
    data?.position?.mooringId as EntityId,
    { isWithAllInfo: true },
  )
  const { data: positionDetail, isLoading: positionLoading } = useGetPosition(
    customerId as EntityId,
    data?.selectedLocalityId as EntityId,
    data?.position?.mooringId as EntityId,
    data?.positionId as EntityId,
  )
  const { showDialog } = useDialogCtx()
  const theme = useTheme()
  const { localityId: localityIdFromURL } = useParams()

  const onUpdate = useCallback(
    async id => {
      const added = await showDialog(
        UpdateMainComponentDialog,
        {
          componentProps: {
            id: id as EntityId,
            mainComponentTypeName: data?.type.name as string,
            customerId: customerId as EntityId,
            localityId: localityIdFromURL as EntityId,
          },
        },
        undefined,
        true,
      )
      if (!added) return
    },
    [data?.mainComponentTypeName],
  )

  const onChangeCage = useCallback(
    id => {
      showDialog(
        ChangeCageDialog,
        {
          componentProps: {
            id: id as EntityId,
            customerId: customerId as EntityId,
            localityId: locality?.id as EntityId,
            positionId: data?.positionId as EntityId,
            mooringId: data?.position?.mooringId as EntityId,
          },
          opts: {
            maxWidth: 'sm',
            fullWidth: true,
          },
        },
        undefined,
        true,
      )
    },
    [locality, customerId, data?.positionId],
  )

  const onAddDeviationForComponent = useCallback(async () => {
    await showDialog(
      CreatePositionDeviationDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
          localityId: locality?.id as EntityId,
          mooringId: data?.position?.mooringId as EntityId,
          position: data?.position,
          mainComponent: data,
          formWizard: IDeviationFormWizardType.Component,
          type:
            data?.type.componentType === IMainComponentType.Custom
              ? IDeviationType.Equipment
              : IDeviationType.MainComponent,
          disableSteps: true,
        } as ICreateDeviationDialogProps,
        isAutoCloseDisabled: true,
      },
      undefined,
      true,
    )
  }, [customerId, locality?.id, data])

  const onAddCertificateForComponent = async () => {
    const added = await showDialog(
      CreateCertificateDialog,
      {
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
        componentProps: {
          mainComponentId: mainComponentId as EntityId,
          customerId: customerId as EntityId,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const onAddDocumentForComponent = async () => {
    const documentParams = {
      mainComponentId: mainComponentId as EntityId,
      mooringId: data?.position?.mooringId as EntityId,
      positionId: data?.positionId,
      mooringName: data?.position?.mooringName as string,
    } as ICreateDocumentsParams
    const added = await showDialog(
      CreateDocumentDialog,
      {
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: {
          customerId: customerId as EntityId,
          localityId: locality?.id as EntityId,
          createDocumentsParams: documentParams,
        },
        pop: () => {
          invalidateDocumentCategory.getDocumentCategoriesWithDocuments(customerId as EntityId, { mainComponentId })
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const onSend = async () => {
    const updated = await showDialog(MainComponentSendModalComponent, {
      title: t('sendToServiceStation', { ns: 'main-component' }),
      componentProps: {
        customerId: customerId as EntityId,
        localityId: locality?.id as EntityId,
        mooringId: data?.position?.mooringId as EntityId,
        positionId: data?.positionId as EntityId,
        id: mainComponentId as EntityId,
        localityIdFromURL: localityIdFromURL,
      },
      opts: {
        maxWidth: 'sm',
        fullWidth: true,
      },
    })
    if (!updated) return
  }

  if (isLoading) return <CenteredSpinner />
  const mainComponentServiceStationStatus = [
    MainComponentStatus.Incoming,
    MainComponentStatus.InService,
    MainComponentStatus.InStock,
    MainComponentStatus.Outgoing,
  ]

  if (data !== undefined)
    return (
      <CommonPageLayout
        titleSection={{
          title: t('main-component-details', { ns: 'main-component' }),
        }}
        topAction={[
          <Box key="actions" className="flex items-center justify-end px-4" borderRadius={2}>
            {data?.positionId != null && (
              <Padding.pl16>
                <AuthorizationProvider
                  permissions={[
                    permissionValue.Locality.Mooring.Write,
                    permissionValue.ServiceStation.Write,
                    permissionValue.ClientAdminPermission,
                  ]}
                  Context={context}
                  type={AuthorizationType.Disabled}
                >
                  <GridActionButton
                    onClick={() => onChangeCage(data.id)}
                    startIcon={<img height={'28px'} width={'28px'} src="/icons/change.svg" />}
                    buttonText={t('change-cage', { ns: 'common' })}
                    height="44px"
                  />
                </AuthorizationProvider>
              </Padding.pl16>
            )}
            {((data?.selectedLocalityId && data.status === MainComponentStatus.InStock) ||
              !(data && mainComponentServiceStationStatus.includes(data.status))) && (
              <Padding.pl16>
                <AuthorizationProvider
                  permissions={[
                    permissionValue.Locality.Mooring.Write,
                    permissionValue.ServiceStation.Write,
                    permissionValue.ClientAdminPermission,
                  ]}
                  Context={context}
                  type={AuthorizationType.Disabled}
                >
                  <GridActionButton
                    onClick={() => onSend()}
                    startIcon={<img height={'28px'} width={'28px'} src="/icons/service_station_number.svg" />}
                    buttonText={t('move-send', { ns: 'main-component' })}
                    height="44px"
                  />
                </AuthorizationProvider>
              </Padding.pl16>
            )}
            <Padding.pl16>
              <AuthorizationProvider
                permissions={[
                  permissionValue.Locality.Mooring.Write,
                  permissionValue.ServiceStation.Write,
                  permissionValue.ClientAdminPermission,
                ]}
                Context={context}
                type={AuthorizationType.Disabled}
              >
                <GridActionButton
                  buttonIcon={<Edit />}
                  onClick={() => onUpdate(data.id)}
                  toolTipTitle={t('edit', { ns: 'common' })}
                  height="44px"
                  width="44px"
                />
              </AuthorizationProvider>
            </Padding.pl16>
          </Box>,
        ]}
      >
        <AuthorizationProvider
          type={AuthorizationType.Disabled}
          permissions={[
            permissionValue.ServiceStation.Read,
            permissionValue.Locality.MainComponent.Certificate.Read,
            permissionValue.Locality.MainComponent.Certificate.Write,
            permissionValue.Locality.MainComponent.Certificate.Delete,
            permissionValue.ClientAdminPermission,
          ]}
          Context={context}
        >
          <Padding.pt16>
            <CertificateTab
              mainComponentId={data.id}
              positionId={data.positionId}
              customerId={customerId as EntityId}
              onAddCertificateForComponent={onAddCertificateForComponent}
              Context={context}
              mainComponentType={data?.type}
            />
          </Padding.pt16>
        </AuthorizationProvider>
        <AuthorizationProvider
          type={AuthorizationType.Disabled}
          Context={context}
          permissions={[
            permissionValue.ServiceStation.Read,
            permissionValue.Locality.MainComponent.Deviation.Read,
            permissionValue.Locality.MainComponent.Deviation.Write,
            permissionValue.Locality.MainComponent.Deviation.Delete,
            permissionValue.ClientAdminPermission,
          ]}
        >
          <DeviationTab
            customerId={customerId!}
            localityId={localityId}
            mainComponentId={data.id}
            onAddDeviationForComponent={onAddDeviationForComponent}
            queryParams={{ mainComponentId }}
            context={context as Context<any>}
            addPermissions={[
              permissionValue.Locality.MainComponent.Deviation.Write,
              permissionValue.ServiceStation.Write,
              permissionValue.ClientAdminPermission,
            ]}
            updatePermissions={[
              permissionValue.Locality.MainComponent.Deviation.Write,
              permissionValue.ServiceStation.Write,
              permissionValue.ClientAdminPermission,
            ]}
            deletePermissions={[
              permissionValue.Locality.MainComponent.Deviation.Delete,
              permissionValue.ServiceStation.Delete,
              permissionValue.ClientAdminPermission,
            ]}
            permission={permission}
          />
        </AuthorizationProvider>
        <Grid container>
          <Grid md={6} className="pr-8-core">
            <CommonPageLayout
              titleSection={{ subtitle: t('documents', { ns: 'document' }) }}
              topAction={[
                {
                  buttonText: t('documents', { ns: 'document' }),
                  buttonIcon: <AddCircleOutline />,
                  onClick: onAddDocumentForComponent,
                  permissionBinaryValues: [
                    permissionValue.Locality.MainComponent.Document.Write,
                    permissionValue.ClientAdminPermission,
                    permissionValue.ServiceStation.Write,
                  ],
                  variant: 'outlined',
                  shadowWeight: null,
                  context: context,
                },
              ]}
            >
              <Padding.p10
                borderRadius={'4px'}
                border={`1px solid ${getThemeColor(theme, MUIThemeColors.secondaryLight)}`}
              >
                <AccordionDocumentsTable
                  customerId={customerId as EntityId}
                  localityId={locality?.id as EntityId}
                  documentTypeId={''}
                  documentsParams={{ mainComponentId, customerId: customerId as EntityId }}
                  height={window.innerHeight / 3}
                  context={context as Context<any>}
                />
              </Padding.p10>
            </CommonPageLayout>
          </Grid>
          <Grid md={6} className="pl-8-core">
            <AuthorizationProvider
              type={AuthorizationType.Disabled}
              Context={context}
              permissions={[
                permissionValue.ServiceStation.Read,
                permissionValue.Locality.MainComponent.Report.Read,
                permissionValue.ClientAdminPermission,
              ]}
            >
              <OverallReport
                type={formSchemaReportTypes.MainComponent}
                mainComponentId={mainComponentId}
                context={context}
                formInitialValues={{
                  [formFieldsDefaultValues.orderNumber]: data?.orderNumber || '',
                  [formFieldsDefaultValues.netType]:
                    Object.keys(IMainComponentType)[
                      Object.values(IMainComponentType).indexOf(
                        data?.type.componentType as unknown as IMainComponentType,
                      )
                    ] || '',
                  [formFieldsDefaultValues.siteLocationName]: locality?.name ? locality?.name : 'n/a',
                  [formFieldsDefaultValues.siteNumber]: locality?.locationNr ? locality?.locationNr : 'n/a',
                  [formFieldsDefaultValues.resourcesName]: data?.name || '',
                }}
                createPermissions={[
                  permissionValue.Locality.MainComponent.Report.Write,
                  permissionValue.ServiceStation.Write,
                  permissionValue.ClientAdminPermission,
                ]}
                deletePermissions={[
                  permissionValue.Locality.MainComponent.Report.Delete,
                  permissionValue.ServiceStation.Delete,
                  permissionValue.ClientAdminPermission,
                ]}
                height={window.innerHeight / 3}
                listView={true}
              />
            </AuthorizationProvider>
          </Grid>
        </Grid>
        <CommonPageLayout titleSection={{ subtitle: t('activity-log', { ns: 'common' }) }} topAction={[]}>
          <MainComponentHistoriesTable
            mainComponentId={mainComponentId as EntityId}
            customerId={customerId as EntityId}
            localityId={localityId || (data?.selectedLocalityId as EntityId)}
            mooringId={data?.position?.mooringId as EntityId}
          />
        </CommonPageLayout>
      </CommonPageLayout>
    )
  return <></>
}
