import { List, Typography } from '@mui/material'
import { Box } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AppRoutesNavigation } from '../../app-routes'
import { customerSidebarItems } from './customer-sidebar-items'
import { useSidebarCtx } from '../../hooks/context-hooks'
import { SidebarHeaderItem } from '../../components/SidebarHeaderItem'
import { SidebarItem } from '../../components/SidebarItem'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { CustomerHeader } from 'src/components/sidemenu/CustomerHeader'
import { useGetCustomerById } from 'src/api/customer'

export const CustomerSidebar = () => {
  const { id } = useParams<any>()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t: translate } = useTranslation(['admin-sidebar', 'service'])
  const { setCollapsibleSidebarOpen, collapsibleSidebarOpen } = useSidebarCtx()
  const { data: customerInfo } = useGetCustomerById(id as EntityId)

  useEffect(() => {
    setCollapsibleSidebarOpen(true)
  }, [])

  return (
    <Box sx={{ paddingTop: collapsibleSidebarOpen ? '0px' : '90px' }}>
      <Box padding={'8px'}>
        {customerInfo && (
          <CustomerHeader
            id={customerInfo?.id as number}
            name={customerInfo?.name as string}
            image={customerInfo?.image}
            active={true}
            subComponentEnable={true}
            MultipleSelectComponent={
              <Typography color="primary" variant="subtitle1" fontWeight={'600'}>
                {translate('administrator')}
              </Typography>
            }
          />
        )}
      </Box>
      <List
        style={{ width: collapsibleSidebarOpen ? '100%' : '72px', height: window.innerHeight - 180, overflow: 'auto' }}
        className="!px-1 !py-1 lg:!pl-1"
      >
        {customerSidebarItems(customerInfo?.isGlobalServiceStationProvider).map(item => {
          const isHeader = item?.children && item.children?.length > 0
          return (
            <div key={item.name}>
              {isHeader && (
                <SidebarHeaderItem
                  item={item}
                  textVisibility={collapsibleSidebarOpen}
                  title={item.name}
                  Icon={item.icon}
                  id={id}
                  pathname={pathname}
                />
              )}
              {!isHeader && (
                <SidebarItem
                  key={item.name}
                  active={!!pathname && pathname.split('/')[4] === item.pathname}
                  title={translate(item.name)}
                  Icon={item.icon}
                  textVisibility={collapsibleSidebarOpen}
                  onClick={() => navigate(item.path ? item.path(id!) : AppRoutesNavigation.Home)}
                />
              )}
            </div>
          )
        })}
      </List>
    </Box>
  )
}
