import React, { Context, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomerLocationCtx } from '../../context/FacilityContext'
import { AuthorizationType } from '../../enums/auth'
import { UnAuthorizationPage } from './UnAuthorizationPage'
import { UserRole } from '../../enums'
import { useIdentityServerUserCtx } from '../../hooks/context-hooks'

export const hasPermission = (binaryValues, permission, role = null) => {
  let hasAccess = false
  if (role == UserRole.Admin && permission == undefined) {
    return true
  }
  binaryValues.map(binaryValue => {
    if (binaryValue && permission && (BigInt(binaryValue) & BigInt(permission)) == BigInt(binaryValue)) {
      hasAccess = true
    }
  })
  return hasAccess
}

//Permission value validation function
const validateAuthorization = (WrappedComponent, context, binaryValues, props, type, t, role) => {
  const permissionSuccess = hasPermission(binaryValues, context?.permission, role)
  if (permissionSuccess) {
    return <WrappedComponent {...props} />
  } else if (type == AuthorizationType.Page) {
    return <UnAuthorizationPage />
  } else if (type == AuthorizationType.Disabled) {
    return (
      <WrappedComponent
        to={window.location.pathname}
        onClick={() => {}}
        style={{ pointerEvents: 'none', cursor: 'not-allowed', color: 'rgba(0, 0, 0, 0.26)' }}
        {...props}
        disabled
      />
    )
  } else {
    return null
  }
}

//HOC for authorization with context(indirect permission)
const indirectAuthorization = (
  WrappedComponent,
  binaryValues: number[],
  type,
  Context: Context<any> | null = null,
  translation,
  role,
) => {
  return (props: any) => {
    const AuthContext = Context ? Context : CustomerLocationCtx
    return (
      <AuthContext.Consumer>
        {context => validateAuthorization(WrappedComponent, context, binaryValues, props, type, translation, role)}
      </AuthContext.Consumer>
    )
  }
}

//HOC for authorization with direct permission
const directAuthorization = (WrappedComponent, binaryValues: number[], type, permission, translation, role) => {
  return (props: any) => {
    return validateAuthorization(
      WrappedComponent,
      { permission: permission },
      binaryValues,
      props,
      type,
      translation,
      role,
    )
  }
}

//translation binding for direct authorization
export const authorization = (
  WrappedComponent: any,
  binaryValues: number[],
  type: AuthorizationType | null = null,
  Context: Context<any> | null = null,
  self_auth = false,
  permission: number | null = null,
) => {
  return (props: any) => {
    const { t } = useTranslation('common')
    const { userProfile } = useIdentityServerUserCtx()
    let Component
    if (self_auth) {
      Component = directAuthorization(WrappedComponent, binaryValues, type, permission, t, userProfile.role)
    } else if (Context) {
      Component = indirectAuthorization(WrappedComponent, binaryValues, type, Context, t, userProfile.role)
    }
    return <Component {...props} />
  }
}

interface IAuthorizationWrapper {
  permissions?: number[]
  type?: AuthorizationType
  Context?: React.Context<any> | null
  self_auth?: boolean
  permission?: number
  children: any
  skippedPermission?: boolean
}

//Wrapper Component for authorization
export const AuthorizationProvider = ({
  permissions = [],
  type = AuthorizationType.Hidden,
  Context = null,
  self_auth = false,
  permission = undefined,
  children,
  skippedPermission = false,
}: IAuthorizationWrapper) => {
  const { t } = useTranslation('common')
  const { userProfile } = useIdentityServerUserCtx()

  const Element = useMemo(() => {
    if (self_auth) {
      return directAuthorization(children.type, permissions, type, permission, t, userProfile.role)
    } else {
      return indirectAuthorization(children.type, permissions, type, Context, t, userProfile.role)
    }
  }, [permission, t])

  if (skippedPermission || permissions == null || permissions.length == 0) {
    return children
  }
  return Element ? <Element {...children.props} /> : <></>
}
