import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useGetCustomerSuppliers } from '../../api/supplier'
import BasicDatePicker from '../../components/DateTimePicker'
import { AppFormProps, AppForm, FormTextField, FormSelectField, FormFileCoreUpload } from '../../components/FormCore'
import { ICustomerAdminSupplier } from '../../generated-types'
import { ICertificate } from '../../generated-types/Certificate'
import { ICertificateDocumentType } from '../../generated-types/certificate-document-type'
import { useZodForm } from '../../hooks/zod-form'
import { EnumToSelectTypes } from '../../generated-types/select-type'
import { GenericDialogLayout } from '../../components/GenericDialogLayout'
import { MainButton } from '../../components/MainButton'
import { APIResultCount } from '../../constant'
import { SuppliersFormField } from '../../components/SuppliersFormField'
import { CertificateValidityPeriod } from 'src/enums'

interface ICreateCertificateFormProps extends AppFormProps<ICertificate> {
  customerId: EntityId
  title: string
}
export const CreateCertificateForm: React.FC<ICreateCertificateFormProps> = ({
  onSubmit,
  onCancel,
  customerId,
  title,
}) => {
  const { t: translate } = useTranslation(['admin-customer', 'common', 'certificate'])

  const schema = z.object({
    certificateNumber: z
      .string()
      .nonempty()
      .regex(/^(?! *$).+/, translate('certificateNumberIsInvalid', { ns: 'certificate' })),
    certificateDate: z.date(),
    validPeriod: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    certificateDocumentType: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    fileId: z.union([z.string(), z.number()]),
    supplierId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
  })

  const form = useZodForm(schema)

  const certificateDocumentTypes = EnumToSelectTypes(ICertificateDocumentType, 'certificate')
  const validPeriods = EnumToSelectTypes(CertificateValidityPeriod, 'certificate')

  const { data: suppliers } = useGetCustomerSuppliers(customerId, 0, APIResultCount.Max)

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      removedCloseButton={false}
      actions={[
        <MainButton
          key="cert-submit"
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
        >
          {translate('add', { ns: 'common' })}
        </MainButton>,
      ]}
    >
      <AppForm form={form} onSubmit={onSubmit}>
        <FormTextField
          fullWidth
          name="certificateNumber"
          label={translate('certificateNumber', { ns: 'certificate' })}
        />
        <BasicDatePicker label={translate('certificateDate', { ns: 'certificate' })} name="certificateDate" />

        <FormSelectField
          name="validPeriod"
          data={validPeriods}
          label={translate('selectDuration', { ns: 'certificate' })}
          getOptionLabel={option => option.value}
          renderOption={(props: any, option) => (
            <Box {...props} key={option.id}>
              {option.value}
            </Box>
          )}
          isOptionEqualToValue={(o, v) => o.id === v.id}
        />

        <FormSelectField
          name="certificateDocumentType"
          data={certificateDocumentTypes}
          label={translate('documentType', { ns: 'certificate' })}
          getOptionLabel={option => option.value}
          renderOption={(props: any, option) => (
            <Box {...props} key={option.id}>
              {option.value}
            </Box>
          )}
          isOptionEqualToValue={(o, v) => o.id === v.id}
        />
        <SuppliersFormField
          suppliers={(suppliers?.items as ICustomerAdminSupplier[]) || []}
          name="supplierId"
          label={translate('certifiedBy', { ns: 'certificate' })}
        />
        <FormFileCoreUpload
          label={translate('fileName', { ns: 'common' })}
          name="fileId"
          mapFileKey="id"
          height="160px"
        />
      </AppForm>
    </GenericDialogLayout>
  )
}
