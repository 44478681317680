import { useMediaQuery, Stack, useTheme, Popover, styled } from '@mui/material'
import { useMemo, useState } from 'react'
import { DynamicTextTypography, IDynamicTextTypographyProps } from '../../../../components/DynamicTextTypography'
import { CertificationValidationPeriod, IMainComponentType } from '../../../../enums'
import Ring from '../../../../assets/Ring.png'
import Ring2X from '../../../../assets/Ring2x.png'
import Net from '../../../../assets/net.png'
import Net2X from '../../../../assets/net.png'
import { IMainComponent } from '../../../../generated-types/main-component'
import { MainComponentProgress } from '../../locality-dashboard/cage-status/MainComponentProgress'
import { IMainComponentStatus } from 'src/generated-types/main-component-status'
import { Flex } from 'src/styles/flexComponent'
import { ICertificateDocumentType } from 'src/generated-types/certificate-document-type'

export const StyledImage = styled('img')({
  height: 14,
  width: 14,
})

export const MainComponentItem: React.FC<{
  mainComponent: IMainComponent
  type: IMainComponentType
  textColor?: any
  removePopOver?: boolean
  itemTextProps?: Omit<IDynamicTextTypographyProps, 'text'>
}> = ({ mainComponent, type, textColor, removePopOver = false, itemTextProps }) => {
  const isTabletOrMobile = useMediaQuery('(min-width: 900px)')
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const icons = useMemo(() => {
    switch (type) {
      case IMainComponentType.Ring:
        return <StyledImage src={isTabletOrMobile ? Ring : Ring2X} />
      case IMainComponentType.Net:
        return <StyledImage src={isTabletOrMobile ? Net : Net2X} />
      default:
        return null
    }
  }, [type])

  const certificate = useMemo(() => {
    const validCertificateList =
      mainComponent?.certificates &&
      (mainComponent?.certificates ?? [])
        .sort((a, b) => b.numberOfDays - a.numberOfDays)
        .filter(c => c.certificateDocumentType == ICertificateDocumentType.ProductCertificate && c.isValid)
    if (validCertificateList && validCertificateList.length > 0) return validCertificateList[0]
  }, [mainComponent?.certificates])

  const certificateValidationTime = useMemo(() => {
    if(certificate?.expiresIn == null || certificate?.numberOfDays == null) return null
    if (certificate?.expiresIn && certificate?.numberOfDays) {
      return (certificate?.expiresIn * 100) / certificate?.numberOfDays
    }
    return 0
  }, [certificate])

  const status: CertificationValidationPeriod = useMemo(() => {
    if (certificateValidationTime != undefined && certificateValidationTime >= 0) {
      if (certificateValidationTime < CertificationValidationPeriod.LOW) {
        return CertificationValidationPeriod.LOW
      } else if (certificateValidationTime < CertificationValidationPeriod.MEDIUM) {
        return CertificationValidationPeriod.MEDIUM
      } else if (certificateValidationTime <= CertificationValidationPeriod.HIGH) {
        return CertificationValidationPeriod.HIGH
      }
    }
    return CertificationValidationPeriod.NA
  }, [certificateValidationTime])

  return (
    <Stack
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={1}
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      className="hover-pointer"
    >
      {icons}
      <DynamicTextTypography
        color={textColor ? textColor : theme.palette.black[theme.palette.mode]}
        variant="body1"
        fontWeight={'500'}
        key={`${mainComponent.id}`}
        text={mainComponent?.serialNumber ?? '-'}
        maxLength={10}
        {...itemTextProps}
      />
      {!removePopOver && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Flex.Column px={2} py={1} spacing={1}>
            <Flex.Row justifyContent={'flex-start'} alignItems={'center'} gap={1}>
              {icons}
              <DynamicTextTypography
                color={textColor ? textColor : theme.palette.black[theme.palette.mode]}
                variant="body2"
                fontWeight={'500'}
                key={`${mainComponent.id}`}
                text={mainComponent?.serialNumber}
                maxLength={10}
              />
            </Flex.Row>
            <MainComponentProgress
              minWidth={'200px'}
              cage={
                {
                  nets: type === IMainComponentType.Net ? [mainComponent] : [],
                  rings: type === IMainComponentType.Ring ? [mainComponent] : [],
                  daysLeft: certificate?.expiresIn,
                  certificateValidationTime: certificateValidationTime,
                } as IMainComponentStatus
              }
              status={status}
            />
          </Flex.Column>
        </Popover>
      )}
    </Stack>
  )
}
