import { Box, Checkbox, Divider, Grid, Typography } from '@mui/material'
import { FormFileCoreUpload, FormSelectField, FormTextField } from '../../../components/FormCore'
import BasicDatePicker from '../../../components/DateTimePicker'
import { useTranslation } from 'react-i18next'
import { ICustomerAdminSupplier, IFile } from '../../../generated-types'
import { useEffect, useState } from 'react'
import { ICertificateDocumentType } from '../../../generated-types/certificate-document-type'
import { useGetFile } from '../../../api/file'
import { IPaginatedResult } from '../../../api/paginated-result'
import { ICertificate } from '../../../generated-types/Certificate'
import { SuppliersFormField } from '../../../components/SuppliersFormField'
import { Padding } from 'src/styles/paddingComponent'
import { Flex } from 'src/styles/flexComponent'
import { IMainComponentType } from 'src/generated-types/main-component-type'
import { EnumToSelectTypes } from 'src/generated-types/select-type'
import { CertificateValidityPeriod } from 'src/enums'

interface Props {
  accordingSummary: string
  form: any
  suppliers?: IPaginatedResult<ICustomerAdminSupplier> | undefined
  initialValues?: any
  isProductCertificateAdd?: boolean
  initialCertificateFiles?: IFile | undefined
  setInitialCertificateFiles?: React.Dispatch<React.SetStateAction<IFile | null | undefined>>
  mainComponentTypeDetails?: {
    mainComponentType: IMainComponentType | undefined
    isCertificateDetailsRequired: boolean
  }
  setMainComponentTypeDetails?: React.Dispatch<
    React.SetStateAction<{
      mainComponentType: IMainComponentType | undefined
      isCertificateDetailsRequired: boolean
    }>
  >
}

export const MainComponentCertificationAdd: React.FC<Props> = ({
  accordingSummary,
  form,
  suppliers,
  initialValues,
  isProductCertificateAdd,
  initialCertificateFiles,
  setInitialCertificateFiles,
  mainComponentTypeDetails,
  setMainComponentTypeDetails,
}) => {
  const { t: translate } = useTranslation(['main-component', 'common', 'certificate'])
  const [certificateFileId, setCertificateFileId] = useState<number | undefined>(form.getValues('certificateFileId'))

  const { data: certificateFile, refetch } = useGetFile(certificateFileId as number)

  useEffect(() => {
    setInitialCertificateFiles && setInitialCertificateFiles(certificateFile)
  }, [certificateFile, setInitialCertificateFiles])

  useEffect(() => {
    const productCertificate = initialValues && (initialValues['certificate'] as ICertificate)
    if (productCertificate) {
      setCertificateFileId(productCertificate?.fileId)

      form.reset({
        ...initialValues,
        certificateId: productCertificate?.id,
        certificateNumber: productCertificate?.certificateNumber,
        certificateDate: productCertificate?.certificateDate,
        validUntil: productCertificate?.validUntil,
        certificateFileId: productCertificate?.fileId,
        certificateDocumentType: productCertificate?.certificateDocumentType,
        certificateSupplierId: suppliers?.items.find(s => s.id == productCertificate?.supplierId) ?? null,
      })
    } else {
      setCertificateFileId(undefined)
    }
  }, [initialValues, suppliers, form])

  useEffect(() => {
    refetch()
  }, [certificateFileId, refetch])

  const certificateDocumentTypes = Object.keys(ICertificateDocumentType)
    .filter(v => isNaN(Number(v)))
    .map(t => ICertificateDocumentType[t])
  const validPeriods = EnumToSelectTypes(CertificateValidityPeriod, 'certificate')

  return (
    <>
      <Padding.pt16>
        <Flex.Row px={1} justifyContent={'space-between'}>
          <Typography variant="subtitle2">{translate(accordingSummary, { ns: 'main-component' })}</Typography>
          {mainComponentTypeDetails?.mainComponentType && (
            <Checkbox
              disabled={setMainComponentTypeDetails == undefined}
              checked={mainComponentTypeDetails?.isCertificateDetailsRequired}
              onChange={(e, v) => {
                setMainComponentTypeDetails &&
                  setMainComponentTypeDetails(prev => ({
                    ...prev,
                    isCertificateDetailsRequired: v,
                  }))
              }}
            />
          )}
        </Flex.Row>
        {mainComponentTypeDetails?.isCertificateDetailsRequired && (
          <Padding.pt4>
            <Box px={1}>
              <Divider />
            </Box>
            <Grid container pt={1} className="!flex !flex-wrap justify-between">
              <Grid item xs={6}>
                <FormTextField
                  name="certificateId"
                  sx={{ display: 'none' }}
                  disabled={
                    !(mainComponentTypeDetails == null) && !mainComponentTypeDetails.isCertificateDetailsRequired
                  }
                />
                <FormTextField
                  fullWidth
                  name="certificateNumber"
                  label={translate('number', { ns: 'certificate' })}
                  disabled={
                    !(mainComponentTypeDetails == null) && !mainComponentTypeDetails.isCertificateDetailsRequired
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelectField
                  name="certificateDocumentType"
                  data={certificateDocumentTypes}
                  label={translate('documentType', { ns: 'certificate' })}
                  getOptionLabel={option => translate(ICertificateDocumentType[option], { ns: 'certificate' })}
                  renderOption={(props: any, option) => (
                    <Box {...props} key={option.id}>
                      {translate(ICertificateDocumentType[option], { ns: 'certificate' })}
                    </Box>
                  )}
                  isOptionEqualToValue={(o, v) => o === v}
                  defaultValue={ICertificateDocumentType.ProductCertificate}
                  disabled={
                    (!(mainComponentTypeDetails == null) && !mainComponentTypeDetails.isCertificateDetailsRequired) ||
                    isProductCertificateAdd
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <BasicDatePicker
                  label={translate('certificateDate', { ns: 'certificate' })}
                  name="certificateDate"
                  disabled={
                    !(mainComponentTypeDetails == null) && !mainComponentTypeDetails.isCertificateDetailsRequired
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <FormSelectField
                  name="validPeriod"
                  data={validPeriods}
                  label={translate('selectDuration', { ns: 'certificate' })}
                  getOptionLabel={option => option.value}
                  renderOption={(props: any, option) => (
                    <Box {...props} key={option.id}>
                      {option.value}
                    </Box>
                  )}
                  isOptionEqualToValue={(o, v) => o.id === v.id}
                />
              </Grid>
              <Grid item xs={6}>
                <SuppliersFormField
                  suppliers={suppliers?.items as ICustomerAdminSupplier[]}
                  name="certificateSupplierId"
                  label={translate('certifiedBy', { ns: 'certificate' })}
                  disabled={
                    !(mainComponentTypeDetails == null) && !mainComponentTypeDetails.isCertificateDetailsRequired
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormFileCoreUpload
                  height="175px"
                  label={translate('fileName', { ns: 'common' })}
                  name="certificateFileId"
                  mapFileKey="id"
                  initialFiles={initialCertificateFiles}
                  disabled={
                    !(mainComponentTypeDetails == null) && !mainComponentTypeDetails.isCertificateDetailsRequired
                  }
                />
              </Grid>
            </Grid>
          </Padding.pt4>
        )}
      </Padding.pt16>
    </>
  )
}
