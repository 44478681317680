import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useCreateCertificateExtension, invalidateCertificateExtensionCache } from '../../api/CertificateExtension'
import { invalidateCertificateCache } from '../../api/Certificates'
import { invalidateMainComponentCache } from '../../api/main-component'
import { CANNOT_ADD, LIMIT_EXCEEDED } from '../../constant'
import { AddCertificateExtensionForm } from '../../forms/certificate-forms/AddCertificateExtensionForm'
import { ShowDialogProps } from '../../show-dialog'
import { invalidateLocalityCache } from '../../api/localities'
import { useParams } from 'react-router-dom'
import { invalidateMooringCache } from 'src/api/moorings'

interface CreateCertificateExtensionDialogProps extends ShowDialogProps {
  certificateId: EntityId
  mainComponentId: EntityId
  customerId: EntityId
  positionId?: EntityId
}

export const CreateCertificateExtensionDialog: React.FC<CreateCertificateExtensionDialogProps> = ({
  pop,
  certificateId,
  mainComponentId,
  customerId,
  positionId,
}) => {
  const { t } = useTranslation(['certificate', 'common'])
  const { mutateAsync, error } = useCreateCertificateExtension(certificateId)
  const { localityId } = useParams()

  useEffect(() => {
    if (error != null) {
      if (error['data'] == LIMIT_EXCEEDED) {
        toast(t('CannotAddMoreThanTwoExtensions', { ns: 'certificate' }), { type: 'warning' })
        return
      }
      if (error['data'] == CANNOT_ADD) {
        toast(t('CannotUseSameExtensionNumber', { ns: 'certificate' }), { type: 'error' })
        return
      }
      toast(t('unExpectedError', { ns: 'common-api-message' }), { type: 'error' })
    }
  }, [error])

  const onSubmit = async form => {
    await mutateAsync(form, {
      onSuccess: () => {
        invalidateCertificateCache.getCertificates(mainComponentId)
        invalidateCertificateExtensionCache.getCertificateExtensions(certificateId)
        invalidateMainComponentCache.getMainComponentById(customerId as EntityId, mainComponentId)
        invalidateLocalityCache.getCageStatus(customerId as EntityId, localityId as EntityId)
        invalidateMainComponentCache.getMainComponents(customerId)
        invalidateMooringCache.getMooringsForMap(customerId as EntityId, localityId)
        invalidateMainComponentCache.getMainComponentById(customerId, mainComponentId)
        toast(t('successfullyAdded', { ns: 'common-api-message' }), { type: 'success' })
        pop()
      }
    })
  }

  return <AddCertificateExtensionForm onSubmit={onSubmit} onCancel={() => pop()} />
}
