import { z } from 'zod'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Autocomplete, Box, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useZodForm } from '../../../../hooks/zod-form'
import { AppForm, FormSubmitButton, FormTextField, AppFormProps } from '../../../../components/FormCore'
import { IPositionType } from '../../../../generated-types'
import { useCallback, useEffect, useState } from 'react'
import { IFrequencyPeriodType } from '../../../../generated-types/frequency-period-type'
import { decode, encode } from 'html-entities'
import { Controller } from 'react-hook-form'
import { validateString } from '../../../../utils'
import { decodeFrequencyInstructionHTML } from 'src/utils/function'
import { AppTextField } from 'src/components/AppTextField'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import React from 'react'

interface FrequencyFormProps extends AppFormProps {
  title: string
}
export const FrequencyForm: React.FC<FrequencyFormProps> = ({ onSubmit, onCancel, initialValues, title }) => {
  const parsedInitialValues = initialValues
    ? {
        ...initialValues,
        periodFrequency: initialValues.periodFrequency ? String(initialValues.periodFrequency) : '',
      }
    : undefined

  const { t } = useTranslation(['frequency', 'position', 'common'])

  const schema = z.object({
    title: z
      .string()
      .nonempty(t('provideValidInput', { ns: 'common' }))
      .max(150, t('max_length', { ns: 'form-validation' }))
      .refine(validateString, {
        message: t('provideValidInput', { ns: 'common' }),
      }),
    instructions: z
      .string()
      .default('')
      .refine(
        value => {
          const decodedValue = decodeFrequencyInstructionHTML(value)
          return decodedValue.trim().length > 0
        },
        {
          message: t('instructionsAreRequired', { ns: 'frequency' }),
        },
      ),
    periodFrequency: z
      .string()
      .nonempty(t('provideValidInput', { ns: 'common' }))
      .or(z.number())
      .transform(v => Number(v))
      .refine(n => n >= 0),
    timeThreshold: z
      .string()
      .nonempty(t('provideValidInput', { ns: 'common' }))
      .or(z.number())
      .transform(v => Number(v))
      .refine(n => n >= 0),
    periodType: z.union([z.number(), z.string().nonempty(t('provideValidInput', { ns: 'common' }))]),
    positionTypes: z.any(),
  })

  const [positionTypes, setPositionTypes] = useState<IPositionType[]>(parsedInitialValues?.positionTypes)
  const [instructions, setInstructions] = useState<string>(parsedInitialValues?.instructions || '')
  const [periodType, setPeriodType] = useState<number>(parsedInitialValues?.periodType)

  const form = useZodForm(schema, {
    defaultValues: parsedInitialValues,
  })

  const { setValue, control } = useZodForm(schema)

  const isUpdateForm = parsedInitialValues?.id

  const handleSubmit = useCallback(
    async formValue => {
      if (positionTypes.length === 0) return
      formValue.positionTypes = positionTypes.map(positionType => positionType)
      await onSubmit(formValue)
    },
    [positionTypes, onSubmit, form, t],
  )

  useEffect(() => {
    if (instructions) {
      form.setValue('instructions', instructions)
      form.clearErrors('instructions')
    }
  }, [form, instructions])

  useEffect(() => {
    if (periodType || periodType == 0) {
      setValue('periodType', periodType)
      form.setValue('periodType', periodType)
      form.clearErrors('periodType')
    }
  }, [form, periodType, setValue])

  const positionTypesOptions = Object.keys(IPositionType)
    .filter(v => isNaN(Number(v)))
    .map(t => t)
  const periodTypesOptions = Object.keys(IFrequencyPeriodType)
    .filter(v => isNaN(Number(v)))
    .map(t => t)

  return (
    <AppForm form={form} onSubmit={handleSubmit} hasInitialValues={parsedInitialValues}>
      <GenericDialogLayout
        title={title}
        pop={onCancel}
        removedCloseButton={false}
        actions={[
          <FormSubmitButton
            key="submit"
            createText={isUpdateForm ? t('update', { ns: 'common' }) : t('add', { ns: 'common' })}
            data-cy="submit"
          />,
        ]}
      >
        {isUpdateForm && <FormTextField name="id" type="hidden" />}
        <FormTextField name="title" label={t('title', { ns: 'common' })} data-cy="title" />
        <Grid container gap={1}>
          <Grid item xs={5}>
            <FormTextField
              name="periodFrequency"
              type={'number'}
              label={t('frequency', { ns: 'frequency' })}
              data-cy="periodFrequency"
            />
          </Grid>
          <Grid item xs={6} px={1} py={1}>
            <FormControl variant="filled" fullWidth error={!!form.formState.errors.periodType} data-cy="periodType">
              <InputLabel id="periodType">{t('periodType', { ns: 'frequency' })}</InputLabel>
              <Controller
                name="periodType"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => {
                  if (periodType || periodType == 0) {
                    value = periodType
                  }
                  return (
                    <Select
                      sx={{ height: '49px' }}
                      fullWidth
                      name="periodType"
                      value={IFrequencyPeriodType[value]}
                      onChange={event => {
                        setPeriodType(Number(IFrequencyPeriodType[event.target.value.toString()]))
                        onChange(event.target.value.toString())
                      }}
                      defaultValue={IFrequencyPeriodType[periodType]}
                      displayEmpty
                      label={t('periodType', { ns: 'component' })}
                      variant="filled"
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {periodTypesOptions?.map(periodType => (
                        <MenuItem key={periodType} value={periodType}>
                          {t(periodType.toString().toLocaleLowerCase(), { ns: 'common' })}
                        </MenuItem>
                      ))}
                    </Select>
                  )
                }}
              />
              {form.formState.errors.periodType && (
                <FormHelperText>
                  {t('periodTypeIs', { ns: 'frequency' })}
                  {form.formState.errors.periodType.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Autocomplete
          multiple
          options={positionTypesOptions}
          defaultValue={positionTypes?.map(positionType => IPositionType[positionType])}
          onChange={(_, v) => setPositionTypes(v.map(v => Number(IPositionType[v])))}
          freeSolo
          renderTags={(value: readonly string[], getTagProps) =>
            value?.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <React.Fragment>
                <Chip
                  sx={{ blockSize: 25, fontSize: 12 }}
                  variant="outlined"
                  label={t(option, { ns: 'position' })}
                  {...getTagProps({ index })}
                />
              </React.Fragment>
            ))
          }
          getOptionLabel={opt => t(opt, { ns: 'position' })}
          renderInput={params => (
            <AppTextField
              {...params}
              name="positionTypes"
              required
              error={form.formState.isSubmitted && (!positionTypes || positionTypes?.length == 0)}
              helperText={
                form.formState.isSubmitted && (!positionTypes || positionTypes?.length == 0)
                  ? t('provideValidInput', { ns: 'common' })
                  : ''
              }
              label={t('positionTypes', { ns: 'frequency' })}
              placeholder={t('addPositionType', { ns: 'frequency' })}
              data-cy="positionTypes"
            />
          )}
        />
        <FormTextField
          name="timeThreshold"
          type={'number'}
          label={t('timeThreshold', { ns: 'frequency' })}
          data-cy="timeThreshold"
        />
        <Box
          p={1}
          mx={1}
          my={1}
          sx={{ backgroundColor: 'lightgray', boxShadow: '0px 3px 9px #888888', borderRadius: 1 }}
        >
          <Box
            sx={{
              padding: '5px 0px 8px 5px',
            }}
          >
            <label
              style={{
                color: form.formState.errors.instructions ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)',
                fontSize: '1.1rem',
                fontWeight: '400',
              }}
            >
              {t('description', { ns: 'frequency' })}
            </label>
          </Box>
          <ReactQuill
            value={decode(instructions)}
            onChange={value => {
              setInstructions(encode(value))
            }}
            id="instructions"
            modules={{
              clipboard: {
                matchVisual: true,
              },
            }}
          />
          {form.formState.errors.instructions && (
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {t(form.formState.errors.instructions.message as string, { ns: 'frequency' })}
            </FormHelperText>
          )}
        </Box>
      </GenericDialogLayout>
    </AppForm>
  )
}
