import { IClientUserLinkDto } from '@lovoldsystem/authorization.client'
import { IApiResult } from '../generated-types/api-result'
import { ICustomerWithComponentCount, IServiceStation } from '../generated-types/service-station'
import { queryClient } from '../query-client'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from '@lovoldsystem/core.client'
import { serviceStationQueryKey } from '../query-keys'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/serviceStation`

export const serviceStationEndpoints = {
  getComponentsByStatus(customerId: EntityId, serviceStationId: EntityId, status: number) {
    return `${baseUrl(customerId)}/${serviceStationId}/${status}`
  },
  updateComponentStatus(customerId: EntityId, componentId: EntityId) {
    return `${baseUrl(customerId)}/component/${componentId}`
  },
  getServiceStationById(customerId: EntityId, serviceStationId: EntityId) {
    return `${baseUrl(customerId)}/${serviceStationId}`
  },
  getCustomerWithComponentCountById(customerId: EntityId, serviceStationId: EntityId) {
    return `${baseUrl(customerId)}/customerandcomponentscount/${serviceStationId}`
  },
  getStorages(customerId: EntityId) {
    return `${baseUrl(customerId)}/getStorages`
  },
  getServiceStations(customerId: EntityId) {
    return `${baseUrl(customerId)}/getServiceStations`
  },
  getServicesServiceStations(customerId: EntityId) {
    return `${baseUrl(customerId)}/getServicesServiceStations`
  },
  getAllTypeServiceStations(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getAllServiceStations(customerId: EntityId) {
    return `${baseUrl(customerId)}/all`
  },
  getAllEligibleServiceStations(customerId: EntityId) {
    return `${baseUrl(customerId)}/all-eligible`
  },
  createServiceStation(customerId: EntityId) {
    return `${baseUrl(customerId)}/`
  },
  updateServiceStation(customerId: EntityId, serviceStationId: EntityId) {
    return `${baseUrl(customerId)}/${serviceStationId}`
  },
  deleteServiceStation(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getServiceStationGroups(customerId: EntityId, serviceStationId: EntityId) {
    return `${baseUrl(customerId)}/${serviceStationId}/service-station-groups`
  },
  removeServiceStationGroupFromServiceStation(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}/service-station-groups`
  },
  getUsers(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}/users`
  },
  updateServiceStationGroups(customerId: EntityId, serviceStationId: EntityId) {
    return `${baseUrl(customerId)}/${serviceStationId}/add-service-station-groups`
  },
}

export const invalidateServiceStationCache = {
  getAllTypeServiceStations(customerId: EntityId) {
    queryClient.invalidateQueries(serviceStationEndpoints.getAllTypeServiceStations(customerId))
  },
  getServiceStations(customerId: EntityId) {
    queryClient.invalidateQueries(serviceStationEndpoints.getServiceStations(customerId))
  },
  getServiceStationById(customerId: EntityId, serviceStationId: EntityId) {
    queryClient.invalidateQueries(serviceStationEndpoints.getServiceStationById(customerId, serviceStationId))
  },
  getUsers: (customerId: EntityId, id: EntityId) =>
    queryClient.invalidateQueries(serviceStationQueryKey.GET_USERS_FOR_SERVICE_STATION(customerId, id)),
  getServiceStationGroups: (customerId: EntityId, serviceStationId: EntityId) =>
    queryClient.invalidateQueries(serviceStationQueryKey.GET_SERVICE_STATION_GROUPS(customerId, serviceStationId)),
}

export const invalidateGetServiceStationById = (customerId: EntityId, serviceStationId: EntityId) =>
  queryClient.invalidateQueries([serviceStationEndpoints.getServiceStationById(customerId, serviceStationId)])

export const useGetComponentsByStatus = (
  customerId: EntityId,
  serviceStationId: EntityId,
  status: number,
  search?: string,
  page?: number,
  pageSize?: number,
) =>
  useGet<Array<IServiceStation>>(serviceStationEndpoints.getComponentsByStatus(customerId, serviceStationId, status), {
    search,
    page,
    pageSize,
  })

export const useGetServiceStationById = (customerId: EntityId, serviceStationId: EntityId) =>
  useGet<IServiceStation>(serviceStationEndpoints.getServiceStationById(customerId, serviceStationId))

export const useGetStorages = (customerId: EntityId) =>
  useGet<Array<IServiceStation>>(serviceStationEndpoints.getStorages(customerId))

export const useGetServiceStations = (customerId: EntityId) =>
  useGet<Array<IServiceStation>>(serviceStationEndpoints.getServiceStations(customerId))

export const useGetServicesServiceStations = (customerId: EntityId) =>
  useGet<Array<IServiceStation>>(serviceStationEndpoints.getServicesServiceStations(customerId))

export const useGetAllServiceStations = (customerId: EntityId) =>
  useGet<Array<IServiceStation>>(serviceStationEndpoints.getAllServiceStations(customerId))

export const useGetAllEligibleServiceStations = (customerId: EntityId) =>
  useGet<Array<IServiceStation>>(serviceStationEndpoints.getAllEligibleServiceStations(customerId))

export const useGetAllTypeServiceStations = (customerId: EntityId, queryParams?: { page: number; pageSize: number }) =>
  useGet<IPaginatedResult<IServiceStation>>(serviceStationEndpoints.getAllTypeServiceStations(customerId), queryParams)

export const useGetCustomerWithComponentCountById = (customerId: EntityId, serviceStationId: EntityId) =>
  useGet<Array<ICustomerWithComponentCount>>(
    serviceStationEndpoints.getCustomerWithComponentCountById(customerId, serviceStationId),
  )

export const invalidateComponentGetByStatus = (customerId: EntityId, serviceStationId: EntityId, status: number) =>
  queryClient.invalidateQueries([serviceStationEndpoints.getComponentsByStatus(customerId, serviceStationId, status)])

export const useUpdateComponentStatus = (customerId: EntityId, componentId: EntityId) =>
  usePut<boolean, object>(serviceStationEndpoints.updateComponentStatus(customerId, componentId))

export const useCreateServiceStation = (customerId: EntityId) =>
  usePost<IApiResult, IServiceStation>(serviceStationEndpoints.createServiceStation(customerId))

export const useUpdateServiceStation = (customerId: EntityId, serviceStationId: EntityId) =>
  usePut<IApiResult, IServiceStation>(
    serviceStationEndpoints.updateServiceStation(customerId, serviceStationId),
    undefined,
  )

export const useDeleteServiceStation = (customerId: EntityId) =>
  useDelete<IApiResult>(serviceStationEndpoints.deleteServiceStation(customerId))

export const useGetServiceStationGroups = (customerId: EntityId, serviceStationId: EntityId) =>
  useGet<IPaginatedResult<IServiceStation>>(
    serviceStationEndpoints.getServiceStationGroups(customerId, serviceStationId),
  )

export const useGetUsersForServiceStation = (customerId: EntityId, id: EntityId) =>
  useGet<IClientUserLinkDto[]>(serviceStationEndpoints.getUsers(customerId, id), undefined, {
    queryKey: serviceStationQueryKey.GET_USERS_FOR_SERVICE_STATION(customerId, id),
  })

export const useRemoveServiceStationGroupFromServiceStation = (customerId: EntityId, id: EntityId) =>
  useDelete<IApiResult>(serviceStationEndpoints.removeServiceStationGroupFromServiceStation(customerId, id))

export const useUpdateServiceStationGroups = (customerId: EntityId, serviceStationId: EntityId) =>
  usePut<IApiResult, IServiceStation>(
    serviceStationEndpoints.updateServiceStationGroups(customerId, serviceStationId),
    undefined,
  )
