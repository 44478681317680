import { Badge, Box, styled, useTheme } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  invalidateMainComponentTypeCache,
  useAddMainComponentType,
  useGetMainComponentTypeWithMainComponentCount,
} from '../../../api/main-component-type'
import TabView, { IArrayItem } from '../../../components/tab/TabView'
import { CenteredSpinner } from '../../../components/Spinner'
import { ShowDialogProps } from '../../../show-dialog'
import { MainComponentTypeForm } from '../../main-component-type/MainComponentTypeForm'
import { MainComponentAddContainer } from './MainComponentAddContainer'
import { ICustomProperty } from '../../../generated-types/customProperty'
import { toast } from 'react-toastify'
import { IPrivilegeLevel, IMainComponentType } from '../../../enums'
import { ALREADY_EXISTS, DiscardComponentId } from '../../../constant'
import { useGetDiscardedMainComponents } from '../../../api/main-component'
import { DiscardedMainComponentList } from './DiscardedMainComponentList'
import { useGetAdminAndCustomerCustomProperties } from '../../../api/custom-property'
import LiceSkirt from 'src/assets/svg/lice-skirt.png'
import Ring from 'src/assets/svg/ring.png'
import Net from 'src/assets/svg/not.png'
import { AddOutlined, Hub } from '@mui/icons-material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import i18n from 'src/i18n'

export interface CreateMainComponentTypeProps extends ShowDialogProps {
  customerId: EntityId
  privilegeLevel?: IPrivilegeLevel
}

export const CreateMainComponentTypeDialog: React.FC<CreateMainComponentTypeProps> = ({
  pop,
  customerId,
  privilegeLevel,
}) => {
  const { t: translate } = useTranslation(['main-component-type', 'common'])
  const { mutateAsync, error, isLoading: isLoadingAdd } = useAddMainComponentType(customerId)
  useEffect(() => {
    if (error && error['data'] == ALREADY_EXISTS) {
      toast(translate('already-exists'), { type: 'error' })
    }
  }, [error])

  const onSubmit = async form => {
    await mutateAsync(form, {
      onSuccess: () => {
        toast(translate('successfully-added'), { type: 'success' })
        invalidateMainComponentTypeCache.useGetMainComponentTypes(customerId)
        invalidateMainComponentTypeCache.useGetMainComponentTypeWithMainComponentCount(customerId)
        pop(true)
      },
      onError: () => {
        toast.error(translate('addingError', { ns: 'common' }))
      },
    })
  }

  const { data, isLoading } = useGetAdminAndCustomerCustomProperties(customerId)
  if (isLoading) return <CenteredSpinner />

  return (
    <MainComponentTypeForm
      onSubmit={onSubmit}
      onCancel={() => pop()}
      userCustomProperties={data?.items as ICustomProperty[]}
      privilegeLevel={privilegeLevel}
      customerId={customerId}
      isLoading={isLoadingAdd}
      title={translate('addMainComponentType')}
    />
  )
}

const MainComponentItem: React.FC<{ type: IMainComponentType }> = ({ type }) => {
  const theme = useTheme()
  const icons = useMemo(() => {
    switch (type) {
      case IMainComponentType.Ring:
        return <ImageContainer src={Ring} />
      case IMainComponentType.Net:
        return <ImageContainer src={Net} />
      case IMainComponentType.LiceSkirt:
        return <ImageContainer src={LiceSkirt} />
      case IMainComponentType.Custom:
        return <IconContainer component={Hub} sx={{ color: getThemeColor(theme, MUIThemeColors.primaryDark) }} />
      default:
        return null
    }
  }, [type])

  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
      {icons}
    </Box>
  )
}

const MainComponentDashboard = () => {
  const { id: customerId } = useParams()
  const { showDialog } = useDialogCtx()
  const { data: mainComponentTypesWithCounts } = useGetMainComponentTypeWithMainComponentCount(customerId as EntityId)
  const { data: discardedComponents } = useGetDiscardedMainComponents(customerId as EntityId)
  const { t: translate } = useTranslation(['main-component'])
  const discardTab = useMemo(() => {
    return {
      id: DiscardComponentId,
      header: {
        title: translate('discardedComponents', { ns: 'main-component' }),
        content: <Badge badgeContent={discardedComponents?.totalCount} color="primary" />,
      },
      content: <DiscardedMainComponentList customerId={customerId as EntityId} />,
    } as IArrayItem
  }, [discardedComponents, customerId])

  const dynamicTabs = useMemo(() => {
    return (
      mainComponentTypesWithCounts?.map(tab => {
        return {
          id: tab.mainComponentTypeId as number,
          header: {
            title: ['en', 'us'].includes(i18n.language)
              ? JSON.parse(tab.mainComponentTypeName as string).en
              : JSON.parse(tab.mainComponentTypeName as string).no,
            content: (
              <OuterContainer display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Badge badgeContent={tab.mainComponentsCount} color="primary" showZero>
                  <MainComponentItem type={tab.componentType} />
                </Badge>
              </OuterContainer>
            ),
          },
          content: (
            <MainComponentAddContainer
              mainComponentTypeName={tab.mainComponentTypeName}
              componentTypeId={tab.mainComponentTypeId}
              itemCount={tab?.mainComponentsCount ?? 0}
            />
          ),
        }
      }) ?? []
    )
  }, [mainComponentTypesWithCounts, i18n.language])

  const onAddMainComponentType = async () => {
    const added = await showDialog(
      CreateMainComponentTypeDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  return (
    <CommonPageLayout titleSection={{ title: translate('main-components', { ns: 'header' }) }}>
      <Box className="bg-white " width={'100%'}>
        {dynamicTabs && (
          <TabView
            uniqueId={String(`customer-main-component-${customerId}`)}
            data={[...dynamicTabs, ...[discardTab]]}
            toolbarOptions={[
              {
                label: translate('addMainComponentType', { ns: 'main-component-type' }),
                Icon: <AddOutlined />,
                onClick: onAddMainComponentType,
              },
            ]}
            height={'calc(100vh - 220px)'}
          />
        )}
      </Box>
    </CommonPageLayout>
  )
}

const commonCss = {
  display: 'inline',
  margin: '3px',
  height: 26,
}

const OuterContainer = styled(Box)({
  width: '40px',
  height: '40px',
})

export const ImageContainer = styled('img')(commonCss)

export const IconContainer = styled(Box)(commonCss)

export default MainComponentDashboard
