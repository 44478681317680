import { FC, useEffect } from 'react'
import { Pane, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet.tilelayer.fallback'

export const BaseTileLayer: FC<{ customUrl?: string }> = ({ customUrl }) => {
  const map = useMap()

  useEffect(() => {
    const openStreetMap = L.tileLayer(customUrl ?? 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      noWrap: true,
      maxZoom: 18,
      minZoom: 4,
      attribution: '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    })

    const norwayBackgroundMap = (L as any).tileLayer.fallback(
      '//cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png',
      '//cache1.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png',
      '//cache2.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png',
      '//cache3.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png',
      '//cache4.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png',
    )

    const tileMapLayers = L.layerGroup()

    //add layers for base tile map
    tileMapLayers.addLayer(openStreetMap)
    tileMapLayers.addLayer(norwayBackgroundMap)

    if (map) {
      map.addLayer(tileMapLayers)
    }

    return () => {
      map.removeLayer(tileMapLayers)
    }
  }, [map, customUrl])

  return (
    <>
      <Pane name="wms-pane" style={{ zIndex: 500 }} />
    </>
  )
}
