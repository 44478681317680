import { Box, Card, Stack, styled, Typography, useTheme } from '@mui/material'
import { ILocality, ILocalityNameAndLogo } from '../../generated-types'
import { ReactComponent as MaintenanceIcon } from '../../assets/svg/maintenance.svg'
import { ReactComponent as DocumentIcon } from '../../assets/svg/documents-icon.svg'
import { ReactComponent as DeviationIcon } from '../../assets/svg/deviation.svg'
import { ReactComponent as InfoIcon } from '../../assets/svg/material-info.svg'
import { ReactComponent as FishIcon } from '../../assets/svg/fish.svg'
import { GetRGBA, getThemeColor } from 'src/utils'
import { convertFirstLetterToUpperCase } from 'src/utils/function'
import { Padding } from 'src/styles/paddingComponent'
import { MUIThemeColors } from 'src/mui-theme'
import { Flex } from 'src/styles/flexComponent'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Spinner } from '../Spinner'

interface LocationHeaderProps {
  locality: ILocalityNameAndLogo
  classname?: string
  onClick: (e: any) => void
  active?: boolean
  localityInfo?: ILocality
  isLoading?: boolean
}

export const LocationHeader = ({ locality, localityInfo, isLoading, classname, onClick, active }: LocationHeaderProps) => {
  const theme = useTheme()
  const [viewPanel, setViewPanel] = useState(false)

  const onHover = e => {
    e.preventDefault()
    setViewPanel(true)
  }

  return (
    locality &&
    <Box onClick={onClick} className={classname} width={'100%'} position={'relative'} onMouseLeave={(e) => {
      e.preventDefault()
      setViewPanel(false)
    }}>
      <HoverCard deviationCount={localityInfo?.deviationCount} documentCount={localityInfo?.expiredDocumentsCount} serviceCount={localityInfo?.servicesCount} visible={viewPanel} />
      <LocationHeaderCard style={{ height: active ? '100px' : '44px' }}
        sx={{ backgroundImage: `url(${locality.logoUrl ?? '/icons/card-background.png'})` }} >
        <Box flex={1} paddingTop={active ? '6px' : '3px'} >
          <Stack direction={'column'} className="pl-12-core pt-2-core pr-6-core pb-2-core" alignItems={'flex-start'} justifyContent={'center'} sx={{ backgroundColor: getThemeColor(theme, MUIThemeColors.primaryMain), width: 'fit-content', height: '37px' }} >
            <Name variant='body1' fontWeight={200} lineHeight={'18px'} color={getThemeColor(theme, MUIThemeColors.white)} >
              {locality.name}
            </Name>
            {
              active &&
              <>
                <Typography fontWeight={400} lineHeight={'12px'} variant='caption' color={getThemeColor(theme, MUIThemeColors.white)} >
                  {localityInfo?.locationNr && '#'} {localityInfo?.locationNr}
                </Typography >
              </>
            }
          </Stack >
        </Box>
        <Flex.Column justifyContent={'flex-end'} className='p-4-core'>
          <Flex.Row flex={1} >
            {active && localityInfo?.hasFish &&
              <>
                <Padding.p2 sx={{ backgroundColor: getThemeColor(theme, MUIThemeColors.primaryMain), height: '21px', borderRadius: '50%', width: '21px' }}>
                  {
                    <FishIcon color={theme.palette.white[theme.palette.mode]} />
                  }
                </Padding.p2>
              </>
            }

            <Flex.Row flex={1} justifyContent={'end'}>
              {
                active && (isLoading ?
                  <Spinner size={'15px'} /> :
                  <>
                    {localityInfo?.servicesCount != undefined && localityInfo?.deviationCount != undefined && localityInfo?.expiredDocumentsCount != undefined &&
                      (localityInfo?.servicesCount > 0 || localityInfo?.expiredDocumentsCount > 0 || localityInfo?.deviationCount > 0) &&
                      <Padding.pl4 onMouseOver={(e) => onHover(e)} sx={{ height: '21px' }}>
                        {/* <ActivityIcon sx={{ backgroundColor: theme.palette.warning[theme.palette.mode], paddingTop: '3px', paddingLeft: '4px' }}> */}
                        <InfoIcon style={{ backgroundColor: theme.palette.white[theme.palette.mode], fontSize: '15px', borderRadius: '50%' }} />
                        {/* </ActivityIcon> */}
                      </Padding.pl4>
                    }
                  </>
                )}
            </Flex.Row>
          </Flex.Row >
        </Flex.Column>
      </LocationHeaderCard >
    </Box >
  )
}

const HoverCard = ({ deviationCount, documentCount, serviceCount, visible }) => {
  const { t } = useTranslation(['common', 'sidebar'])
  const theme = useTheme()
  return <HoverCardContainer height={visible ? '100px' : '0px'}>
    <Flex.Column justifyContent={'center'} width={'124px'} height={'100%'}>
      <Flex.Row >
        <Padding.pr6 sx={{ paddingTop: '2px' }}>
          <MaintenanceIcon height={'12px'} width={'12px'} />
        </Padding.pr6>
        <Typography width={'100%'} variant='body2' color={getThemeColor(theme, MUIThemeColors.white)}> {convertFirstLetterToUpperCase(t('services'))}</Typography>
        <Typography variant='body2' color={getThemeColor(theme, MUIThemeColors.white)}> {serviceCount?.toString().padStart(3, '0')}</Typography>
      </Flex.Row>
      <Flex.Row justifyContent={'space-evenly'}>
        <Padding.pr6 sx={{ paddingTop: '2px' }}>
          <DeviationIcon height={'12px'} width={'12px'} />
        </Padding.pr6>
        <Typography width={'100%'} variant='body2' color={getThemeColor(theme, MUIThemeColors.white)}> {convertFirstLetterToUpperCase(t('deviations'))}</Typography>
        <Typography variant='body2' color={getThemeColor(theme, MUIThemeColors.white)}> {deviationCount?.toString().padStart(3, '0')}</Typography>
      </Flex.Row>
      <Flex.Row justifyContent={'space-evenly'}>
        <Padding.pr6 sx={{ paddingTop: '2px' }}>
          <DocumentIcon height={'12px'} width={'12px'} />
        </Padding.pr6>
        <Typography width={'100%'} variant='body2' color={getThemeColor(theme, MUIThemeColors.white)}> {convertFirstLetterToUpperCase(t('documents'))}</Typography>
        <Typography variant='body2' color={getThemeColor(theme, MUIThemeColors.white)}> {documentCount?.toString().padStart(3, '0')}</Typography>
      </Flex.Row>
    </Flex.Column>
  </HoverCardContainer>
}

//styled components

const HoverCardContainer = styled(Box)`
  position:absolute;
  display:flex;
  flex-direction:row;
  justify-content:center;
  background-color: ${props => getThemeColor(props.theme, MUIThemeColors.primaryMain)};
  border-radius: 5px;
  cursor: pointer;
  transition: height 500ms;
  overflow: hidden;
  width: 100%;
`

const Name = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'auto',
  display: 'inline-block',
  fontWeight: 600,
}))

const LocationHeaderCard = styled(Card)(({ theme }) => ({
  '&.MuiPaper-root': {
    backgroundColor: theme.palette.white[theme.palette.mode],
    color: theme.palette.primaryDark[theme.palette.mode],
    boxShadow: `0px 0px 6px ${GetRGBA(theme.palette.black[theme.palette.mode], 0.29)}`,
  },
  backgroundSize: '300px auto',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  color: theme.palette.white[theme.palette.mode],
}))

const ActivityIcon = styled(Box)`
  height: 16px;
  width: 16px;
  border-radius: 8px;
`
