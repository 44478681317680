import { useParams } from 'react-router-dom'
import { UserInvitesTable } from './UserInvitesTable'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const UserInvitesScreen = () => {
  const { id: customerId } = useParams()
  const { t: translate } = useTranslation(['admin-sidebar', 'common'])

  const theme = useTheme()

  return (
    <CommonPageLayout titleSection={{ title: translate('user-invites') }} minHeight={'calc(100vh - 100px)'}>
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <UserInvitesTable customerId={customerId!} />
      </Box>
    </CommonPageLayout>
  )
}
