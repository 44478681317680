import { Tooltip, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import FormSchemasDialog from './FormSchemasDialog'
import { useParams } from 'react-router-dom'
import { IFormSchema, IFormSchemaDto, formSchemaReportTypes } from '../../generated-types/form-schema'
import {
  invalidateSchemaForm,
  useBulkExcelExport,
  useDeleteFormSchemaReportById,
  useGetFormSchemaReports,
} from '../../api/form-schema'
import { AddCircleOutline, InfoOutlined } from '@mui/icons-material'
import { downloadFileLocal } from '../../utils/downloadFile'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDialogCtx } from '../../hooks/context-hooks'
import { toast } from 'react-toastify'
import { invalidateLocalityFrequencyCache } from '../../api/locality-frequency'
import { CommonPageLayout } from '../common/CommonPageLayout'
import { Padding } from 'src/styles/paddingComponent'
import { ITaskFormSchema } from 'src/generated-types/taskFormSchema'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import OverallReportTabContainer from './OverallReportTabContainer'
import OverallReportListContainer from './OverallReportListContainer'

interface IOverallReport {
  deviationId?: string | EntityId
  localityFrequencyId?: number
  groupId?: string
  modelCustomerId?: EntityId
  mainComponentId?: string
  type: formSchemaReportTypes
  formInitialValues: object
  disabled?: boolean
  title?: string
  context?: React.Context<any> | null
  usedSchemas?: (schemas: (number | undefined)[] | undefined) => void
  scheduledTaskId?: number
  requiredReports?: ITaskFormSchema[]
  createPermissions: number[]
  deletePermissions: number[]
  height?: number
  listView?: boolean
}

const OverallReport: React.FC<IOverallReport> = ({
  deviationId,
  type,
  localityFrequencyId,
  groupId,
  formInitialValues,
  mainComponentId,
  disabled,
  title,
  usedSchemas = null,
  scheduledTaskId,
  context,
  requiredReports,
  createPermissions,
  deletePermissions,
  modelCustomerId,
  height = 300,
  listView,
}) => {
  const { id, customerId: cId, localityId } = useParams()
  const customerId = useMemo(() => cId ?? (id as EntityId) ?? modelCustomerId, [cId, id, modelCustomerId])
  const [bulkUploadLoading, setBulkUploadLoading] = useState(0)
  const { t } = useTranslation(['overall-reports', 'task'])
  const { showConfirmDialog } = useDialogCtx()
  const theme = useTheme()
  const { data, isLoading } = useGetFormSchemaReports(customerId, {
    type,
    localityFrequencyId,
    groupId,
    deviationId: deviationId as string | undefined,
    mainComponentId,
    scheduledTaskId,
  })
  const { mutateAsync: bulkExport } = useBulkExcelExport(customerId as EntityId)
  const { mutateAsync: deleteReport } = useDeleteFormSchemaReportById(customerId as EntityId)
  const { showDialog } = useDialogCtx()

  const requiredReportsList = useMemo(
    () =>
      requiredReports
        ?.map((value, index) => {
          if (index === requiredReports?.length - 1) {
            return value.formSchema.name
          } else {
            return value.formSchema.name + ' | '
          }
        })
        .join(''),
    [requiredReports],
  )

  const handleExport = async (row: IFormSchemaDto) => {
    setBulkUploadLoading(row.id as number)
    await bulkExport({
      formSchemaId: row.id,
      type,
      localityFrequencyId,
      groupId,
      deviationId,
      mainComponentId,
      scheduledTaskId,
      userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then(res => {
        downloadFileLocal(res, row?.name)
      })
      .finally(() => {
        setBulkUploadLoading(0)
      })
  }

  const onAdd = () => {
    showDialog(
      FormSchemasDialog,
      {
        componentProps: {
          customerId: customerId,
          localityId: localityId as any,
          property: {
            deviationId: deviationId,
            localityFrequencyId: localityFrequencyId,
            groupId: groupId,
            mainComponentId: mainComponentId,
            type: type,
            scheduledTaskId: scheduledTaskId,
          },
          formInitialValues: formInitialValues,
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
  }

  const handleDelete = useCallback((id: number) => {
    showConfirmDialog(
      t('deleteFormSchemaReportTitle', { ns: 'form-schema-report' }),
      t('deleteFormSchemaReportMessage', { ns: 'form-schema-report' }),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        await deleteReport(id)
          .then(() => {
            toast.success(t('form-schema-report-deleted', { ns: 'form-schema-report' }))
            invalidateSchemaForm.getFormSchemaReports(customerId as EntityId)
            invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId as EntityId, localityId as EntityId)
            pop()
          })
          .catch(() => {
            toast.error(t('error-deleting-form-schema-report', { ns: 'form-schema-report' }))
          })
      },
    )
  }, [])

  useEffect(() => {
    const formSchemaIds = data
      ?.filter(s => s?.formSchemaReports != null && s?.formSchemaReports?.length > 0)
      .map(s => s.id)
    if (usedSchemas) {
      usedSchemas(formSchemaIds)
    }
  }, [data])

  const onComplete = (row: IFormSchema | null | undefined) => {
    showDialog(
      FormSchemasDialog,
      {
        componentProps: {
          customerId: customerId,
          localityId: localityId as any,
          property: {
            deviationId: deviationId,
            localityFrequencyId: localityFrequencyId,
            groupId: groupId,
            mainComponentId: mainComponentId,
            type: type,
            scheduledTaskId: scheduledTaskId,
          },
          formInitialValues: formInitialValues,
          selectedSchemaForm: row,
        },
        opts: {
          maxWidth: 'lg',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
  }

  return (
    <CommonPageLayout
      titleSection={{ subtitle: title ? title : t('overall-reports') }}
      titleContainerProps={{ paddingBottom: '12px' }}
      topAction={[
        {
          buttonText: t('new-form'),
          buttonIcon: <AddCircleOutline />,
          onClick: onAdd,
          context: context,
          disabled: disabled,
          permissionBinaryValues: createPermissions,
          variant: 'outlined',
          shadowWeight: 0,
        },
      ]}
      warningNote={
        requiredReports != null ? (
          <Tooltip
            sx={{ cursor: 'pointer' }}
            title={`${t('rerquired-reports', { ns: 'task' })} ${requiredReportsList}`}
          >
            <InfoOutlined />
          </Tooltip>
        ) : undefined
      }
    >
      <Padding.p8 borderRadius={'4px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)}>
        {listView ? (
          <OverallReportListContainer
            onComplete={onComplete}
            disabled={disabled}
            bulkUploadLoading={bulkUploadLoading}
            createPermissions={createPermissions}
            context={context}
            handleExport={handleExport}
            data={data}
            deletePermissions={deletePermissions}
            handleDelete={handleDelete}
            isLoading={isLoading}
            height={height}
            requiredReports={requiredReports}
          />
        ) : (
          <OverallReportTabContainer
            onComplete={onComplete}
            disabled={disabled}
            bulkUploadLoading={bulkUploadLoading}
            createPermissions={createPermissions}
            context={context}
            handleExport={handleExport}
            data={data}
            deletePermissions={deletePermissions}
            handleDelete={handleDelete}
            isLoading={isLoading}
            height={height}
            requiredReports={requiredReports}
          />
        )}
      </Padding.p8>
    </CommonPageLayout>
  )
}

export default OverallReport
