import L, { LatLngExpression } from 'leaflet'
import { Polyline, Tooltip, useMap, useMapEvent } from 'react-leaflet'
import { IPosition } from '../../../../../generated-types'
import {
  mapOptions,
  opacity,
  deviationPositions,
  totalDeviationMultiplier,
  grayedOpacity,
  minZoom,
} from '../constraints'
import { getBearing, getDistance, destVincenty, midpoint, getPositionName } from '../utils'
import { memo, useEffect, useState } from 'react'

interface IBridle {
  position: IPosition
  navigateToPosition: (positionId: number) => void
}

export const Bridle: React.FC<IBridle> = memo(({ position, navigateToPosition }) => {
  const generateBridles = true

  const map = useMap()
  const [zoomLevel, setZoomLevel] = useState(Math.round(map.getZoom()))

  useEffect(() => { 
    setZoomLevel(Math.floor(map.getZoom())) 
  }, [map.getZoom()])

  const handleZoomEnd = event => {
    const newZoomLevel = event.target.getZoom()
    setZoomLevel(Math.round(newZoomLevel))
  }
  useMapEvent('zoomend', handleZoomEnd)

  if (position.isHidden || !generateBridles || !position.connectedPositions[0] || !position.connectedPositions[1])
    return <></> //hide if visible set to false.

  const content = mapOptions.lang.bridle + ' ' + getPositionName(position)

  const bearing = getBearing(
    position.connectedPositions[0].latitude,
    position.connectedPositions[0].longitude,
    position.connectedPositions[1].latitude,
    position.connectedPositions[1].longitude,
  )

  const distance = getDistance(
    position.connectedPositions[0].latitude,
    position.connectedPositions[0].longitude,
    position.connectedPositions[1].latitude,
    position.connectedPositions[1].longitude,
  )

  const destPos1 = destVincenty(
    position.connectedPositions[0].latitude,
    position.connectedPositions[0].longitude,
    bearing - 18,
    (distance / 100) * 50,
  )

  const destPos2 = destVincenty(
    position.connectedPositions[0].latitude,
    position.connectedPositions[0].longitude,
    bearing,
    (distance / 100) * 50,
  )

  const destPos3 = destVincenty(
    position.connectedPositions[0].latitude,
    position.connectedPositions[0].longitude,
    bearing + 18,
    (distance / 100) * 50,
  )

  if (position.BridleFeets !== 2 && position.BridleFeets !== 1) {
    const options = {
      simpleline: 2,
      weight: 4,
      opacity: position.isTransparent ? grayedOpacity : opacity,
      color:
        position.deviationsCount > 0
          ? opacity !== 1.0
            ? mapOptions.bridleColor
            : mapOptions.deviationColor
          : mapOptions.bridleColor,
      pane: position.isWorkAssignmentPosition ? 'bridleUpdated' : 'bridle',
    }
    const bridle2 = L.polyline(
      [
        [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
        [+destPos2[0], +destPos2[1]],
      ],
      options,
    )
    //.addTo(isFleet ? lgFleetBridles : lgBridles)
  }

  let latlngs
  if (position.BridleFeets === 1) {
    latlngs = [
      [
        [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
        [destPos2[0], destPos2[1]],
      ],
    ]
  } else {
    latlngs = [
      [
        [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
        [destPos1[0], destPos1[1]],
        [destPos2[0], destPos2[1]],
        [destPos3[0], destPos3[1]],
        [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
      ],
    ]
  }

  const pathOptions = {
    simplepoly: 2,
    fillOpacity: 0,
    opacity: position.isTransparent ? grayedOpacity : opacity,
    weight: zoomLevel <= minZoom ? 1 : zoomLevel - minZoom,
    color:
      position.deviationsCount > 0
        ? opacity !== 1.0
          ? mapOptions.bridleColor
          : mapOptions.deviationColor
        : mapOptions.bridleColor,
    pane: position.isWorkAssignmentPosition ? 'bridleUpdated' : 'bridle',
  }

  if (position.deviationsCount != 0) {
    const mid = midpoint(
      position.connectedPositions[0].latitude,
      position.connectedPositions[0].longitude,
      +destPos2[0],
      +destPos2[1],
      0.5,
    )
    deviationPositions.push([mid[0], mid[1], totalDeviationMultiplier * position.deviationsCount])
  }

  return (
    <Polyline
      key={position.id}
      positions={latlngs as LatLngExpression[]}
      pathOptions={pathOptions}
      pane={pathOptions.pane}
      eventHandlers={{
        click: () => {
          navigateToPosition(position.id)
        },
      }}
    >
      <Tooltip sticky>
        <strong>{content}</strong>
      </Tooltip>
    </Polyline>
  )
})
