import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AppTextField } from './AppTextField'
import { CalendarPickerView } from '@mui/x-date-pickers'
import { useFormFieldError } from './FormCore'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { addCurrentTimeToDate } from '../utils/function'

export interface GenericDatePicker<T = any> {
  disabled?: boolean
  value?: T
  defaultValue?: T
  name: string
  label?: string
  error?: boolean
  inputFormat?: string
  helperText?: string
  field?: any
  views?: CalendarPickerView[]
  openTo?: CalendarPickerView
  shouldDisableDate?: ((day: Date) => boolean) | undefined
  shouldDisableYear?: ((year: any) => boolean) | undefined
  shouldDisableMonth?: ((month: any) => boolean) | undefined
  isWithoutAppForm?: boolean
  allowNullable?: boolean
}

interface DatePickerProps extends GenericDatePicker {
  onChange: (value: any) => any
  minDate?: Date | undefined
  onClick?: (value: any) => any
  onFocus?: (value: any) => any
}

interface CustomDatePickerProps extends GenericDatePicker {
  onChange?: (value: any) => any
  minDate?: Date | undefined
  onClick?: (value: any) => any
  onFocus?: (value: any) => any
}

export default function BasicDatePicker({
  isWithoutAppForm = false,
  onChange,
  value,
  minDate,
  onClick,
  onFocus,
  ...rest
}: CustomDatePickerProps) {
  if (isWithoutAppForm) {
    if (!onChange && value) {
      Logger.error('onChange & value required')
      return <></>
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {isWithoutAppForm ? (
        <GenericDatePicker
          onChange={onChange as (value: any) => any}
          value={value}
          minDate={minDate}
          {...rest}
          onClick={onClick}
          onFocus={onFocus}
        />
      ) : (
        <DatePickerWithAppForm
          onChange={onChange}
          value={value}
          {...rest}
          minDate={minDate}
          onClick={onClick}
          onFocus={onFocus}
        />
      )}
    </LocalizationProvider>
  )
}

const DatePickerWithAppForm: React.FC<CustomDatePickerProps> = ({
  helperText,
  error,
  name,
  value,
  onChange,
  minDate,
  allowNullable,
  onClick,
  onFocus,
  ...rest
}) => {
  const { hasError, message } = useFormFieldError(name)
  const { field } = useController({ name })

  const { setError, clearErrors } = useFormContext()
  const { t } = useTranslation()

  return (
    <GenericDatePicker
      name={name}
      error={error ?? hasError}
      helperText={helperText ?? message}
      value={value ?? field.value}
      onChange={date => {
        onChange ? onChange(addCurrentTimeToDate(date)) : field.onChange(addCurrentTimeToDate(date))
        if (date || allowNullable) {
          clearErrors(name)
        } else {
          setError(name, { type: 'required', message: t('required') })
        }
      }}
      minDate={minDate}
      {...rest}
      onClick={onClick}
      onFocus={onFocus}
    />
  )
}

const GenericDatePicker: React.FC<DatePickerProps> = ({
  value,
  defaultValue,
  name,
  error,
  helperText,
  inputFormat,
  minDate,
  onClick,
  onFocus,
  ...rest
}) => {
  return (
    <DatePicker
      value={value || defaultValue || null}
      inputFormat={inputFormat ?? 'yyyy/dd/MM'}
      minDate={minDate ? new Date(minDate as Date) : undefined}
      {...rest}
      renderInput={params => (
        <AppTextField
          type="date"
          variant="outlined"
          value={addCurrentTimeToDate(value || defaultValue) || null}
          fullWidth
          helperText={helperText}
          name={name}
          {...params}
          defaultValue={defaultValue}
          error={error}
          onClick={onClick}
          onFocus={onFocus}
        />
      )}
    />
  )
}
