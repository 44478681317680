import { Stack, styled, useMediaQuery, useTheme } from '@mui/material'
import { GridActionButton } from '../../../../components/common/GridActionButton'
import { ReactComponent as Extension } from '../../../../assets/svg/extension.svg'
import { ReactComponent as Move } from '../../../../assets/svg/move-component.svg'
import { CertificationValidationPeriod } from '../../../../enums'
import { IMainComponentStatus } from '../../../../generated-types/main-component-status'
import { useTranslation } from 'react-i18next'
import { useDialogCtx } from '../../../../hooks/context-hooks'
import { CreateCertificateExtensionDialog } from '../../../../components/main-component/CreateCertificateExtensionDialog'
import { MainComponentSendModalComponent } from '../../positions/components/MainComponentSendModal'
import { toast } from 'react-toastify'

const CustomStyleButton = styled(GridActionButton)(({ theme }) => ({
  ':hover': {
    backgroundColor: theme.palette.secondaryLight[theme.palette.mode],
  },
}))

export const CageStatusActionContainer: React.FC<{
  cage: IMainComponentStatus
  status: CertificationValidationPeriod
  customerId: EntityId
  localityId: EntityId
  actionStatus?: { hasAddCertificateAccess: boolean; hasSendToServiceStationAccess: boolean }
}> = ({ cage, status, customerId, localityId, actionStatus }) => {
  const isDesktopView = useMediaQuery('(min-width: 900px)')
  const theme = useTheme()
  const { t: translate } = useTranslation(['main-component'])
  const { showDialog } = useDialogCtx()

  const svgIconColor =
    status != CertificationValidationPeriod.NA
      ? status === CertificationValidationPeriod.LOW
        ? theme.palette.white[theme.palette.mode]
        : cage.isValidCertificateAvailable
        ? theme.palette.secondaryMain[theme.palette.mode]
        : theme.palette.primaryMain[theme.palette.mode]
      : theme.palette.secondaryMain[theme.palette.mode]

  const onSend = async () => {
    const updated = await showDialog(MainComponentSendModalComponent, {
      title: translate('sendToServiceStation', { ns: 'main-component' }),
      componentProps: {
        customerId: customerId as EntityId,
        localityId: localityId as EntityId,
        mooringId: cage?.mooringId as EntityId as EntityId,
        positionId: cage?.id as EntityId as EntityId,
        mainComponents: [...(cage?.nets ?? []), ...(cage?.rings ?? []), ...(cage?.liceSkirts ?? [])],
      },
      opts: {
        maxWidth: 'sm',
        fullWidth: true,
      },
    })
    if (!updated) return
  }

  const onAddCertificateForComponent = async () => {
    const added = await showDialog(CreateCertificateExtensionDialog, {
      title: translate('addExtension', { ns: 'certificateExtension' }),
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
      componentProps: {
        mainComponentId: cage?.nextExpiringMainComponentId as EntityId,
        customerId: customerId as EntityId,
        certificateId: cage?.nextExpiringCertificateId as EntityId,
      },
    })
    if (!added) return
  }

  const boxBackgroundColor =
    status === CertificationValidationPeriod.LOW
      ? theme.palette.errorMain[theme.palette.mode]
      : theme.palette.secondaryLight[theme.palette.mode]

  return (
    <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={!isDesktopView ? 2 : 1}>
      {cage?.nets?.length > 0 && (
        <CustomStyleButton
          onClick={() => {
            if (cage?.numberOfExtensions != undefined && !(cage?.numberOfExtensions < 2)) {
              toast(translate('CannotAddMoreThanTwoExtensions', { ns: 'certificate' }), { type: 'warning' })
              return
            }
            onAddCertificateForComponent()
          }}
          toolTipTitle={translate('addExtension', { ns: 'certificateExtension' })}
          bgColor={boxBackgroundColor}
          height="24px"
          width="24px"
          shadowWeight={1}
          buttonIcon={<Extension color={svgIconColor} height={'14px'} width={'14px'} />}
          disabled={
            cage.isValidCertificateAvailable ||
            status === CertificationValidationPeriod.NA ||
            actionStatus?.hasAddCertificateAccess === false
          }
        />
      )}
      <CustomStyleButton
        onClick={() => {
          onSend()
        }}
        toolTipTitle={translate('sendToServiceStation', { ns: 'main-component' })}
        bgColor={boxBackgroundColor}
        height="24px"
        width="24px"
        shadowWeight={1}
        sx={{
          ':hover': {
            backgroundColor: theme.palette.secondaryLight[theme.palette.mode],
          },
        }}
        buttonIcon={
          <Move
            color={
              (cage.nets?.length === 0 && cage.rings?.length === 0) || !(cage?.nets && cage?.rings)
                ? theme.palette.secondaryMain[theme.palette.mode]
                : status === CertificationValidationPeriod.LOW
                ? theme.palette.white[theme.palette.mode]
                : theme.palette.primaryMain[theme.palette.mode]
            }
            height={'14px'}
            width={'14px'}
          />
        }
        disabled={
          (cage.nets?.length === 0 && cage.rings?.length === 0) ||
          !(cage?.nets && cage?.rings) ||
          actionStatus?.hasSendToServiceStationAccess === false
        }
      />
    </Stack>
  )
}
