import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { Button, Paper } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { ICustomProperty } from '../generated-types/customProperty'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { MdAdd } from 'react-icons/md'
import { useCallback } from 'react'
import { AppTextField } from './AppTextField'
import { IPrivilegeLevel } from 'src/enums'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { CreateCustomPropertyDialog } from 'src/screens/admin/custom-type/CustomPropertyAddDialog'

interface MultipleSelectCheckmarkProps {
  customProperties: Array<any>
  setCustomProperties: React.Dispatch<React.SetStateAction<any[]>>
  data: Array<ICustomProperty>
  privilegeLevel: IPrivilegeLevel
  customerId: EntityId
}

export const MultipleSelectCheckmark: React.FC<MultipleSelectCheckmarkProps> = ({
  customProperties,
  setCustomProperties,
  data,
  privilegeLevel = IPrivilegeLevel.Customer,
  customerId,
}) => {
  const { showDialog } = useDialogCtx()
  const getTranslatedLabel = React.useCallback(
    (property: ICustomProperty) =>
      ['en', 'us'].includes(i18n.language)
        ? JSON.parse(property.label as string).en
        : JSON.parse(property.label as string).no,
    [i18n.language],
  )
  const { t } = useTranslation(['custom-property', 'common'])

  const handleCustomPropertyChange = useCallback(v => {
    setCustomProperties(prev => [...v])
  }, [])

  const onAddCustomProperty = async () => {
    const added = await showDialog(
      CreateCustomPropertyDialog,
      {
        componentProps: {
          customerId: customerId,
          isAdmin: privilegeLevel == IPrivilegeLevel.SuperAdmin,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  return (
    <div>
      <Autocomplete
        multiple
        id="checkboxes-tags"
        options={data}
        value={customProperties}
        disableCloseOnSelect
        getOptionLabel={option => getTranslatedLabel(option)}
        onChange={(e, v) => handleCustomPropertyChange(v)}
        renderOption={(props, option, { selected }) => (
          <>
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={customProperties?.find(c => c.id == option.id) != null} />
              {getTranslatedLabel(option)}
            </li>
          </>
        )}
        style={{ width: 500 }}
        renderInput={params => <AppTextField {...params} label={t('add-fields')} />}
        PaperComponent={({ children }) => {
          return (
            <Paper>
              {children}
              <Button
                color="primary"
                fullWidth
                sx={{ justifyContent: 'flex-start', pl: 2 }}
                onMouseDown={onAddCustomProperty}
                startIcon={<MdAdd />}
                variant="contained"
              >
                {t('addNewCustomProperty', { ns: 'custom-property' })}
              </Button>
            </Paper>
          )
        }}
      />
    </div>
  )
}
