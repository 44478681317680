import { LayerGroup, WMSTileLayer } from 'react-leaflet'
import { agricultureZones } from './constraints'

export const ExtremePoints: React.FC = () => {
  return (
    <LayerGroup>
      <WMSTileLayer
        url={agricultureZones.extremePoint}
        layers="ytterpunkt_ihht_akvakulturregisteret"
        transparent={true}
        zIndex={500}
        format="image/png"
        pane="wms-pane"
      />
    </LayerGroup>
  )
}
