import { Box, Grid, styled, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useAddDocument, invalidateDocument } from '../../../../api/document'
import { invalidateDocumentCategoryCache } from '../../../../api/document-category'
import BasicDatePicker from '../../../../components/DateTimePicker'
import {
  AppForm,
  FormTextField,
  FormSelectField,
  FormCheckbox,
  FormFileCoreUpload,
} from '../../../../components/FormCore'
import { useZodForm } from '../../../../hooks/zod-form'
import { ShowDialogProps } from '../../../../show-dialog'
import { invalidateDeviation } from '../../../../api/deviation'
import { invalidateLocalityCache } from '../../../../api/localities'
import { GenericDialogLayout } from '../../../../components/GenericDialogLayout'
import { MainButton } from '../../../../components/MainButton'
import { IDocumentCategory } from '../../../../generated-types'
import { ReactComponent as FileIcon } from 'src/assets/svg/fileUploadIcon.svg'
import { invalidateDocumentCategory } from 'src/api/users/document-category'
import { setToMidnight } from 'src/utils'
import { useGetAllMooringsByLocalityId } from 'src/api/moorings'
import { MooringDropdownDto } from 'src/generated-types/mooring-drop-down-dto'
import { invalidationActivityLogCache } from 'src/api/activity-log'
import { invalidateCustomer } from 'src/api/customer'
import { useGetMainComponentById } from 'src/api/main-component'
import { MainComponentStatus } from 'src/enums'
import { Flex } from 'src/styles/flexComponent'
import { useGetCustomerAndAdminDocumentCategories } from 'src/api/customer-document-category'
import { getMooringName } from 'src/utils/function'

export interface ICreateDocumentsParams {
  mainComponentId?: EntityId
  mooringId?: EntityId
  positionId?: EntityId
  componentId?: EntityId
  deviationId?: EntityId
  deviationFollowUpId?: EntityId
  localityFrequencyId?: EntityId
  mooringName?: string
}

export interface CreateDocumentDialogProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  createDocumentsParams: ICreateDocumentsParams
}

export const CreateDocumentDialog: React.FC<CreateDocumentDialogProps> = ({
  pop,
  customerId,
  localityId,
  createDocumentsParams,
}) => {
  const { t } = useTranslation(['document', 'common'])

  const [isFacilityCertificate, setIsFacilityCertificate] = useState(false)

  const documentTypes = useGetCustomerAndAdminDocumentCategories(customerId)
  const { data: moorings } = useGetAllMooringsByLocalityId(customerId as EntityId, localityId as EntityId)
  const { data: mainComponent } = useGetMainComponentById(customerId, createDocumentsParams.mainComponentId ?? 0)

  const schema = z.object({
    title: z.string().nonempty(t('provideValidInput', { ns: 'common' })),
    documentCategoryId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    dueDate: z.any().or(z.string()).optional(),
    notes: z.string().optional(),
    version: z.string().optional(),
    fileId: z.union([z.string(), z.number()]),
    mainComponentId: z.string().optional(),
    mooringId: z
      .object({
        id: z.number(),
      })
      .or(z.string())
      .or(z.null())
      .optional()
      .transform(value => (typeof value == 'object' ? value?.id : value)),
    positionId: z.string().optional(),
    componentId: z.string().optional(),
    deviationId: z.string().optional(),
    deviationFollowUpId: z.string().optional(),
    localityFrequencyId: z.string().optional(),
    localityId: z.string().optional(),
    connectAsLocalityCertificate: z.boolean().optional(),
  })

  const form = useZodForm(schema, {
    defaultValues:
      !createDocumentsParams || Object.keys(createDocumentsParams).length === 1
        ? {}
        : createDocumentsParams.mooringId
        ? { mooringId: { name: createDocumentsParams.mooringName, id: Number(createDocumentsParams.mooringId) } }
        : {},
  })

  const mutation = useAddDocument(customerId)
  const onSubmit = async form => {
    form.localityId = localityId
    form.positionId = createDocumentsParams.positionId
    form.mooringId = form.mooringId == undefined ? createDocumentsParams.mooringId : form.mooringId
    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidateDocumentCategoryCache.getDocumentCategoriesWithDocuments()
        invalidateDocument.getDocuments(customerId)
        if (createDocumentsParams.deviationId) {
          invalidateDeviation.getDeviationDocuments(customerId, createDocumentsParams.deviationId)
        }
        if (localityId != null) {
          invalidateLocalityCache.getLocality(customerId, localityId)
        }
        if (createDocumentsParams.positionId) {
          invalidateDocumentCategory.getDocumentCategoriesWithDocuments(customerId)
        }
        invalidateDocument.getExpireCounts(customerId, localityId)
        invalidateDocumentCategory.getDocumentCategoriesWithDocuments(customerId, { localityId })
        invalidateLocalityCache.getLocalitiesWithInfoCount(customerId)
        invalidationActivityLogCache.getCageActivityLogs()
        invalidateCustomer.getCustomersActivityCounts(customerId)
        pop()
      },
    })
  }

  const handleChange = useCallback((category: IDocumentCategory) => {
    if (category.categoryName === 'Facility Certificate') {
      setIsFacilityCertificate(true)
    } else {
      setIsFacilityCertificate(false)
    }
    form.setValue('documentCategoryId', category as any)
  }, [])

  const onChangeDocumentCategory = useCallback(
    (e, value) => {
      handleChange(value)
    },
    [handleChange],
  )

  return (
    <GenericDialogLayout
      title={t('addDocuments', { ns: 'document' })}
      pop={pop}
      removedCloseButton={false}
      actions={[
        <MainButton
          loading={mutation.isLoading}
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
          key={'add/update-btn'}
          data-cy="AddDocument"
        >
          {t('add', { ns: 'common' })}
        </MainButton>,
      ]}
    >
      <AppForm form={form} onSubmit={onSubmit}>
        {createDocumentsParams.mainComponentId && (
          <FormTextField
            name="mainComponentId"
            type="hidden"
            sx={{ display: 'none' }}
            value={createDocumentsParams.mainComponentId}
          />
        )}
        {createDocumentsParams.mooringId && (
          <FormTextField
            name="mooringId"
            type="hidden"
            sx={{ display: 'none' }}
            value={createDocumentsParams.mooringId}
          />
        )}
        {createDocumentsParams.positionId && (
          <FormTextField
            name="positionId"
            type="hidden"
            sx={{ display: 'none' }}
            value={createDocumentsParams.positionId}
          />
        )}
        {createDocumentsParams.componentId && (
          <FormTextField
            name="componentId"
            type="hidden"
            sx={{ display: 'none' }}
            value={createDocumentsParams.componentId}
          />
        )}
        {createDocumentsParams.deviationId && (
          <FormTextField
            name="deviationId"
            type="hidden"
            sx={{ display: 'none' }}
            value={createDocumentsParams.deviationId}
          />
        )}
        {createDocumentsParams.deviationFollowUpId && (
          <FormTextField
            name="deviationFollowUpId"
            type="hidden"
            sx={{ display: 'none' }}
            value={createDocumentsParams.deviationFollowUpId}
          />
        )}
        {createDocumentsParams.localityFrequencyId && (
          <FormTextField
            name="localityFrequencyId"
            type="hidden"
            sx={{ display: 'none' }}
            value={createDocumentsParams.localityFrequencyId}
          />
        )}
        <FormTextField name="title" label={t('title', { ns: 'common' })} fullWidth />
        <Grid container>
          <Grid item xs={12} sm={4}>
            <FormSelectField
              name="documentCategoryId"
              data={documentTypes.data?.items as IDocumentCategory[]}
              label={t('document-type', { ns: 'document' })}
              getOptionLabel={option => option.categoryName}
              renderOption={(props: any, option) => (
                <Box {...props} key={option.id}>
                  {option.categoryName}
                </Box>
              )}
              isOptionEqualToValue={(o, v) => o.id === v.id}
              onChange={onChangeDocumentCategory}
            />
          </Grid>
          {((createDocumentsParams.mainComponentId !== undefined &&
            mainComponent?.status === MainComponentStatus.Active) ||
            createDocumentsParams.mainComponentId === undefined) && (
            <Grid item xs={12} sm={4}>
              <FormSelectField
                name="mooringId"
                data={moorings as MooringDropdownDto[]}
                label={t('mooring', { ns: 'common' })}
                getOptionLabel={option => getMooringName(option.name)}
                renderOption={(props: any, option) => (
                  <Box {...props} key={option.id}>
                    {getMooringName(option.name)}
                  </Box>
                )}
                disabled={Object.keys(createDocumentsParams).length !== 0}
                isOptionEqualToValue={(o, v) => o.id === v.id}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <BasicDatePicker
              label={t('dueDate', { ns: 'common' })}
              name="dueDate"
              shouldDisableDate={date => date < setToMidnight(new Date())}
            />
          </Grid>
        </Grid>
        <FormTextField name="notes" label={t('document-notes', { ns: 'document' })} fullWidth multiline rows={4} />
        <FormTextField name="version" label={t('version', { ns: 'global-documents' })} fullWidth />
        <FormFileCoreUpload
          FileIcon={FileIcon}
          label={t('fileName', { ns: 'common' })}
          name="fileId"
          mapFileKey="id"
          height="175px"
          isDeletable={false}
        />
        <Flex.Row columnGap={1} flexWrap={'wrap'}>
          {isFacilityCertificate && (
            <FormCheckbox
              name="connectAsLocalityCertificate"
              styledLabelComponent={<StyledTypography>{t('connectAsLocalityCertificate')}</StyledTypography>}
              defaultValue={false}
              sx={{ color: '#00000061' }}
            />
          )}
          <FormCheckbox
            name="connectMooringAnalysis"
            styledLabelComponent={<StyledTypography>{t('connected-mooring-analysis')}</StyledTypography>}
            defaultValue={false}
            sx={{ color: '#00000061' }}
          />
          <FormCheckbox
            name="localityReport"
            styledLabelComponent={<StyledTypography>{t('locality-report')}</StyledTypography>}
            defaultValue={false}
            sx={{ color: '#00000061' }}
          />
          <FormCheckbox
            name="userManual"
            styledLabelComponent={<StyledTypography>{t('user-manual')}</StyledTypography>}
            defaultValue={false}
            sx={{ color: '#00000061' }}
          />
        </Flex.Row>
      </AppForm>
    </GenericDialogLayout>
  )
}

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.primaryDark[theme.palette.mode],
  fontSize: '16px',
}))
