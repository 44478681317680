import { string, z } from 'zod'
import { AppForm, AppFormProps, FormSubmitButton } from '../../components/FormCore'
import { useZodForm } from '../../hooks/zod-form'
import { Autocomplete, MenuItem } from '@mui/material'
import { ILocality } from '../../generated-types'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useGetLocalities } from '../../api/localities'
import { APIResultCount } from '../../constant'
import { MainComponentCertificationAdd } from '../customer/mainComponents/MainComponentCertificationAdd'
import { useGetCustomerSuppliers } from '../../api/supplier'
import { AppTextField } from 'src/components/AppTextField'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { SelectPlacement } from './SelectPlacement'
import { IMainComponentType } from 'src/generated-types/main-component-type'

export interface SendToLocalityDialogFormProps extends AppFormProps {
  customerId: EntityId
  isCertificateAddFormVisible: boolean
  isPlacementVisible: boolean
  mainComponentTypeDetails?: {
    mainComponentType: IMainComponentType | undefined
    isCertificateDetailsRequired: boolean
  }
  setMainComponentTypeDetails?: React.Dispatch<
    React.SetStateAction<{
      mainComponentType: IMainComponentType | undefined
      isCertificateDetailsRequired: boolean
    }>
  >
}

export const SendToLocalityDialogForm: React.FC<SendToLocalityDialogFormProps> = ({
  onSubmit,
  onCancel,
  customerId,
  isCertificateAddFormVisible,
  isPlacementVisible,
  mainComponentTypeDetails,
  setMainComponentTypeDetails,
}) => {
  const { t } = useTranslation(['service-station', 'common'])

  const MainComponentSchema = z.object({
    selectedLocalityId: z.number(),
    installationDate: z.date().or(z.string()).optional(),
    selectedPositionId: z.number().optional(),
    selectedMooringId: z.number().optional(),
  })

  const MainComponentSchemaWithCertificate = z
    .object({
      certificateNumber: z
        .string()
        .nonempty()
        .regex(/^(?! *$).+/, t('certificateNumberIsInvalid', { ns: 'certificate' })),
      certificateDate: z.date().or(string()),
      validUntil: z.date().or(z.string()),
      certificateDocumentType: z.number(),
      certificateFileId: z.union([z.string(), z.number()]),
      certificateSupplierId: z
        .object({
          id: z.number(),
        })
        .transform(value => value.id),
    })
    .merge(MainComponentSchema)

  const { data } = useGetLocalities(customerId, { page: 0, pageSize: APIResultCount.Max })
  const [selectedLocalityId, setSelectedLocalityId] = useState<EntityId | undefined>()
  const { data: suppliers } = useGetCustomerSuppliers(customerId, 0, APIResultCount.Max)

  const form = useZodForm(
    mainComponentTypeDetails?.isCertificateDetailsRequired ? MainComponentSchemaWithCertificate : MainComponentSchema,
  )

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={false}>
      <GenericDialogLayout
        title={t('sendToLocality', { ns: 'main-component' })}
        pop={onCancel}
        actions={[<FormSubmitButton key="send-to-locality" createText={t('Send', { ns: 'common' })} />]}
        removedCloseButton={false}
      >
        <Autocomplete
          onChange={(_, value) => {
            form.setValue('selectedLocalityId', (value as ILocality).id)
            setSelectedLocalityId((value as ILocality).id as EntityId)
          }}
          options={
            (data?.items?.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })) ??
              []) as ILocality[]
          }
          autoHighlight
          getOptionLabel={o => o.name}
          renderOption={(props, o) => (
            <MenuItem key={o.id} value={o.id} {...props}>
              {o.name}
            </MenuItem>
          )}
          renderInput={params => (
            <AppTextField
              {...params}
              label={t('selectALocality', { ns: 'service-station' })}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        {selectedLocalityId && !isPlacementVisible && (
          <SelectPlacement
            customerId={customerId}
            localityId={selectedLocalityId}
            disabled={isPlacementVisible}
            form={form}
          />
        )}

        {isCertificateAddFormVisible && (
          <MainComponentCertificationAdd
            accordingSummary={'certificate'}
            form={form}
            suppliers={suppliers}
            isProductCertificateAdd={false}
            mainComponentTypeDetails={mainComponentTypeDetails}
            setMainComponentTypeDetails={setMainComponentTypeDetails}
          />
        )}
      </GenericDialogLayout>
    </AppForm>
  )
}
