import { useTranslation } from 'react-i18next'
import { LayersControl, WMSTileLayer, LayerGroup } from 'react-leaflet'
import { mapLayers } from '../../constraints'
import { Suspense, lazy, memo } from 'react'
const TileLayerCustom = lazy(() => import('./TileLayerCustom'))

export const MapTileLayers: React.FC<{ maxZoom: number }> = memo(({ maxZoom = 16 }) => {
  const { t } = useTranslation(['map', 'common'])
  return (
    <>
      <LayersControl.Overlay name={`<b><u>${t('map-layers')}</u></b>`}>
        <LayerGroup></LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.BaseLayer name={t('map-norway')} checked>
        <Suspense fallback={<></>}>
          <TileLayerCustom attribution={`Map data &copy; ${t('map-norway')}`} url={mapLayers.map_norway} />
        </Suspense>
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name={t('map-norway-sea')}>
        <Suspense fallback={<></>}>
          <TileLayerCustom attribution={`Map data &copy; ${t('map-norway-sea')}`} url={mapLayers.map_norway_sea} />
        </Suspense>
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name={t('map-norway-depth')}>
        <Suspense fallback={<></>}>
          <TileLayerCustom attribution={`Map data &copy; ${t('map-norway-depth')}`} url={mapLayers.map_norway_depth} />
        </Suspense>
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name={t('norway-picture')}>
        <WMSTileLayer
          attribution={`Map data &copy; ${t('norway-picture')}`}
          url={mapLayers.map_norway_image}
          layers="ortofoto"
          noWrap={true}
          maxZoom={maxZoom}
          minZoom={4}
        />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name={t('google-satellite-map')}>
        <Suspense fallback={<></>}>
          <TileLayerCustom
            attribution={`Map data &copy; ${t('google-satellite-map')}`}
            url={mapLayers.map_google_satellite}
            maxZoom={maxZoom}
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
        </Suspense>
      </LayersControl.BaseLayer>
    </>
  )
})
