import { LatLngExpression } from 'leaflet'
import { Circle, Tooltip } from 'react-leaflet'
import { IPosition } from '../../../../../generated-types'
import { deviationPositions, totalDeviationMultiplier, opacity, mapOptions, grayedOpacity } from '../constraints'
import { getPositionName } from '../utils'
import { memo } from 'react'

interface ICorner {
  position: IPosition
  navigateToPosition: (positionId: number) => void
  size?: number
}

export const Corner: React.FC<ICorner> = memo(({ position, navigateToPosition, size }) => {
  size = size || 4
  const generateCorners = true
  if (position.isHidden || !generateCorners) return <></>

  if (position.deviationsCount != 0) {
    deviationPositions.push([
      position.latitude,
      position.longitude,
      totalDeviationMultiplier * position.deviationsCount,
    ])
  }

  const latlngs = [position.latitude, position.longitude]
  const pathOptions = {
    radius: size,
    fillColor:
      position.deviationsCount > 0
        ? opacity !== 1.0
          ? mapOptions.cornerColor
          : mapOptions.deviationColor
        : mapOptions.cornerColor,
    fillOpacity: position.isTransparent ? grayedOpacity : opacity,
    stroke: false,
    pane: position.isWorkAssignmentPosition ? 'cornerUpdated' : 'corner',
  }

  const tooltip = `${mapOptions.lang.corner} ${getPositionName(position)}`

  return (
    <Circle
      key={position.id}
      center={latlngs as LatLngExpression}
      pathOptions={pathOptions}
      radius={pathOptions.radius}
      pane={pathOptions.pane}
      eventHandlers={{
        click: e => {
          navigateToPosition(position.id)
        },
      }}
    >
      <Tooltip>
        <strong>{tooltip}</strong>
      </Tooltip>
    </Circle>
  )
})
