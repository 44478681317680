import { GridActionsCellItem, GridColumns, GridNativeColTypes, useGridApiRef } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import { customerEndpoints } from '../../../api/customer'
import { invalidateCustomerCache, useDeleteCustomer } from '../../../api/customer-admin'
import UpdateCustomerDialog from '../../../dialogs/customer/UpdateCustomerDialog'
import { showConfirmDialog } from '../../../show-dialog'
import GenericTable from '../../../components/table/GenericTable'
import { AppRoutesNavigation } from '../../../app-routes'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { useNavigation } from 'src/context/NavigationContext'

export const CustomersAdminTable = () => {
  const apiRef = useGridApiRef()
  const navigate = useNavigation()
  const { t: translate } = useTranslation(['admin-customer', 'common'])
  const { showDialog } = useDialogCtx()
  const { mutate, isLoading: isDeleting } = useDeleteCustomer()

  const onDeleteSupplier = (id: EntityId) => {
    showConfirmDialog(
      translate('deleteCustomer', { ns: 'admin-customer' }),
      translate('areYouSureDeleteCustomer', { ns: 'admin-customer' }),
    ).then(e => {
      if (e) {
        mutate(id)
        apiRef.current.updateRows([{ id, _action: 'delete' }])
      }
    })
  }

  const onUpdate = async id => {
    const added = await showDialog(
      UpdateCustomerDialog,
      {
        componentProps: { id: id as EntityId },
        title: translate('updateCustomer', { ns: 'admin-customer' }),
        opts: { maxWidth: 'md', fullWidth: true },
      },
      undefined,
      false,
    )
    if (!added) return

    invalidateCustomerCache.getCustomers()
    invalidateCustomerCache.getCustomer(id)
  }

  const STATIC_COLUMNS: GridColumns = [
    { field: 'name', headerName: translate('name', { ns: 'common' }), flex: 1 },
    { field: 'organizationNr', headerName: 'Organization number', flex: 1 },
    { field: 'address', headerName: translate('address', { ns: 'common' }), flex: 1 },
    { field: 'city', headerName: translate('city', { ns: 'common' }), flex: 1 },
    { field: 'country', headerName: translate('country', { ns: 'common' }), flex: 1 },
  ]

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(id as number)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDeleteSupplier(id as EntityId)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [isDeleting],
  )

  return (
    <GenericTable
      id="customers-table"
      enablePagination
      enableServerPagination
      pageSize={10}
      pageOptions={[10, 25, 50]}
      queryUrl={customerEndpoints.getAdminCustomers()}
      onRowClick={({ id, row }) => {
        navigate.navigateTo(AppRoutesNavigation.Admin.customerUsers(id as EntityId), {
          state: { customerName: row.name, isGlobalServiceProvider: row.isGlobalServiceStationProvider },
        })
      }}
      columns={columns}
      enableToolbar={true}
      getRowHeight={() => 'auto'}
    />
  )
}
