import L, { LatLngExpression } from 'leaflet'
import { LayerGroup, Circle, Tooltip, Polyline, useMap, useMapEvent, Popup } from 'react-leaflet'
import { IMainComponentType } from '../../../../../enums'
import { IMooringType, IPosition } from '../../../../../generated-types'
import { grayedOpacity, letterArray, mapOptions, minZoom, opacity } from '../constraints'
import { midpoint, triangleCentroid, getDistance, getPositionName } from '../utils'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { LocalityCageStatusCard } from '../../../../../components/locality-dashboard/CageStatusMapCard'
import { styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ICage {
  position: IPosition
  navigateToPosition: (positionId: number) => void
  steelFrame?: boolean
  showCageStatusPopOver?: boolean
  mooringType?: IMooringType
}

export const StyledPopup = styled(Popup)({
  width: '275px',
  '& .leaflet-popup-content': { margin: '0px' },
  '& .leaflet-popup-content p': { margin: '0px' },
  '& .leaflet-popup-content-wrapper': { padding: '0px', boxShadow: '0px 3px 6px #00000040' },
})

export const Cage: React.FC<ICage> = memo(
  ({ position, navigateToPosition, steelFrame = false, showCageStatusPopOver, mooringType }) => {
    const map = useMap()
    const [zoomLevel, setZoomLevel] = useState(Math.floor(map.getZoom()))
    const cagePopupRef = React.useRef(null)
    const { t: translate } = useTranslation(['common'])


    useEffect(() => {
      setZoomLevel(Math.floor(map.getZoom()))
    }, [map.getZoom()])

    const handleZoomEnd = event => {
      const newZoomLevel = event.target.getZoom()
      setZoomLevel(Math.floor(newZoomLevel))
    }

    const handleClose = useCallback(() => { 
      if(cagePopupRef.current) {
        (cagePopupRef.current as any).closePopup()
      }
    }, [cagePopupRef])

    const handleOpen = useCallback(() => { 
      if(cagePopupRef.current) {
        (cagePopupRef.current as any).openPopup()
      }
    }, [cagePopupRef])

    const netResources = useMemo(() => {
      return position.mainComponents?.filter(x => x.type.componentType === IMainComponentType.Net)
    }, [position.mainComponents])

    const ringResources = useMemo(() => {
      return position.mainComponents?.filter(x => x.type.componentType === IMainComponentType.Ring)
    }, [position.mainComponents])

    const netFilColor = useMemo(() => {
      return netResources && netResources.length > 0
        ? position.deviationsCount > 0
          ? mapOptions.deviationColor
          : mapOptions.netColor
        : opacity !== 1.0
        ? mapOptions.netColor
        : mapOptions.netColorInactive
    }, [netResources, mapOptions, position.deviationsCount])

    const ringFilColor = useMemo(() => {
      return ringResources && ringResources.length > 0
        ? position.deviationsCount > 0
          ? mapOptions.deviationColor
          : mapOptions.ringColor
        : opacity !== 1.0
        ? mapOptions.ringColor
        : mapOptions.ringColorInactive
    }, [ringResources, mapOptions, position.deviationsCount])

    const fontSize = useMemo(() => {
      return zoomLevel - minZoom <= 0 ? 8 : (zoomLevel - minZoom) * 8
    }, [zoomLevel])

    useMapEvent('zoomend', handleZoomEnd)
    if (position.isHidden || !position.connectedPositions[0]) return <></> //hide if visible set to false.

    let middle

    let netLatLongs: LatLngExpression = [
      position.connectedPositions[0].latitude,
      position.connectedPositions[0].longitude,
    ]
    let netPathOptions: L.PathOptions = {}
    netPathOptions.weight = zoomLevel - minZoom <= 0 ? 1 : zoomLevel - minZoom
    let netToolTip = ''
    let netText = ''
    let netRadius = 0

    let ringLatLongs: LatLngExpression = [
      position.connectedPositions[0].latitude,
      position.connectedPositions[0].longitude,
    ]

    let ringPathOptions: L.PathOptions = {}
    let ringToolTip = ''
    let ringRadius = 0

    if (!steelFrame && position.connectedPositions[1]) {
      middle = midpoint(
        position.connectedPositions[0].latitude,
        position.connectedPositions[0].longitude,
        position.connectedPositions[1].latitude,
        position.connectedPositions[1].longitude,
        0.5,
      )

      if (
        position.connectedPositions[2] &&
        !isNaN(position.connectedPositions[2].latitude) &&
        isNaN(position.connectedPositions[2].latitude)
      ) {
        const triangleMidpoint = triangleCentroid([
          [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
          [position.connectedPositions[1].latitude, position.connectedPositions[1].longitude],
          [position.connectedPositions[2].latitude, position.connectedPositions[2].longitude],
        ])

        middle = triangleMidpoint

        const distance = getDistance(
          triangleMidpoint[0],
          triangleMidpoint[1],
          position.connectedPositions[0].latitude,
          position.connectedPositions[0].longitude,
        )

        netRadius = (distance / 2) * 2

        netLatLongs = [triangleMidpoint[0], triangleMidpoint[1]]
        netToolTip = mapOptions.lang.net + ' ' + getPositionName(position)
        netText = position.name
        netPathOptions = {
          stroke: true,
          color: 'gray',
          fillColor: netFilColor,
          fillOpacity: position.isTransparent ? grayedOpacity : opacity,
          opacity: 100,
          pane: position.isWorkAssignmentPosition ? 'netUpdated' : 'net',
        }

        return (
          <LayerGroup key={position.id}>
            <Circle
              className="net"
              eventHandlers={{
                click: () => {
                  navigateToPosition(position.id)
                },
              }}
              center={netLatLongs as LatLngExpression}
              pane={netPathOptions.pane}
              pathOptions={{ fillOpacity: 0, opacity: 0 }}
              radius={netRadius}
            >
              <Tooltip permanent direction="center" offset={[0, 0]} className="permanent-tooltip">
                <strong>{netText}</strong>
              </Tooltip>
            </Circle>
            <Circle
              className="shadow-net"
              center={netLatLongs as LatLngExpression}
              pane={netPathOptions.pane}
              pathOptions={netPathOptions}
              radius={netRadius}
              eventHandlers={{
                click: () => {
                  navigateToPosition(position.id)
                },
              }}
            >
              <Tooltip>
                <strong>Net {netToolTip}</strong>
              </Tooltip>
            </Circle>
          </LayerGroup>
        )
      }
      const distance = getDistance(
        position.connectedPositions[0].latitude,
        position.connectedPositions[0].longitude,
        position.connectedPositions[1].latitude,
        position.connectedPositions[1].longitude,
      )

      ringRadius = ((distance / 100) * 40) / 2
      ringLatLongs = L.latLng(middle[0], middle[1])
      ringToolTip = mapOptions.lang.ring + ' ' + getPositionName(position)
      ringPathOptions = {
        stroke: false,
        fillColor: ringFilColor,
        fillOpacity: position.isTransparent ? grayedOpacity : opacity,
        opacity: 0,
        pane: position.isWorkAssignmentPosition ? 'ringUpdated' : 'ring',
      }

      netLatLongs = L.latLng(middle[0], middle[1])
      netToolTip = `${getPositionName(position)}`
      netText = position.name
      netRadius = ringRadius - 2
      netPathOptions = {
        stroke: false,
        fillColor: netFilColor,
        fillOpacity: position.isTransparent ? grayedOpacity : opacity,
        opacity: 0,
        pane: position.isWorkAssignmentPosition ? 'netUpdated' : 'net',
      }

      return (
        <LayerGroup key={position.id}>
          <Circle
            className="cage"
            center={netLatLongs as LatLngExpression}
            pane={netPathOptions.pane}
            pathOptions={netPathOptions}
            radius={netRadius}
            eventHandlers={{
              click: () => {
                navigateToPosition(position.id)
              },
            }}
          ></Circle>
          <Circle
            className="ring"
            center={ringLatLongs as LatLngExpression}
            pane={ringPathOptions.pane}
            pathOptions={ringPathOptions}
            radius={ringRadius}
            eventHandlers={{
              click: () => {
                navigateToPosition(position.id)
              },
            }}
          >
            <Tooltip>
              <strong>{ringToolTip}</strong>
            </Tooltip>
          </Circle>
          <Circle
            className="net"
            center={netLatLongs as LatLngExpression}
            pane={netPathOptions.pane}
            pathOptions={{ fillOpacity: 0, opacity: 0 }}
            radius={netRadius}
          >
            <Tooltip permanent direction="center" offset={[0, 0]} className="permanent-tooltip">
              <strong style={{ fontSize: fontSize }}>{getPositionName(position)}</strong>
            </Tooltip>
          </Circle>
          <Circle
            className="shadow-net"
            center={netLatLongs as LatLngExpression}
            pane={netPathOptions.pane}
            pathOptions={netPathOptions}
            radius={netRadius}
            ref={cagePopupRef}
            eventHandlers={{
              click: _ => navigateToPosition(position.id),
              contextmenu: event => event.target.openPopup(),
              mouseout: event => event.target.closePopup(),
            }}
          >
            {showCageStatusPopOver ? (
              <StyledPopup closeButton={false} autoPan={false} autoClose={true}>
                <LocalityCageStatusCard
                  referenceName={`${getPositionName(position)} - ${translate(IMooringType[mooringType as number], {
                    ns: 'mooring',
                  })}`}
                  mainComponentStatus={position.mainComponentStatus}
                  handleClose={handleClose}
                  handleOpen={handleOpen}
                />
              </StyledPopup>
            ) : (
              <Tooltip>
                <strong>Net {netToolTip}</strong>
              </Tooltip>
            )}
          </Circle>
        </LayerGroup>
      )
    }

    if (!position.connectedPositions[2]) return <></>

    middle = midpoint(
      position.connectedPositions[0].latitude,
      position.connectedPositions[0].longitude,
      position.connectedPositions[2].latitude,
      position.connectedPositions[2].longitude,
      0.5,
    )
    const latLongs = [
      [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
      [position.connectedPositions[1].latitude, position.connectedPositions[1].longitude],
      [position.connectedPositions[2].latitude, position.connectedPositions[2].longitude],
      [position.connectedPositions[3].latitude, position.connectedPositions[3].longitude],
      [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
    ]

    netToolTip = mapOptions.lang.cage + ' ' + getPositionName(position)
    netPathOptions = {
      // simplepoly: 1,
      fillOpacity: position.isTransparent ? grayedOpacity : opacity,
      fillColor: netFilColor,
      weight: 4,
      color: mapOptions.frameLineColor,
      pane: position.isWorkAssignmentPosition ? 'netUpdated' : 'net',
    }
    return (
      <LayerGroup key={position.id}>
        <Polyline
          key={position.id}
          positions={latLongs as LatLngExpression[]}
          pathOptions={netPathOptions}
          pane={netPathOptions.pane}
          eventHandlers={{
            click: () => {
              navigateToPosition(position.id)
            },
          }}
        >
          <Tooltip sticky>
            <strong>{getPositionName(position)}</strong>
          </Tooltip>
        </Polyline>
        <Circle
          className="net"
          center={[middle[0], middle[1]] as LatLngExpression}
          pane={netPathOptions.pane}
          pathOptions={{
            color: 'none',
            fillColor: 'none',
            fillOpacity: 0,
            opacity: 0,
            pane: position.isWorkAssignmentPosition ? 'cageUpdated' : 'cage',
            fill: true,
          }}
          radius={15 * 0.6}
        >
          <Tooltip permanent direction="center" offset={[0, 0]} className="permanent-tooltip">
            <strong style={{ fontSize: fontSize }}>{position?.reference ?? letterArray[parseInt(netText) - 901]}</strong>
          </Tooltip>
        </Circle>
      </LayerGroup>
    )
  },
)
