import { Box, Stack, useTheme } from '@mui/material'
import CageIcon from '../../assets/svg/cage.svg'
import { FC, useMemo } from 'react'
import { CertificationValidationPeriod } from '../../enums'
import GenericCard from '../GenericCard'
import { StatusIndicator } from '../common/StatusIndicator'
import { MainComponentProgress } from 'src/screens/localities/locality-dashboard/cage-status/MainComponentProgress'
import { IMainComponentStatus } from 'src/generated-types/main-component-status'
import { CageStatusActionContainer } from 'src/screens/localities/locality-dashboard/cage-status/CageStatusActionContainer'
import { useParams } from 'react-router-dom'
import { MainComponentsContainer } from 'src/screens/localities/locality-dashboard/cage-status/MainComponentsContainer'

interface ILocalityCageStatusCard {
  mainComponentStatus: IMainComponentStatus
  referenceName: string | number
  handleClose: () => void
  handleOpen: () => void
}

export const LocalityCageStatusCard: FC<ILocalityCageStatusCard> = ({ mainComponentStatus: cage, referenceName,handleClose,handleOpen }) => {
  const theme = useTheme()
  const { localityId, customerId } = useParams()

  const status: CertificationValidationPeriod = useMemo(() => {
    if (cage?.certificateValidationTime != undefined && cage?.certificateValidationTime >= 0) {
      if (cage?.certificateValidationTime < CertificationValidationPeriod.LOW) {
        return CertificationValidationPeriod.LOW
      } else if (cage?.certificateValidationTime < CertificationValidationPeriod.MEDIUM) {
        return CertificationValidationPeriod.MEDIUM
      } else if (cage?.certificateValidationTime <= CertificationValidationPeriod.HIGH) {
        return CertificationValidationPeriod.HIGH
      }
    }
    return CertificationValidationPeriod.NA
  }, [cage?.certificateValidationTime])

  return (
    <GenericCard
      headerIcon={
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'24px'}
          width={'24px'}
          bgcolor={theme.palette.secondaryLight[theme.palette.mode]}
        >
          <img src={CageIcon} height={'14px'} width={'14px'} />
        </Box>
      }
      actions={
        <Box paddingTop={'4px'}>
          <CageStatusActionContainer
            cage={cage}
            status={status}
            customerId={customerId as EntityId}
            localityId={localityId as EntityId}
          />
        </Box>
      }
      isSelected={false}
      minWidth={'275px'}
      title={referenceName as string}
      titleTypographyProps={{
        variant: 'body2',
        color: theme.palette.primaryMain[theme.palette.mode],
        fontWeight: 600,
      }}
      onMouseLeave={() => {
        handleClose()
      }}
      onMouseOverCapture={() => {
        handleOpen()
      }}
      backgroundColor={theme.palette.inputSearch[theme.palette.mode]}
    >
      <>
        <Box display={'flex'} flexDirection={'column'} paddingX={'5px'} rowGap={'8px'}>
          <MainComponentsContainer
            cage={cage}
            textColor={theme.palette.primaryMain[theme.palette.mode]}
            removePopOver
          />
          <Stack direction={'row'} columnGap={'8px'}>
            <StatusIndicator
              statusText={'HS'}
              height={'25px'}
              width={'25%'}
              bgcolor={theme.palette.errorMain[theme.palette.mode]}
              borderRadius={'3px'}
              fontColor={theme.palette.white[theme.palette.mode]}
              variant="body2"
              fontWeight={'500'}
              p={1}
            />
            <StatusIndicator
              statusText={'VC5M'}
              height={'25px'}
              width={'25%'}
              bgcolor={theme.palette.errorMain[theme.palette.mode]}
              borderRadius={'3px'}
              fontColor={theme.palette.white[theme.palette.mode]}
              variant="body2"
              fontWeight={'500'}
              p={1}
            />
            <StatusIndicator
              statusText={'VC15M'}
              height={'25px'}
              width={'25%'}
              bgcolor={theme.palette.primaryMain[theme.palette.mode]}
              borderRadius={'3px'}
              fontColor={theme.palette.white[theme.palette.mode]}
              variant="body2"
              fontWeight={'500'}
              p={1}
            />
          </Stack>
          <MainComponentProgress
            paddingTop={'3px'}
            cage={cage}
            status={status}
            textColor={theme.palette.primaryMain[theme.palette.mode]}
          />
        </Box>
      </>
    </GenericCard>
  )
}
