import moment from 'moment'
import { Months, ServiceStationType } from '../enums'
import { GlobalDocumentStatus } from '../generated-types/global-documents'
import { letterArray } from '../screens/localities/components/map/constraints'
import { t } from 'i18next'

export const getValueFromStringKey = (value, key) => {
  const keys = key.split('.')
  let valueReturn = value
  keys.forEach(element => {
    valueReturn = valueReturn[element]
  })
  return valueReturn ?? '-'
}

export const getServiceStationType = type => {
  switch (type) {
    case ServiceStationType.ServiceStation:
      return 'serviceStations'
    case ServiceStationType.Storage:
      return 'storages'
    case ServiceStationType.Service:
      return 'services'
  }
}

export function generateUUID() {
  let date = new Date().getTime() //Timestamp
  let currentDate = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (character) {
    let randomValue = Math.random() * 16 //random number between 0 and 16
    if (date > 0) {
      //Use timestamp until depleted
      randomValue = (date + randomValue) % 16 | 0
      date = Math.floor(date / 16)
    } else {
      //Use microseconds since page-load if supported
      randomValue = (currentDate + randomValue) % 16 | 0
      currentDate = Math.floor(currentDate / 16)
    }
    return (character === 'x' ? randomValue : (randomValue & 0x3) | 0x8).toString(16)
  })
}

export const getCageName = (cageNumber: string) => {
  return `M${parseInt(cageNumber) - 900}`
}

export const getCageLetter = (cageNumber: string) => {
  return letterArray[parseInt(cageNumber) - 901] ?? 0
}

export const getLocalDateTime = (date: Date | undefined) => {
  if (!date) return undefined

  const localDateTime = getLocalFormattedDateTimeFromUTCString(date?.toString(), 'YYYY-MM-DDTHH:mmZ')

  return new Date(localDateTime)
}

export const getLocalFormattedDateTimeFromUTCString = (date: string, format = 'YYYY-MM-DD') => {
  return moment.utc(date).local().format(format)
}

export const formatNumber = (num, padStartCount = 0) => {
  let returnNumber = num
  if (num >= 1e6) {
    returnNumber = (num / 1e6).toFixed(1) + 'M'
  } else if (num >= 1e3) {
    returnNumber = (num / 1e3).toFixed(1) + 'K'
  }
  return returnNumber.toString().padStart(padStartCount, '0')
}

export const addCurrentTimeToDate = date => {
  if (!date) return undefined

  const today = new Date()
  const dateTime = new Date(date)
  dateTime.setHours(today.getHours(), today.getMinutes(), today.getSeconds())

  return getUTCDate(dateTime)
}

export const getUTCDate = (date: Date) => {
  if (!date) return undefined

  // Get the year, month, and day from the local date
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  // Create a new date object in the local timezone
  const localDate = new Date(year, month, day)

  // Get the UTC date corresponding to the local date
  const utcDate = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate()))

  return utcDate
}

export const convertFirstLetterToUpperCase = (word: string) => {
  return word[0].toUpperCase() + word.substring(1)
}

export const getGlobalDocumentStatus = (status: GlobalDocumentStatus) => {
  switch (status) {
    case GlobalDocumentStatus.Approved:
      return 'approved'
    case GlobalDocumentStatus.Withdrawn:
      return 'withdrawn'
    case GlobalDocumentStatus.SaveDraft:
      return 'draft'
    default:
      return 'new'
  }
}

export const getYearAndMonth = (year?: number, month?: number) => {
  if (year != undefined && month != undefined) {
    return `${year}-${Months[month]}`
  }
  if (year) {
    return `${year}`
  }
  return '-'
}

export const dateWithoutTimeConvert = (dateString: string | Date) => {
  const date = new Date(dateString)
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  return date
}

export const sumTwoNumbers = (num1, num2) => {
  return (num1 ?? 0) + (num2 ?? 0)
}

// Check if the string is only-symbols-string
export const checkAlphaNumerics = value => {
  const hasAlphanumerics = /[a-zA-Z0-9]/.test(value)
  return hasAlphanumerics
}

export const decodeFrequencyInstructionHTML = instruction => {
  const instructiontextArea = document.createElement('textarea')
  instructiontextArea.innerHTML = instruction
  const doc = new DOMParser().parseFromString(instructiontextArea.value, 'text/html')
  return doc.body.textContent || ''
}

export const getMooringName = mooringname => {
  const nameArray = mooringname.split('|')
  return `${t(nameArray[0].trim(), { ns: 'mooring' })} | ${nameArray[1]}`
}
