import { LatLngExpression } from 'leaflet'
import { MooringLine } from './components/MooringLine'
import { LayerGroup, Polygon } from 'react-leaflet'
import { useParams } from 'react-router'
import { AppRoutesNavigation } from '../../../../app-routes'
import { ILocality, IMooring, IMooringType, IPosition, IPositionType } from '../../../../generated-types'
import { Bridle } from './components/Bridle'
import { Cage } from './components/Cage'
import { Corner } from './components/Corner'
import { FrameLine } from './components/FrameLine'
import { InnerMooringLine } from './components/InnerMooringLine'
import { defaultRadiusSize } from './constraints'
import { useCallback, useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Fleet } from './components/Fleet'
import { CreateMooring } from './layers/CreateMooring'
import { useNavigation } from 'src/context/NavigationContext'
import { ICluster } from 'src/generated-types/cluster'

export const GenericComponentLayer: React.FC<{
  position: IPosition
  isFleet?: boolean
  steelFrame: boolean
  navigate?: NavigateFunction
  showCageStatusPopOver?: boolean
  onPositionSelect?: (positionId: number, positionName: string) => void
  mooringType?: IMooringType
}> = ({ position, isFleet = false, steelFrame, navigate, showCageStatusPopOver, onPositionSelect, mooringType }) => {
  const { customerId, id, localityId, mooringId } = useParams()
  const [localityIdFromQuery, setLocalityIdFromQuery] = useState<string | null>()
  const { navigateTo } = useNavigation()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const localityId = params.get('localityId')
    setLocalityIdFromQuery(localityId)
  }, [])

  const navigateToPosition = useCallback(
    (positionId: number) => {
      if (onPositionSelect) {
        onPositionSelect(positionId, position.name)
      } else if (
        (customerId || id) &&
        (localityId || localityIdFromQuery != null) &&
        (mooringId || position.mooringId) &&
        positionId
      ) {
        navigate &&
          navigateTo(
            AppRoutesNavigation.Localities.position(
              (customerId ?? id) as EntityId,
              (localityId ?? localityIdFromQuery) as EntityId,
              (mooringId ?? position.mooringId) as EntityId,
              positionId,
            ),
          )
      }
    },
    [navigate, customerId, id, localityId, localityIdFromQuery, mooringId, position.mooringId, onPositionSelect],
  )

  const latLongs = [] as LatLngExpression[]

  switch (position.type) {
    case IPositionType.FrameLines:
      return <FrameLine position={position} navigateToPosition={navigateToPosition} />
    case IPositionType.Bridle:
      if (isFleet) {
        return <InnerMooringLine position={position} navigateToPosition={navigateToPosition} />
      }
      return <Bridle position={position} navigateToPosition={navigateToPosition} />
    case IPositionType.MooringLine:
      return <MooringLine position={position} navigateToPosition={navigateToPosition} />
    case IPositionType.Cage:
      return (
        <Cage
          position={position}
          navigateToPosition={navigateToPosition}
          steelFrame={steelFrame}
          showCageStatusPopOver={showCageStatusPopOver}
          mooringType={mooringType}
        />
      )
    case IPositionType.Corner:
      return (
        <Corner position={position} navigateToPosition={navigateToPosition} size={isFleet ? 2 : defaultRadiusSize} />
      )
    case IPositionType.Polygon:
      position.connectedPositions.forEach(function (position) {
        latLongs.push([position.latitude, position.longitude])
      })
      return <Polygon positions={latLongs} pathOptions={{ fillOpacity: 1, color: 'red', pane: 'polygon' }} />
    default:
      return <></>
  }
}

export const CreateLayerGroup: React.FC<{
  positions?: IPosition[]
  moorings?: IMooring[]
  localities?: ILocality[]
  type?: IPositionType
  mapPosition: L.LatLngExpression
  isFleet?: boolean
  steelFrame?: boolean
  isModalView: boolean
  mooringType?: IMooringType
  showCageStatusPopOver?: boolean
  clusters?: ICluster[]
  onPositionSelect?: (positionId: number, positionName: string) => void
}> = ({
  positions,
  type,
  clusters,
  mapPosition,
  isFleet,
  steelFrame,
  isModalView,
  mooringType,
  moorings,
  localities,
  showCageStatusPopOver,
  onPositionSelect,
}) => {
    return (
      <>
        <LayerGroup>
          {positions
            ?.filter(p => type == undefined || p.type === type)
            .map((p, index) => {
              return (
                <ComponentLayer
                  key={index}
                  position={p}
                  isFleet={isFleet}
                  steelFrame={steelFrame as boolean}
                  isModalView={isModalView}
                  showCageStatusPopOver={showCageStatusPopOver}
                  onPositionSelect={onPositionSelect}
                  mooringType={mooringType}
                />
              )
            })}
          {moorings &&
            moorings.map((mooring, index) => {
              return (
                <CreateMooring
                  mooring={mooring as IMooring}
                  mapPosition={mapPosition}
                  key={index}
                  clusters={clusters}
                  isModalView={isModalView}
                  isFleet={mooring.type == IMooringType.Barge}
                  showCageStatusPopOver={showCageStatusPopOver}
                  onPositionSelect={onPositionSelect}
                />
              )
            })}
          {localities &&
            localities
              .filter(x => x.moorings != null)
              .map(x => x.moorings)
              .forEach(x =>
                x.map((mooring, index) => {
                  return (
                    <CreateMooring
                      mooring={mooring as IMooring}
                      mapPosition={mapPosition}
                      key={index}
                      isModalView={isModalView}
                      isFleet={mooring.type == IMooringType.Barge}
                      showCageStatusPopOver={showCageStatusPopOver}
                      onPositionSelect={onPositionSelect}
                      clusters={clusters}
                    />
                  )
                }),
              )}
          {positions && mooringType == IMooringType.Barge && <Fleet positions={positions} />}
        </LayerGroup>
      </>
    )
  }

const ComponentLayerWithNavigation: React.FC<{
  position: IPosition
  isFleet?: boolean
  steelFrame: boolean
  showCageStatusPopOver?: boolean
  onPositionSelect?: (positionId: number, positionName: string) => void
  mooringType?: IMooringType
}> = ({ position, isFleet = false, steelFrame, showCageStatusPopOver, onPositionSelect, mooringType }) => {
  const navigate = useNavigate()
  return (
    <GenericComponentLayer
      position={position}
      isFleet={isFleet}
      steelFrame={steelFrame}
      navigate={navigate}
      showCageStatusPopOver={showCageStatusPopOver}
      onPositionSelect={onPositionSelect}
      mooringType={mooringType}
    />
  )
}

export const ComponentLayer: React.FC<{
  position: IPosition
  isFleet?: boolean
  steelFrame: boolean
  isModalView: boolean
  showCageStatusPopOver?: boolean
  mooringType?: IMooringType
  onPositionSelect?: (positionId: number, positionName: string) => void
}> = ({ position, isFleet = false, steelFrame, isModalView, showCageStatusPopOver, onPositionSelect, mooringType }) => {
  return isModalView ? (
    <GenericComponentLayer
      position={position}
      isFleet={isFleet}
      steelFrame={steelFrame}
      showCageStatusPopOver={showCageStatusPopOver}
      onPositionSelect={onPositionSelect}
      mooringType={mooringType}
    />
  ) : (
    <ComponentLayerWithNavigation
      position={position}
      isFleet={isFleet}
      steelFrame={steelFrame}
      showCageStatusPopOver={showCageStatusPopOver}
      onPositionSelect={onPositionSelect}
      mooringType={mooringType}
    />
  )
}
