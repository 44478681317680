import {
  GridActionsCellItem,
  GridColumns,
  GridNativeColTypes,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridRowParams,
} from '@mui/x-data-grid-pro'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import GenericTable, { IToolbarOptions } from '../../../components/table/GenericTable'
import { getUserDetail } from '../../../utils/getUserDetails'
import { GenericToggle } from '../../../components/GenericToggle'
import { IServiceStation } from '../../../generated-types/service-station'
import { QueryKeyT } from '@lovoldsystem/core.client'
import { getServiceStationType } from '../../../utils/function'

interface IServiceStationsTableProps {
  toolbarOptions?: IToolbarOptions[]
  handleRemove?: (id: EntityId) => void
  handleEdit?: (initialValues: IServiceStation) => void
  getDetailPanelContent?: ((params: GridRowParams<any>) => React.ReactNode) | undefined
  queryUrl: string
  queryParams?: object | undefined
  generateQueryKey?: (page: number, pageSize: number) => QueryKeyT | undefined
}

export const ServiceStationsTable: FC<IServiceStationsTableProps> = ({
  toolbarOptions,
  handleRemove,
  handleEdit,
  getDetailPanelContent,
  queryUrl,
  queryParams,
  generateQueryKey,
}) => {
  const { t } = useTranslation('service-station')

  const renderActions = useCallback(({ row, id }) => {
    const actions: JSX.Element[] = []
    if (handleEdit) {
      actions.push(
        <GridActionsCellItem
          key={id}
          label={t('edit', { ns: 'common' })}
          icon={<MdEdit size={24} />}
          onClick={() => {
            handleEdit(row)
          }}
          color="primary"
          showInMenu
        />,
      )
    }
    if (handleRemove) {
      actions.push(
        <GridActionsCellItem
          key={id}
          label={t('delete', { ns: 'common' })}
          icon={<MdDelete size={24} />}
          onClick={() => {
            handleRemove(row.id)
          }}
          color="primary"
          showInMenu
        />,
      )
    }
    return actions
  }, [])

  const STATIC_COLUMNS: GridColumns = [
    { field: 'name', headerName: t('name', { ns: 'common' }), flex: 1 },
    { field: 'serviceStationNumber', headerName: t('serviceStationNumber', { ns: 'service-station' }), flex: 1 },
    {
      field: 'type',
      headerName: t('type', { ns: 'service-station' }),
      flex: 1,
      valueGetter: ({ row }) => t(getServiceStationType(row.type) ?? '', { ns: 'service-station' }),
    },
    {
      field: 'managedByUser',
      headerName: t('managedByUser', { ns: 'service-station' }),
      flex: 1,
      valueGetter: ({ row }) => (row?.managedByUser ? getUserDetail(row?.managedByUser) : '-'),
    },
  ]

  const columns: GridColumns = useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        flex: 0.2,
        align: 'center',
        renderCell: params => (
          <GenericToggle
            id={params.id}
            value={params.value}
            expandTitle={t('view-service-station', { ns: 'service-station' })}
          />
        ),
      },
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        headerName: '',
        width: 60,
        getActions: renderActions,
      },
    ],
    [t, handleRemove],
  )

  return (
    <GenericTable
      id="service-stations"
      queryUrl={queryUrl}
      queryParams={queryParams}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => 'auto'}
      columns={columns.filter(col => handleEdit || handleRemove || col.field !== 'actions')}
      enableToolbar={true}
      enableServerPagination
      toolbarOptions={toolbarOptions}
      generateQueryKey={generateQueryKey}
      autoHeight={false}
      height={'81vh'}
    />
  )
}
