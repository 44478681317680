import { Stack, useTheme } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { AppRoutesRouter } from '../../app-routes'
import { CustomerServiceStationCtx, ServiceStationContextProvider } from '../../context/ServiceStationContext'
import { useDialogCtx } from '../../hooks/context-hooks'
import { DialogContextType } from '../../enums'
import { lazy, Suspense } from 'react'
import { CenteredSpinner } from 'src/components/Spinner'
import DeviationContainer from 'src/screens/localities/deviations/DeviationContainer'
import { DocumentServiceStationPage } from 'src/pages/locality/DocumentPage'

const Dashboard = lazy(async () => await import('src/screens/serviceStations/Dashboard'))
const SelectedServiceStationMainComponent = lazy(
  async () => await import('src/screens/localities/mainComponents/components/SelectedMainComponentInLocality'),
)

export const ServiceStationRoutes = () => {
  const { dialogsCustom } = useDialogCtx()
  const theme = useTheme()

  return (
    <ServiceStationContextProvider>
      {Object.values(dialogsCustom[DialogContextType.serviceStationContext] ?? {})}
      <Stack minWidth={'100%'} minHeight={'calc(100vh - 200px)'} className="pb-4-core">
        <Suspense fallback={<CenteredSpinner />}>
          <Routes>
            <Route path={AppRoutesRouter.serviceStation.dashboard} element={<Dashboard />} />
            <Route
              path={AppRoutesRouter.serviceStation.deviation}
              element={<DeviationContainer context={CustomerServiceStationCtx} />}
            />
            <Route path={AppRoutesRouter.serviceStation.documents} element={<DocumentServiceStationPage />} />
            <Route
              path={AppRoutesRouter.serviceStation.mainComponent}
              element={<SelectedServiceStationMainComponent />}
            />
            <Route path="*" element={<div></div>} />
          </Routes>
        </Suspense>
      </Stack>
    </ServiceStationContextProvider>
  )
}
