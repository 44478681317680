import { LatLngExpression } from 'leaflet'
import { Polyline, Tooltip, useMap, useMapEvent } from 'react-leaflet'
import { IPosition } from '../../../../../generated-types'
import { opacity, mapOptions, deviationPositions, totalDeviationMultiplier, grayedOpacity, minZoom } from '../constraints'
import { getPositionName, midpoint } from '../utils'
import { memo, useEffect, useState } from 'react'

interface IFrameLine {
  position: IPosition
  navigateToPosition: (positionId: number) => void
}

const getParams = (position: IPosition) => {
  const latlngs = [[position.connectedPositions[0].latitude, position.connectedPositions[0].longitude]]

  if (position.connectedPositions)
    position.connectedPositions.forEach(e => {
      latlngs.push([e.latitude, e.longitude])
    })

  const pathOptions = {
    weight: 4,
    opacity: position.isTransparent ? grayedOpacity : opacity,
    color:
      position.deviationsCount > 0
        ? opacity !== 1.0
          ? mapOptions.frameLineColor
          : mapOptions.deviationColor
        : mapOptions.frameLineColor,
    pane: position.isWorkAssignmentPosition ? 'frameLineUpdated' : 'frameLine',
  }

  if (position.deviationsCount != 0) {
    const mid = midpoint(
      position.connectedPositions[0].latitude,
      position.connectedPositions[0].longitude,
      position.connectedPositions[1].latitude,
      position.connectedPositions[1].longitude,
      0.5,
    )
    deviationPositions.push([mid[0], mid[1], totalDeviationMultiplier * position.deviationsCount])
  }
  return { latlngs, pathOptions }
}

export const FrameLine: React.FC<IFrameLine> = memo(({ position, navigateToPosition }) => {
  const generateFrameLines = true
  const map = useMap()
  const [zoomLevel, setZoomLevel] = useState(Math.round(map.getZoom()))

  useEffect(() => { 
    setZoomLevel(Math.floor(map.getZoom())) 
  }, [map.getZoom()])

  const handleZoomEnd = event => {
    const newZoomLevel = event.target.getZoom()
    setZoomLevel(Math.round(newZoomLevel))
  }
  useMapEvent('zoomend', handleZoomEnd)

  if (position.isHidden || !generateFrameLines || !position.connectedPositions[1]) return <></> //hide if visible set to false.

  const { latlngs, pathOptions } = getParams(position)
  pathOptions.weight = zoomLevel - minZoom <= 0 ? 1 : zoomLevel - minZoom
  const toolTip = mapOptions.lang.frameLine + ' ' + getPositionName(position)

  return (
    <Polyline
      key={position.id}
      positions={latlngs as LatLngExpression[]}
      pathOptions={pathOptions}
      pane={pathOptions.pane}
      eventHandlers={{
        click: () => {
          navigateToPosition(position.id)
        },
      }}
    >
      <Tooltip sticky>
        <strong>{toolTip}</strong>
      </Tooltip>
    </Polyline>
  )
})
