import L from 'leaflet'
import { useLeafletContext } from '@react-leaflet/core'
import { useEffect } from 'react'

export const BarentsWatch: React.FC<{
  url: string
  style?: L.PathOptions | L.StyleFunction<any> | undefined
  tooltipTitle?: string
}> = ({ url, style, tooltipTitle }) => {
  const context = useLeafletContext()
  const container = context.layerContainer || context.map
  useEffect(() => {
    let geojsonLayer
    fetch(url).then(response => {
      if (response.ok) {
        response.json().then(data => {
          geojsonLayer = L.geoJSON(data, {
            style: style ?? {
              color: '#ff7800',
              weight: 2,
              opacity: 0.65,
            },
          })
          if (geojsonLayer) {
            geojsonLayer.bindTooltip(tooltipTitle ?? 'BarentsWatch', {
              permanent: false,
              sticky: true,
              offset: [10, 0],
              direction: 'right',
              opacity: 0.7,
            })
            container.addLayer(geojsonLayer)
          }
        })
      }
    })

    return () => {
      if (geojsonLayer) {
        container.removeLayer(geojsonLayer)
      }
    }
  }, [url])
  return <></>
}
