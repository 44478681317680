import L from 'leaflet'
import { Marker, Tooltip } from 'react-leaflet'

const VesselLayers = () => {
  return (
    <>
      {mockVesselData.map(vessel => {
        return (
          <Marker
            key={vessel.mmsi}
            position={[vessel.shipData.latitude, vessel.shipData.longitude]}
            icon={L.divIcon({
              html: `<img src='/icons/vessel.svg' style="transform:rotate(${vessel?.shipData?.heading - 30}deg);" />`,
              iconSize: [10, 10],
              className: 'map-icon',
            })}
          >
            <Tooltip direction={'top'} offset={[0, -10]}>
              {vessel?.shipName}
            </Tooltip>
          </Marker>
        )
      })}
    </>
  )
}

export default VesselLayers

const mockVesselData = [
  {
    mmsi: 215211000,
    shipName: 'KEY FIGHTER',
    shipData: {
      latitude: 66.732639,
      longitude: 12.33345,
      heading: 0,
    },
  },
  {
    mmsi: 1,
    shipName: 'ARCTIC LADY',
    shipData: {
      latitude: 67.212259,
      longitude: 13.43345,
      heading: 75,
    },
  },
  {
    mmsi: 1,
    shipName: 'ARCTIC LADY',
    shipData: {
      latitude: 67.012259,
      longitude: 13.03345,
      heading: 145,
    },
  },
  {
    mmsi: 1,
    shipName: 'ARCTIC LADY',
    shipData: {
      latitude: 67.3221,
      longitude: 10.5029,
      heading: 145,
    },
  },
  {
    mmsi: 1,
    shipName: 'Key Fighter',
    shipData: {
      latitude: 67.0005,
      longitude: 10.1733,
      heading: 67,
    },
  },
]
