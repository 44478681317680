import { Stack, useTheme } from '@mui/material'
import { useQuery } from 'react-query'
import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { AppRoutesRouter } from '../../app-routes'
import { CustomerCtx, CustomerCtxProvider } from '../../context/CustomerContext'
import { LoadingGuard } from '../../guards/LoadingGuard'
import { getCustomer } from '../../http'
import { CustomPropertyContainer } from '../../screens/admin/custom-type/CustomPropertyContainer'
import { MainComponentTypesContainer } from '../../screens/customer/main-component-types/CustomerMainComponentTypesContainer'
import { CustomerAccessControlScreen } from '../../screens/customer/CustomerAccessControlScreen'
import { LocalitiesScreen } from '../../screens/customer/localities/LocalitiesScreen'
import { LocalityGroupsScreen } from '../../screens/customer/location-groups/LocalityGroupsScreen'
import { ServicesContainer } from '../../screens/customer/services/ServicesContainer'
import { UserInvitesScreen } from '../../screens/customer/user-invites/UserInvitesScreen'
import FormSchemas from '../../screens/customer/form/FormSchemas'
import AddFormSchema from '../../screens/customer/form/AddFormSchema'
import EditFormSchema from '../../screens/customer/form/EditFormSchema'
import MainComponentDashboard from '../../screens/customer/mainComponents/MainComponentDashboard'
import Tasks from '../../screens/customer/tasks/Tasks'
import Resources from '../../screens/customer/tasks/resource/Resources'
import { DialogContextType, RoleTypes } from '../../enums'
import { useDialogCtx } from '../../hooks/context-hooks'
import { GlobalDocumentsForCustomer } from '../../screens/customer/global-documents/GlobalDocumentsForCustomer'
import { SupplierContainer } from '../../screens/customer/suppliers/SupplierContainer'
import { GlobalDocumentCustomerContainer } from '../../screens/admin/global-documents/GlobalDocumentCustomerContainer'
import TimeReports from '../../screens/customer/tasks/timeReports/TimeReports'
import { UsersContainer } from '../../screens/customer/users/UsersContainer'
import { Fragment, useEffect, useState } from 'react'
import { ServiceStationGroupsScreen } from 'src/screens/customer/service-station-group/ServiceStationGroupsScreen'
import { ServiceStationScreen } from 'src/screens/customer/service-station/ServiceStationScreen'
import { UserResources } from 'src/screens/customer/users/UserResources'
import { axios } from 'src/axios'
import { permissionBinding, permissionLevel } from 'src/constant'
import { SelectedMainComponentInCustomerAdmin } from 'src/screens/localities/mainComponents/components/SelectedMainComponentInLocality'
import { DeviationContainer } from 'src/screens/localities/deviations/DeviationContainer'
import { AuthorizationProvider } from 'src/components/common/Authorization'
import { permissionValue } from 'src/enums/permissionValue'
import { AuthorizationType } from 'src/enums/auth'
import { customerQueryKey } from 'src/query-keys'
import { DocumentAdminPage } from 'src/pages/locality/DocumentPage'
import { AccessControls } from '@lovoldsystem/authorization.client'
import { DocumentCategoriesContainer } from 'src/screens/customer/document-categories/DocumentCategoriesContainer'
export const CustomerAdminRoutes = () => {
  const { id } = useParams()
  const { data, isLoading } = useQuery(customerQueryKey.GET_CUSTOMER(id), () => getCustomer(id!))
  const [permission, setPermission] = useState(-1)
  const { dialogsCustom, showDialog } = useDialogCtx()
  const theme = useTheme()
  useEffect(() => {
    if (data) {
      axios.defaults.headers['X-Permissions'] = data.encryptedPermission
      setPermission(data.permission)
    }
  }, [data])

  return (
    <CustomerCtxProvider customer={data!} permission={permission}>
      <LoadingGuard loading={isLoading} showSpinner>
        <AuthorizationProvider
          permission={permission}
          permissions={[permissionValue.ClientAdminPermission]}
          self_auth={true}
          type={AuthorizationType.Page}
        >
          <Fragment>
            {Object.values(dialogsCustom[DialogContextType.customerAdminContext] ?? {})}
            <Stack
              style={{ backgroundColor: theme.palette.backgroundMain[theme.palette.mode] }}
              width={'100%'}
              minHeight={'calc(100vh - 96px)'}
              className="pb-4-core"
            >
              <Routes>
                <Route path={AppRoutesRouter.Customer.accessControl} element={<CustomerAccessControlScreen />} />
                <Route path={AppRoutesRouter.Customer.globalDocumentsAdmin} element={<GlobalDocumentsForCustomer />} />
                <Route
                  path={AppRoutesRouter.Customer.globalDocumentsCustomer}
                  element={<GlobalDocumentCustomerContainer />}
                />
                <Route
                  path={AppRoutesRouter.Customer.users}
                  element={<UsersContainer isGlobalServiceProvider={data?.isGlobalServiceStationProvider} />}
                />
                <Route path={AppRoutesRouter.Customer.userResources} element={<UserResources />} />
                <Route
                  path={AppRoutesRouter.Customer.accessControls}
                  element={
                    <AccessControls
                      permissionLevels={permissionLevel}
                      permissionBinding={permissionBinding}
                      clientId={parseInt(id || '')}
                      roleTypes={RoleTypes}
                      showDialog={showDialog}
                    />
                  }
                />
                <Route path={AppRoutesRouter.Customer.localities} element={<LocalitiesScreen />} />
                <Route path={AppRoutesRouter.Customer.serviceStations} element={<ServiceStationScreen />} />
                <Route path={AppRoutesRouter.Customer.localityGroup} element={<LocalityGroupsScreen />} />
                <Route path={AppRoutesRouter.Customer.serviceStationGroup} element={<ServiceStationGroupsScreen />} />
                <Route path={AppRoutesRouter.Customer.tasks} element={<Tasks />} />
                <Route path={AppRoutesRouter.Customer.resources} element={<Resources />} />
                <Route path={AppRoutesRouter.Customer.timeReports} element={<TimeReports />} />
                <Route path={AppRoutesRouter.Customer.userInvites} element={<UserInvitesScreen />} />
                <Route path={AppRoutesRouter.Customer.customProperty} element={<CustomPropertyContainer />} />
                <Route path={AppRoutesRouter.Customer.documentCategories} element={<DocumentCategoriesContainer />} />
                <Route path={AppRoutesRouter.Customer.mainComponentTypes} element={<MainComponentTypesContainer />} />
                <Route path={AppRoutesRouter.Customer.formSchemas} element={<FormSchemas />} />
                <Route path={AppRoutesRouter.Customer.formGenerator} element={<AddFormSchema />} />
                <Route path={AppRoutesRouter.Customer.editFormSchema} element={<EditFormSchema />} />
                <Route path={AppRoutesRouter.Customer.services} element={<ServicesContainer />} />
                <Route path={AppRoutesRouter.Customer.mainComponents} element={<MainComponentDashboard />} />
                <Route
                  path={AppRoutesRouter.Customer.mainComponent}
                  element={<SelectedMainComponentInCustomerAdmin />}
                />
                <Route
                  path={AppRoutesRouter.Customer.deviation}
                  element={<DeviationContainer context={CustomerCtx} />}
                />
                <Route path={AppRoutesRouter.Customer.suppliers} element={<SupplierContainer />} />
                <Route path={AppRoutesRouter.Customer.document} element={<DocumentAdminPage />} />
                <Route path="*" element={<Navigate to={AppRoutesRouter.Customer.accessControl} />} />
              </Routes>
            </Stack>
          </Fragment>
        </AuthorizationProvider>
      </LoadingGuard>
    </CustomerCtxProvider>
  )
}
