import GenericTable from '../../../components/table/GenericTable'
import { invalidateGlobalDocument, useDeleteRule } from '../../../api/global-documents'
import { GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { MdDelete, MdEdit } from 'react-icons/md'
import { MainButton } from '../../../components/MainButton'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { GlobalDocumentRuleAddDialog } from './GlobalDocumentRuleAddDialog'
import { IGlobalDocumentRule } from '../../../generated-types/global-documents'
import { GlobalDocumentRuleUpdateDialog } from './GlobalDocumentRuleUpdateDialog'
import i18n from '../../../i18n'
import { IMainComponentType } from '../../../generated-types/main-component-type'
import { MainComponentStatus } from '../../../enums'
import { getYearAndMonth } from '../../../utils/function'
import { toast } from 'react-toastify'
import { AlertDialog } from '../../../show-dialog'
import { RULE_RELEASED } from '../../../constant'
import { invalidateDocumentCategoryCache } from '../../../api/document-category'
import { invalidateDocument } from '../../../api/document'

export interface GlobalDocumentRulesTableProps {
  globalDocumentId: EntityId
  customerId?: EntityId | undefined
  globalDocumentRules: IGlobalDocumentRule[]
  isViewOnly?: boolean
}

export const GlobalDocumentRulesTable: React.FC<GlobalDocumentRulesTableProps> = ({
  globalDocumentId,
  customerId,
  globalDocumentRules,
  isViewOnly = false,
}) => {
  const { t: translate } = useTranslation(['global-document-rules', 'common'])
  const { showDialog } = useDialogCtx()

  const handleEditDocumentRule = useCallback(
    (documentRule: IGlobalDocumentRule) => {
      showDialog(GlobalDocumentRuleUpdateDialog, {
        title: translate('update-document-rule', { ns: 'global-document-rules' }),
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: {
          documentId: documentRule.documentId,
          documentRuleId: documentRule.id,
          initialValues: documentRule,
          currentCustomerId: customerId,
        },
      })
    },
    [customerId, showDialog, translate],
  )

  const { mutateAsync } = useDeleteRule(globalDocumentId)

  const handleDeleteRule = useCallback(
    async (id: EntityId) => {
      showDialog(AlertDialog, {
        componentProps: {
          title: translate('deleteRule', { ns: 'global-document-rules' }),
          text: translate('areYouSureDeleteRule', { ns: 'global-document-rules' }),
          onConfirm: async pop => {
            await mutateAsync(id).then(
              ({ data }) => {
                if (data === RULE_RELEASED) {
                  toast.warning(translate('RuleReleased', { ns: 'global-document-rules' }))
                } else {
                  toast(translate('deletedSuccessfully', { ns: 'common' }), { type: 'success' })
                }
                invalidateGlobalDocument.getRules(globalDocumentId)
                invalidateGlobalDocument.forApproval()
                invalidateGlobalDocument.approved()
                invalidateGlobalDocument.getCategories()
                customerId && invalidateDocumentCategoryCache.getDocumentCategoriesWithDocumentCountByKey()
                customerId && invalidateDocument.getDocuments(customerId)
                pop(true)
              },
              _ => {
                toast.error(translate('unExpectedError', { ns: 'common-api-message' }))
                pop(false)
              },
            )
          },
        },
      })
    },
    [showDialog, translate, mutateAsync, globalDocumentId],
  )

  const HandleAddDocumentRule = (documentId: EntityId) => {
    showDialog(GlobalDocumentRuleAddDialog, {
      title: translate('add-document-rule', { ns: 'global-document-rules' }),
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
      componentProps: {
        documentId: documentId,
        currentCustomerId: customerId,
      },
    })
  }

  const getTranslatedLabel = (property: IMainComponentType) =>
    ['en', 'us'].includes(i18n.language)
      ? JSON.parse(property.name as string).en
      : JSON.parse(property.name as string).no

  const BASE_STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'mainComponentType',
        headerName: translate('main-component-type', { ns: 'global-document-rules' }),
        flex: 1,
        valueGetter: ({ row }) =>
          row.mainComponentType != null
            ? getTranslatedLabel(row.mainComponentType)
            : translate('all-types', { ns: 'global-document-rules' }),
      },
      {
        field: 'mainComponentStatus',
        headerName: translate('main-component-status', { ns: 'global-document-rules' }),
        flex: 1,
        valueGetter: ({ row }) =>
          row.mainComponentStatus != null
            ? translate(MainComponentStatus[row.mainComponentStatus], { ns: 'main-component' })
            : translate('all-status', { ns: 'global-document-rules' }),
      },
      {
        field: 'producedFromYear',
        headerName: translate('produced-from', { ns: 'global-document-rules' }),
        flex: 1,
        valueGetter: ({ row }) => getYearAndMonth(row.producedFromYear, row.producedFromMonth),
      },
      {
        field: 'producedFor',
        headerName: translate('produced-for', { ns: 'global-document-rules' }),
        flex: 1,
        valueGetter: ({ row }) => getYearAndMonth(row.producedForYear, row.producedForMonth),
      },
    ],
    [translate],
  )

  const ACTION_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        headerName: 'Actions',
        renderCell: ({ row }) => [
          <Tooltip title={`${translate('edit', { ns: 'common' })}`} key={'edit'}>
            <IconButton onClick={() => handleEditDocumentRule(row)}>
              <MdEdit size={24} />
            </IconButton>
          </Tooltip>,
          <Tooltip title={`${translate('delete', { ns: 'common' })}`} key={'delete'}>
            <IconButton onClick={() => handleDeleteRule(row.id)}>
              <MdDelete size={24} />
            </IconButton>
          </Tooltip>,
        ],
      },
    ],
    [handleDeleteRule, handleEditDocumentRule, translate],
  )
  const CUSTOMER_STATIC_COLUMN: GridColumns = useMemo(
    () => [
      {
        field: 'customerName',
        headerName: translate('customer', { ns: 'global-document-rules' }),
        flex: 1,
        valueGetter: ({ row }) => row.customer?.name ?? translate('all-customers', { ns: 'global-document-rules' }),
      },
      ...BASE_STATIC_COLUMNS,
      ...ACTION_COLUMNS,
    ],
    [ACTION_COLUMNS, BASE_STATIC_COLUMNS, translate],
  )

  const ADMIN_STATIC_COLUMN: GridColumns = useMemo(
    () => (isViewOnly ? [...BASE_STATIC_COLUMNS] : [...BASE_STATIC_COLUMNS, ...ACTION_COLUMNS]),
    [isViewOnly, BASE_STATIC_COLUMNS, ACTION_COLUMNS],
  )

  return (
    <GenericTable
      id="global-document-rules-table"
      data={globalDocumentRules || []}
      columns={customerId ? ADMIN_STATIC_COLUMN : CUSTOMER_STATIC_COLUMN}
      pageSize={10}
      rowColor="#e5e4e2"
      getDetailPanelHeight={() => 'auto'}
      autoHeight={false}
      height="300px"
      enableToolbar={!isViewOnly}
      toolbarOptions={
        isViewOnly
          ? []
          : [
              {
                label: translate('add-rule', { ns: 'global-document-rules' }),
                Component: (
                  <MainButton
                    onClick={() => {
                      HandleAddDocumentRule(globalDocumentId as EntityId)
                    }}
                  >
                    {translate('add', { ns: 'common' })}
                  </MainButton>
                ),
              },
            ]
      }
    />
  )
}
