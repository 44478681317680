import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export const DownloadController: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { t } = useTranslation(['common'])
  const context = useLeafletContext()
  const container = context.layerContainer || context.map
  const olexDownload = new L.Control({ position: 'topright' })
  olexDownload.onAdd = function () {
    const div = L.DomUtil.create('div', 'leaflet-control-layers leaflet-control')
    div.innerHTML =
      '<img style="width: 45px; padding: 7px; padding-bottom: 3px;cursor: pointer;" src="//map.havbruksloggen.no/Content/icons/gzdl.png"/>'
    div.onclick =
      onClick ??
      (() => {
        toast(t('export-error'), { type: 'error' })
      })
    return div
  }
  useEffect(() => {
    olexDownload.addTo(container as any)
  }, [])
  return <></>
}
