import { IPositionDrawing } from 'src/generated-types/position-drawing'
import { IMooring } from '../generated-types'
import { MooringDropdownDto } from '../generated-types/mooring-drop-down-dto'
import { IMooringsRequestDto } from '../generated-types/moorings-request-dto'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'
import { mooringQueryKeys } from 'src/query-keys'
import { usePatch } from '@lovoldsystem/core.client'

const baseUrl = (customerId: EntityId) => `customers/${customerId}`

export const mooringsEndpoints = {
  getMoorings(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings`
  },
  getInactiveMoorings(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/inactive-moorings`
  },
  getMooringsForMap(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings-for-map`
  },
  getMooringsForService(customerId: EntityId, serviceId: EntityId) {
    return `${baseUrl(customerId)}/services/${serviceId}/getAllForService`
  },
  getMooring(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings/${id}/get-mooring`
  },
  createMooring(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings`
  },
  updateMooring(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings/${id}`
  },
  activateInactiveMooring(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings/${id}/active-mooring`
  },
  updatePositionTemplates(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings/${id}/position-templates`
  },
  deleteMooring(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings`
  },
  getMapSvg(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings/${id}/map-svg`
  },
  archiveMooring(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings/${id}/archive`
  },
  getAllMooringsByLocalityId(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/get-all-moorings`
  },
  getSvgForPosition(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings/${mooringId}/get-svg-for-position`
  },

  regeneratePositions(customerId: EntityId, localityId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/localities/${localityId}/moorings/${id}/regenerate-positions`
  },
  updatePositionDrawingsOfaMooring(customerId, mooringId) {
    return `${baseUrl(customerId)}/moorings/${mooringId}/update-position-drawings-for-mooring`
  },
}

export const invalidateMooringCache = {
  getMooringsForService(customerId: EntityId, serviceId: EntityId) {
    queryClient.invalidateQueries(mooringsEndpoints.getMooringsForService(customerId, serviceId))
  },
  getGetMooring(
    customerId?: EntityId,
    localityId?: EntityId,
    id?: EntityId,
    queryParams?: { isWithAllInfo?: boolean; isWithPositionData?: boolean },
  ) {
    queryClient.invalidateQueries(mooringQueryKeys.GET_MOORING(customerId, localityId, id, queryParams))
  },
  getGetInactiveMoorings(customerId: EntityId, localityId) {
    queryClient.invalidateQueries(mooringQueryKeys.GET_INACTIVE_MOORINGS(customerId, localityId))
  },
  getGetMoorings(
    customerId: EntityId,
    localityId: EntityId,
    isWithPositionData?: boolean,
    page: number = apiConsts.Page,
    pageSize: number = apiConsts.PageSize,
  ) {
    queryClient.invalidateQueries([
      mooringsEndpoints.getMoorings(customerId, localityId),
      {
        isWithPositionData,
        page,
        pageSize,
      },
    ])
  },
  getMapSvg(customerId?: EntityId, localityId?: EntityId, id?: EntityId) {
    queryClient.invalidateQueries(mooringQueryKeys.GET_MAP_SVG(customerId, localityId, id))
  },
  getSvgForPosition(customerId?: EntityId, localityId?: EntityId, mooringId?: EntityId, queryParams?: { positionId: number }) {
    queryClient.invalidateQueries(mooringQueryKeys.GET_SVG_FOR_POSITION(customerId, localityId, mooringId, queryParams))
  },
  getMooringsForMap(customerId?: EntityId, localityId?: EntityId, queryParams?: IMooringsRequestDto) {
    queryClient.invalidateQueries(mooringQueryKeys.GET_MOORINGS_FOR_MAP(customerId, localityId, queryParams))
  },
  getAllMooringsByLocalityId(customerId?: EntityId, localityId?: EntityId,) {
    queryClient.invalidateQueries(mooringQueryKeys.GET_MOORING_FOR_DROPDOWN(customerId, localityId))
  }
}

export const useGetMoorings = (
  customerId: EntityId,
  localityId: EntityId,
  queryParams?: IMooringsRequestDto,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IMooring>>(mooringsEndpoints.getMoorings(customerId, localityId), {
    ...queryParams,
    page,
    pageSize,
  })

  export const useGetInactiveMoorings = (
    customerId: EntityId,
    localityId: EntityId,
  ) =>
    useGet<Array<IMooring>>(mooringsEndpoints.getInactiveMoorings(customerId, localityId), undefined, {
      queryKey: mooringQueryKeys.GET_INACTIVE_MOORINGS(customerId, localityId),
    })

export const useGetMooringsForMap = (customerId: EntityId, localityId: EntityId, queryParams?: IMooringsRequestDto) =>
  useGet<Array<IMooring>>(
    mooringsEndpoints.getMooringsForMap(customerId, localityId),
    {
      ...queryParams,
    },
    { queryKey: mooringQueryKeys.GET_MOORINGS_FOR_MAP(customerId, localityId, queryParams) },
  )

export const useGetMooringsForService = (
  customerId: EntityId,
  serviceId: EntityId,
  page?: number,
  pageSize?: number,
) =>
  useGet<IPaginatedResult<IMooring>>(mooringsEndpoints.getMooringsForService(customerId, serviceId), {
    page,
    pageSize,
  })

export const useGetMooring = (
  customerId: EntityId,
  localityId: EntityId,
  id: EntityId,
  queryParams?: { isWithAllInfo?: boolean; isWithPositionData?: boolean; isIgnoreQueryFilters?: boolean },
) =>
  useGet<IMooring>(
    mooringsEndpoints.getMooring(customerId, localityId, id),
    { ...queryParams },
    {
      enabled: id != undefined ? true : false,
      queryKey: mooringQueryKeys.GET_MOORING(
        customerId,
        localityId != null ? localityId : undefined,
        id != null ? id : undefined,
        queryParams,
      ),
    },
  )

export const useAddMooring = (customerId: EntityId, localityId: EntityId) =>
  usePost<IMooring, IMooring>(mooringsEndpoints.createMooring(customerId, localityId))

export const useUpdatePositionDrawingsOfaMooring = (customerId: EntityId, mooringId: EntityId) =>
  usePost<IMooring, { positionDrawings: IPositionDrawing[]; initialPositionDrawings?: IPositionDrawing[] }>(
    mooringsEndpoints.updatePositionDrawingsOfaMooring(customerId, mooringId),
  )

export const useUpdateMooring = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  usePut<IMooring, IMooring>(mooringsEndpoints.updateMooring(customerId, localityId, id))

export const useActivateMooring = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  usePatch<IMooring, undefined>(mooringsEndpoints.activateInactiveMooring(customerId, localityId, id))

export const useArchiveMooring = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  usePut<IMooring, IMooring>(mooringsEndpoints.archiveMooring(customerId, localityId, id))

export const useUpdatePositionTemplates = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  usePut<any, { positionIdList: number[]; positionDrawingId: number }>(
    mooringsEndpoints.updatePositionTemplates(customerId, localityId, id),
  )

export const useDeleteMooring = (customerId: EntityId, localityId: EntityId) =>
  useDelete<IMooring>(mooringsEndpoints.deleteMooring(customerId, localityId))

export const useGetMapSvg = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  useGet<string>(mooringsEndpoints.getMapSvg(customerId, localityId, id), undefined, {
    enabled: id != null && (id as number) > 0,
    queryKey: mooringQueryKeys.GET_MAP_SVG(customerId, localityId, id),
  })

export const useGetSvgForPosition = (
  customerId: EntityId,
  localityId: EntityId,
  mooringId: EntityId,
  queryParams?: { positionId: number },
) =>
  useGet<string>(
    mooringsEndpoints.getSvgForPosition(customerId, localityId, mooringId),
    { ...queryParams },
    {queryKey: mooringQueryKeys.GET_SVG_FOR_POSITION(customerId, localityId, mooringId, queryParams)}
  )

export const useRegeneratePositions = (customerId: EntityId, localityId: EntityId, id: EntityId) =>
  usePut<IMooring, IMooring>(mooringsEndpoints.regeneratePositions(customerId, localityId, id))

export const useGetAllMooringsByLocalityId = (customerId: EntityId, localityId: EntityId) =>
  useGet<Array<MooringDropdownDto>>(mooringsEndpoints.getAllMooringsByLocalityId(customerId, localityId), undefined, {
    queryKey: mooringQueryKeys.GET_MOORING_FOR_DROPDOWN(customerId, localityId),
  })
