import { MapContainer, Pane } from 'react-leaflet'
import L, { LatLngExpression } from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import { ILocality, IMooring, IMooringType, IPosition, IPositionType } from '../../../../generated-types'
import { useEffect, useMemo, useState } from 'react'
import { ElectricityLine } from './components/ElectricityLine'
import { CreateLayerGroup } from './ComponentLayer'
import { DownloadController } from './components/DownloadController'
import { getKeyValue, maxZoom } from './constraints'
import { ControlLayer } from './layers/ControlLayer'
import { IGroupSelector } from './MapComponent'
import MapBoundsComponent from './MapBoundsComponent'
import { EnlargeComponent } from './components/EnlargeIconComponent'
import { CenterChangeComponent } from './CenterChangeComponent'
import { BaseTileLayer } from './components/MapStaticContent'
import { styled } from '@mui/material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { LocationController } from './components/LocationController'
import { BoatController } from './components/BoatController'
import VesselLayers from './layers/VesselLayers'

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

L.Marker.prototype.options.icon = DefaultIcon

export interface MapComponentProps {
  positions?: IPosition[]
  moorings?: IMooring[]
  localities?: ILocality[]
  center?: LatLngExpression
  zoom?: number
  mooringType?: IMooringType
  zoomControl?: boolean
  scrollWheelZoom?: boolean
  layerControl?: boolean
  attributionControl?: boolean
  downloadControl?: boolean
  onClickDownload?: () => void
  isModalView?: boolean
  navigateLocalityWhenClick?: boolean
  removeAttribution?: boolean
  showEnlargeIcon?: boolean
  onClickEnlarge?: () => void
  showCageStatusPopOver?: boolean
  onPositionSelect?: (positionId: number, positionName: string) => void
  enableBoatController?: boolean
  enableLocationController?: boolean
  enableCluster?: boolean
  enableVessel?: boolean
  customUrl?: string
}

const LeafletMapContainer = ({
  positions,
  moorings,
  localities,
  center,
  zoom,
  mooringType,
  zoomControl = true,
  layerControl = true,
  downloadControl = false,
  showEnlargeIcon: showFullScreenIcon = false,
  attributionControl = true,
  scrollWheelZoom = true,
  onClickDownload,
  onClickEnlarge,
  isModalView = false,
  navigateLocalityWhenClick = false,
  removeAttribution = false,
  showCageStatusPopOver = false,
  onPositionSelect,
  enableBoatController = false,
  enableLocationController = false,
  enableCluster = false,
  enableVessel = false,
  customUrl,
}: MapComponentProps) => {
  const [vessel, setVessel] = useState(enableVessel)
  const [enableLocality, setEnableLocality] = useState(true)
  const [groupSelector, setGroupSelector] = useState<IGroupSelector>({
    facility: true,
    fleet: true,
    deviation: false,
    barentsWatch: false,
    aquaculture: false,
  })

  let mapPosition = center ?? [67.28, 14.40501]

  if (
    positions &&
    positions[0] &&
    positions[0].latitude &&
    positions[0].longitude &&
    positions[0].latitude !== 0 &&
    positions[0].longitude !== 0
  )
    mapPosition = [positions[0].latitude, positions[0].longitude]

  const zoomLevel = zoom ?? 16

  const [electricityLinePositions, setElectricityLinePositions] = useState<IPosition[]>([])

  useEffect(() => {
    if (positions) {
      setElectricityLinePositions(positions.filter(p => p.type == IPositionType.ElectricityLine))
    }
  }, [positions])

  useEffect(() => {
    if (moorings) {
      let p: IPosition[] = []
      moorings.forEach(m => {
        const pos = m.positions.filter(p => p.type == IPositionType.ElectricityLine)
        p = [...p, ...pos]
      })
      setElectricityLinePositions(p)
    }
  }, [moorings])

  useEffect(() => {
    if (removeAttribution) {
      const attribution = document.getElementsByClassName('leaflet-control-attribution')[0] as HTMLElement
      //attribution.style.display = removeAttribution ? 'none' : 'block'
    }
  }, [removeAttribution])

  useEffect(() => {
    if (localities) {
      let p: IPosition[] = []
      localities.forEach(l => {
        l.moorings.forEach(m => {
          const pos = m.positions.filter(p => p.type == IPositionType.ElectricityLine)
          p = [...p, ...pos]
        })
      })
      setElectricityLinePositions(p)
    }
  }, [localities])

  const groupSelectorKeys = useMemo(() => {
    switch (mooringType) {
      case 0:
        return [
          { key: 'fleet', value: 5 },
          { key: 'deviation', value: 11 },
          { key: 'barentsWatch', value: 14 },
          { key: 'aquaculture', value: 19 },
        ]
        break
      case 1:
      case 2:
      case 3:
        return [
          { key: 'facility', value: 5 },
          { key: 'deviation', value: 11 },
          { key: 'barentsWatch', value: 14 },
          { key: 'aquaculture', value: 19 },
        ]
        break
      default:
        return [
          { key: 'facility', value: 5 },
          { key: 'fleet', value: 11 },
          { key: 'deviation', value: 17 },
          { key: 'barentsWatch', value: 20 },
          { key: 'aquaculture', value: 25 },
        ]
        break
    }
  }, [mooringType])

  const items = document.querySelectorAll('leaflet-control-layers-selector')
  useEffect(() => {
    const items = document.querySelectorAll('.leaflet-control-layers-selector')
    if (layerControl && items) {
      groupSelectorKeys.forEach(key => {
        if (items[key.value]) {
          items[key.value].addEventListener('click', () => {
            const checked = (items[key.value] as HTMLInputElement).checked
            setGroupSelector(prev => {
              return {
                ...prev,
                ...getKeyValue(key.key, checked),
              }
            })
          })
        }
      })
    }
    return () => {
      groupSelectorKeys.forEach(key => {
        if (layerControl && items && items[key.value]) {
          items[key.value].removeEventListener('click', () => {
            const checked = (items[key.value] as HTMLInputElement).checked
            setGroupSelector(prev => {
              return {
                ...prev,
                ...getKeyValue(key.key, checked),
              }
            })
          })
        }
      })
    }
  }, [items, groupSelectorKeys])

  useEffect(() => {
    const items = document.querySelectorAll('.leaflet-control-layers-selector')
    if (layerControl && items) {
      groupSelectorKeys.forEach(key => {
        if (items[key.value]) {
          items[key.value].addEventListener('click', () => {
            const checked = (items[key.value] as HTMLInputElement).checked
            setGroupSelector(prev => {
              return {
                ...prev,
                ...getKeyValue(key.key, checked),
              }
            })
          })
        }
      })
    }
    return () => {
      groupSelectorKeys.forEach(key => {
        if (layerControl && items && items[key.value]) {
          items[key.value].removeEventListener('click', () => {
            const checked = (items[key.value] as HTMLInputElement).checked
            setGroupSelector(prev => {
              return {
                ...prev,
                ...getKeyValue(key.key, checked),
              }
            })
          })
        }
      })
    }
  }, [items, groupSelectorKeys])

  return (
    <MapBaseContainer id="controller-div">
      <MapContainer
        center={(center as LatLngExpression) ?? [0, 0]}
        zoom={zoomLevel}
        zoomDelta={0.25}
        maxZoom={maxZoom}
        zoomControl={zoomControl}
        attributionControl={attributionControl}
        zoomSnap={0}
        scrollWheelZoom={scrollWheelZoom}
        className="w-full h-full rounded-lg"
      >
        {center && <CenterChangeComponent center={center as LatLngExpression} />}
        {!center && <MapBoundsComponent localities={localities} moorings={moorings} positions={positions} />}
        <BaseTileLayer customUrl={customUrl} />
        <Pane name="boats" style={{ zIndex: 404 }} />
        <Pane name="farms" style={{ zIndex: 404 }} />
        <Pane name="anchorPoint" style={{ zIndex: 404 }} />
        <Pane name="bridle" style={{ zIndex: 404 }} />
        <Pane name="frameLine" style={{ zIndex: 404 }} />
        <Pane name="mooringLine" style={{ zIndex: 404 }} />
        <Pane name="innerMooringLine" style={{ zIndex: 404 }} />
        <Pane name="electricityLine" style={{ zIndex: 410 }} />
        <Pane name="corner" style={{ zIndex: 409 }} />
        <Pane name="ring" style={{ zIndex: 409 }} />
        <Pane name="net" style={{ zIndex: 409 }} />
        <Pane name="cage" style={{ zIndex: 409 }} />
        <Pane name="polygon" style={{ zIndex: 404 }} />
        <Pane name="anchorPointUpdated" style={{ zIndex: 405 }} />
        <Pane name="bridleUpdated" style={{ zIndex: 405 }} />
        <Pane name="frameLineUpdated" style={{ zIndex: 405 }} />
        <Pane name="mooringLineUpdated" style={{ zIndex: 405 }} />
        <Pane name="innerMooringLineUpdated" style={{ zIndex: 405 }} />
        <Pane name="electricityLineUpdated" style={{ zIndex: 412 }} />
        <Pane name="cornerUpdated" style={{ zIndex: 410 }} />
        <Pane name="ringUpdated" style={{ zIndex: 410 }} />
        <Pane name="netUpdated" style={{ zIndex: 410 }} />
        <Pane name="cageUpdated" style={{ zIndex: 410 }} />
        <Pane name="polygonUpdated" style={{ zIndex: 405 }} />
        <Pane name="barents-watch-zones" style={{ zIndex: 500 }} />
        <Pane name="circlePoints" style={{ zIndex: 600 }} />
        {layerControl ? (
          <ControlLayer
            localities={localities as ILocality[]}
            positions={positions as IPosition[]}
            moorings={moorings as IMooring[]}
            mooringType={mooringType}
            zoom={zoomLevel}
            center={center}
            groupSelector={groupSelector}
            navigateLocalityWhenClick={navigateLocalityWhenClick}
            isModalView={isModalView as boolean}
            showCageStatusPopOver={showCageStatusPopOver}
            onPositionSelect={onPositionSelect}
            enableCluster={enableCluster}
            enableLocality={enableLocality}
          />
        ) : (
          <CreateLayerGroup
            positions={positions as IPosition[]}
            moorings={moorings as IMooring[]}
            localities={localities as ILocality[]}
            mapPosition={mapPosition}
            steelFrame={mooringType == IMooringType.SteelCage}
            isFleet={mooringType == IMooringType.Barge}
            isModalView={isModalView}
            mooringType={mooringType}
            showCageStatusPopOver={showCageStatusPopOver}
            onPositionSelect={onPositionSelect}
          />
        )}
        {showFullScreenIcon && <EnlargeComponent onClick={onClickEnlarge} />}
        {enableVessel && vessel && <VesselLayers />}
        {electricityLinePositions.length > 0 && <ElectricityLine polygonPositions={electricityLinePositions} />}
        {downloadControl && <DownloadController onClick={onClickDownload} />}
        {enableLocationController && (
          <LocationController
            onClick={() => {
              setEnableLocality(isLocality => !isLocality)
            }}
          />
        )}
        {enableBoatController && (
          <BoatController
            enable={vessel}
            onClick={() => {
              setVessel(vesselData => !vesselData)
            }}
          />
        )}
      </MapContainer>
    </MapBaseContainer>
  )
}

export const MapBaseContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  '& .leaflet-control-layers-separator': {
    display: 'none',
  },
  '& .leaflet-control-layers-expanded': {
    '& .leaflet-control-layers-list': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& .leaflet-control-layers-overlays': {
      '& label': {
        '& .leaflet-control-layers-separator': {
          display: 'box',
        },
      },
      '& label:last-child': {
        '& input': {
          display: 'none',
        },
      },
    },
  },
  '& .leaflet-control-layers-toggle': {
    backgroundImage: `url(${'/icons/map-layers.svg'})`,
    backgroundColor: getThemeColor(theme, MUIThemeColors.white),
    height: '30px',
    width: '30px',
    padding: '20px',
    borderRadius: '50%',
  },
  '& .leaflet-control-zoom-in, .leaflet-control-zoom-out': {
    color: getThemeColor(theme, MUIThemeColors.primaryLight),
    backgroundColor: getThemeColor(theme, MUIThemeColors.white),
  },
  '& .leaflet-control-zoom-in:hover, .leaflet-control-zoom-out:hover': {
    color: getThemeColor(theme, MUIThemeColors.primaryMain),
    backgroundColor: getThemeColor(theme, MUIThemeColors.secondaryLight),
  },
  '& .leaflet-top.leaflet-left': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '1px',
  },
}))

export default LeafletMapContainer
