import { LatLngExpression } from 'leaflet'
import { Polyline, Tooltip, useMap, useMapEvent } from 'react-leaflet'
import { grayedOpacity, mapOptions, minZoom, opacity } from '../constraints'
import { IPosition } from '../../../../../generated-types'
import { getPositionName } from '../utils'
import { memo, useEffect, useState } from 'react'

interface IInnerMooringLineProps {
  position: IPosition
  navigateToPosition: (positionId: number) => void
}

export const InnerMooringLine: React.FC<IInnerMooringLineProps> = memo(({ position, navigateToPosition }) => {
  const map = useMap()
  const [zoomLevel, setZoomLevel] = useState(Math.round(map.getZoom()))
  
  useEffect(() => { 
    setZoomLevel(Math.floor(map.getZoom())) 
  }, [map.getZoom()])

  const handleZoomEnd = event => {
    const newZoomLevel = event.target.getZoom()
    setZoomLevel(Math.round(newZoomLevel))
  }
  useMapEvent('zoomend', handleZoomEnd)
  if (position.isHidden || !position.connectedPositions[0]) return <></>

  let latLongs = [] as LatLngExpression[]

  if (position.connectedPositions[0] && position.connectedPositions[1]) {
    latLongs = [
      [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
      [position.connectedPositions[1].latitude, position.connectedPositions[1].longitude],
    ]
  } else {
    latLongs = [
      [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
      [position.connectedPositions[1].latitude, position.connectedPositions[1].longitude],
    ]
  }

  const pathOptions = {
    weight: 4,
    simpleline: 1.6,
    opacity: position.isTransparent ? grayedOpacity : opacity,
    fillOpacity: position.isTransparent ? grayedOpacity : opacity,
    color:
      position.deviationsCount > 0
        ? opacity !== 1.0
          ? mapOptions.mooringColor
          : mapOptions.deviationColor
        : mapOptions.innerMooringColor,
    pane: position.isWorkAssignmentPosition ? 'innerMooringLineUpdated' : 'innerMooringLine',
  }

  const toolTip = `${mapOptions.lang.innerMooringLine} ${getPositionName(position)}`
  pathOptions.weight = zoomLevel - minZoom <= 0 ? 1 : zoomLevel - minZoom

  return (
    <Polyline
      key={position.id}
      positions={latLongs as LatLngExpression[]}
      pathOptions={pathOptions}
      pane={pathOptions.pane}
      eventHandlers={{
        click: () => {
          navigateToPosition(position.id)
        },
      }}
    >
      <Tooltip sticky>
        <strong>{toolTip}</strong>
      </Tooltip>
    </Polyline>
  )
})
