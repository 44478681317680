import L, { LatLngExpression } from 'leaflet'
import { Polyline, Marker, Tooltip, useMapEvent, useMap } from 'react-leaflet'
import { IConnectedPosition, IPosition } from '../../../../../generated-types'
import { mapOptions, deviationPositions, totalDeviationMultiplier, opacity, grayedOpacity, minZoom } from '../constraints'
import { midpoint, getBearing, destVincenty, getPositionName } from '../utils'
import ankerMaker from '../../../../../assets/anker.png'
import landMaker from '../../../../../assets/land.png'
import { memo, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material'

const getParams = (position: IPosition, ploughLength?: number, positionPrefix?: string) => {
  ploughLength = ploughLength || 30
  positionPrefix = positionPrefix || ''

  let latlngs: number[][] = []
  if (!isNaN(position.connectedPositions[0].latitude) && !isNaN(position.connectedPositions[0].longitude)) {
    const middle = midpoint(
      position.longitude,
      position.latitude,
      position.connectedPositions[0].longitude,
      position.connectedPositions[0].latitude,
      0.5,
    )

    const bearing = getBearing(middle[0], middle[1], position.latitude, position.longitude)

    const destMid = destVincenty(middle[0], middle[1], bearing, ploughLength)

    latlngs = [
      [position.latitude, position.longitude],
      [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
    ]
  } else {
    latlngs = [
      [position.latitude, position.longitude],
      [position.connectedPositions[0].latitude, position.connectedPositions[0].longitude],
    ]
  }

  //TODO: Harten rregulloje si me poshte
  //.addTo(isFleet ? lgFleetMoorings : lgMoorings)

  const markerPosition = [position.latitude, position.longitude]
  //TODO: ikonat rregulloj si me poshte
  //.addTo(isFleet ? lgFleetMoorings : lgMoorings)

  let bearing = 0
  const destPos = []
  const currentPoint = {}

  let posisjonText = positionPrefix + getPositionName(position)
  if (posisjonText.length < 2) {
    posisjonText = ' ' + posisjonText + ' '
  } else if (posisjonText.length < 3) {
    posisjonText = posisjonText + ' '
  }

  const posLength = posisjonText.length

  if (
    position.connectedPositions[1] &&
    !isNaN(position.connectedPositions[1].longitude) &&
    !isNaN(position.connectedPositions[1].latitude) &&
    latlngs.length == 5
  ) {
    bearing = getBearing(latlngs[4][0], latlngs[4][1], latlngs[3][0], latlngs[3][1])

    const destPos = destVincenty(latlngs[4][0], latlngs[4][1], bearing, posLength * 16)
    // currentPoint = container.latLngToLayerPoint(L.latLng(latlngs[4][0], latlngs[4][1]))
  } else {
    bearing = getBearing(latlngs[0][0], latlngs[0][1], latlngs[1][0], latlngs[1][1])
    const destPos = destVincenty(latlngs[1][0], latlngs[1][1], bearing, posLength * 16)
    // currentPoint = container.latLngToLayerPoint(L.latLng(latlngs[1][0], latlngs[1][1]))
  }

  // const newpoint = container.latLngToLayerPoint(destPos)
  // const xoffset = (posLength * 9.16)

  // const newOffset = [xoffset + currentPoint.x - newpoint.x, currentPoint.y - newpoint.y]
  // if (newOffset[1] > 18) newOffset[1] = 18 //fix to big space on y when over 3 letters.
  // if (newOffset[1] < -18) newOffset[1] = -18 //fix to big space on y when over 3 letters.

  //newOffset = [xoffset + 0, 0]
  // ankerpunkt.bindTooltip(posisjonText, { permanent: true, className: 'fortoyninglabel', offset: [newOffset[0], newOffset[1]], direction: 'left', opacity: opacity })

  const toolTip = `${mapOptions.lang.mooringLine} ${getPositionName(position)}`
  // attachTooltip(content, mooringLine, position.id)
  // attachTooltip(content, ankerpunkt, position.id)

  if (position.deviationsCount != 0) {
    deviationPositions.push([
      position.latitude,
      position.longitude,
      totalDeviationMultiplier * position.deviationsCount,
    ])
  }

  return { latlngs, markerPosition, toolTip }
}

interface IMooringLineProps {
  position: IPosition
  navigateToPosition: (positionId: number) => void
  ploughLength?: number
  positionPrefix?: string
}

export const MooringLine: React.FC<IMooringLineProps> = memo(({
  position,
  navigateToPosition,
  ploughLength,
  positionPrefix,
}) => {
  const [permanent, setPermanent] = useState(true)
  const map = useMap()
  const [zoomLevel, setZoomLevel] = useState(Math.round(map.getZoom()))

  useEffect(() => { 
    setZoomLevel(Math.floor(map.getZoom())) 
  }, [map.getZoom()])

  const fontSize = useMemo(() => {
    return (zoomLevel - minZoom) <= 0 ? 8 : (zoomLevel - minZoom) * 8
  }, [zoomLevel, minZoom, permanent])

  const handleZoomEnd = event => {
    const newZoomLevel = event.target.getZoom()

    setZoomLevel(Math.round(newZoomLevel))
    if (newZoomLevel < minZoom) {
      setPermanent(false)
    } else {
      setPermanent(true)
    }
  }
  useMapEvent('zoomend', handleZoomEnd)

  const newIcon = useMemo(() => {
    return L.icon({
      iconUrl: !position.Anker === true ? ankerMaker : landMaker,
      iconSize: [zoomLevel - 4 , zoomLevel - 4],
      pane: position.Anker === true ? 'anchorPoint' : 'mooringLine',
    })
  }, [position, zoomLevel])

  const generateMoorings = true
  if (position.isHidden || !generateMoorings || !position.connectedPositions[0]) return <></> //hide if visible set to false.

  const getDirection = (position: IPosition, connectedPosition: IConnectedPosition) => {
    const difLong = position.longitude - connectedPosition.longitude
    const difLat = position.latitude - connectedPosition.latitude
    if (Math.abs(difLong) > Math.abs(difLat)) {
      if (difLong > 0) return 'right'
      return 'left'
    } else {
      if (difLat > 0) return 'top'
      return 'bottom'
    }
  }

  const pathOptions = {
    weight: zoomLevel - minZoom <= 0 ? 1 : zoomLevel - minZoom,
    simpleline: 1.6,
    opacity: position.isTransparent ? grayedOpacity : opacity,
    fillOpacity: position.isTransparent ? grayedOpacity : opacity,
    color:
      position.deviationsCount > 0
        ? opacity !== 1.0
          ? mapOptions.mooringColor
          : mapOptions.deviationColor
        : mapOptions.mooringColor,
    pane: position.isWorkAssignmentPosition ? 'mooringLineUpdated' : 'mooringLine',
  }

  const { latlngs, markerPosition, toolTip } = getParams(position, ploughLength, positionPrefix)

  return (
    <Polyline
      key={position.id}
      positions={latlngs as LatLngExpression[]}
      pathOptions={pathOptions}
      pane={pathOptions.pane}
      eventHandlers={{
        click: () => {
          navigateToPosition(position.id)
        },
      }}
    >
      <Marker
        position={markerPosition as LatLngExpression}
        icon={newIcon}
        eventHandlers={{
          click: () => {
            navigateToPosition(position.id)
          },
        }}
      >
        {permanent && (
          <StyledTooltip
            permanent
            direction={getDirection(position, position.connectedPositions[0])}
            offset={[0, 0]}
          >
            <strong style={{ fontSize: fontSize }}>{getPositionName(position)}</strong>
          </StyledTooltip>
        )}
      </Marker>
      <Tooltip sticky>
        <strong>{toolTip}</strong>
      </Tooltip>
    </Polyline>
  )
})

const StyledTooltip = styled(Tooltip)({
  backgroundColor: 'transparent',
  border: 'none',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
})
