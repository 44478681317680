import { IPaginatedResult } from 'src/api/paginated-result'
import { ILocality } from 'src/generated-types'
import { IMainComponent } from 'src/generated-types/main-component'

export const validationCageProperties=(locality:ILocality,mainComponents:IPaginatedResult<IMainComponent>)=>{
  //initial property validate object  
  const propertyObject = {
        hs: {
          value: locality.hs,
          isExceed: false,
        },
        vc5m: {
          value: locality.vC5M,
          isExceed: false,
        },
        vc15m: {
          value: locality.vC15M,
          isExceed: false,
        },
        solidity: {
          value: 0,
          isExceed: false,
        },
        circumferences: {
          value:0,
          isExceed: false,
        },
      }
      mainComponents?.items?.map(mainComponent => {
        if(mainComponent?.mainComponentProperties && mainComponent?.mainComponentProperties?.length > 0){
          let validationCheck = true
      
          //validate hs, vc5m and vc15m values 
          mainComponent?.mainComponentProperties?.map(property => {
            if ((propertyObject?.hs?.value && property.hs >= propertyObject?.hs?.value) 
              && (propertyObject?.vc5m?.value && property.vC5 >= propertyObject?.vc5m?.value)
            && (propertyObject?.vc15m?.value && property.vC15 >= propertyObject?.vc15m?.value)) {
              validationCheck = false
            }
          })
          if(validationCheck){
            propertyObject.hs = { value: locality.hs, isExceed: true }
            propertyObject.vc5m = { value: locality.vC5M, isExceed: true }
            propertyObject.vc15m = { value: locality.vC15M, isExceed: true }
          }
        }

        //validate circumference and solidity values 
        if (
          mainComponent?.circumference
        ) {
          const matchingSolidityAndCircumferences = locality.solidityAndCircumferences?.find(sc=>sc.circumference == mainComponent?.circumference)
          if (
            matchingSolidityAndCircumferences &&
            mainComponent?.solidity &&
            mainComponent?.solidity > matchingSolidityAndCircumferences?.solidity
          ) {
            propertyObject.circumferences = { value: mainComponent?.circumference, isExceed: true }
            propertyObject.solidity = { value: mainComponent?.solidity, isExceed: true }
          }
        }
      })
      return propertyObject
}