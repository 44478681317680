import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { CancelButton } from '../components/CancelButton'
import { AppForm, AppFormProps, FormCheckbox, FormFileUpload, FormSubmitButton, FormTextField } from '../components/FormCore'
import { FileType } from '../enums'
import { ICustomer } from '../generated-types'
import { useZodForm } from '../hooks/zod-form'
import { useGetUniqueAdminCustomers } from 'src/api/customer'
import { useGetFile } from 'src/api/file'

const schema = z.object({
  name: z.string().nonempty(),
  image: z.string().optional().nullable(),
  organizationNr: z.string().nonempty(),
  address: z.string().nonempty(),
  zipCode: z.string().nonempty(),
  city: z.string().optional(),
  country: z.string().optional(),
  phone: z.string().optional(),
})

export type CustomerFormType = z.infer<typeof schema>

export const CustomerForm: React.FC<AppFormProps<ICustomer>> = ({ onSubmit, onCancel, initialValues }) => {
  const { t: translate } = useTranslation(['admin-customer', 'common', 'custom-property'])
  const { data } = useGetUniqueAdminCustomers()
  const isUpdateForm = initialValues?.id
  const { data: file } = useGetFile(initialValues?.fileId ?? 0)

  const nameValidation = v => {
    if (isUpdateForm && initialValues?.name == v) {
      return true
    }
    return !data?.find(u => u.name == v)
  }

  const organizationNumberValidation = v => {
    if (isUpdateForm && initialValues?.organizationNr == v) {
      return true
    }
    return !data?.find(u => u.organizationNr == v)
  }

  const customerAddScema = z.object({
    name: z.string()
      .refine(nameValidation, { message: translate('duplicateName', { ns: 'custom-property' }) })
      .optional(),
    image: z.string().optional().nullable(),
    fileId: z.union([z.string(), z.number()]).or(z.null()).optional(),
    organizationNr: z.string().refine(organizationNumberValidation, {
      message: translate('duplicateOrganizationNumber', { ns: 'custom-property' }),
    })
      .optional(),
    address: z.string().nonempty(),
    zipCode: z.string().nonempty(),
    city: z.string().optional(),
    country: z.string().optional(),
    phone: z.string().optional(),
    isGlobalServiceStationProvider: z.boolean().optional()
  })
  const form = useZodForm(customerAddScema, { defaultValues: initialValues })

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={!!initialValues}>
      <FormTextField fullWidth name="name" label={translate('name', { ns: 'common' })} />
      <Box py={1}>
        <FormFileUpload
          label={translate('image', { ns: 'common' })}
          name="fileId"
          mapFileKey="id"
          type={FileType.Image}
          initialFiles={file}
        />
      </Box>
      <FormTextField fullWidth name="organizationNr" label={translate('organizationNumber')} />
      <FormTextField fullWidth name="address" label={translate('address', { ns: 'common' })} />
      <FormTextField fullWidth name="zipCode" label={translate('zipCode', { ns: 'common' })} />
      <FormTextField fullWidth name="city" label={translate('city', { ns: 'common' })} />
      <FormTextField fullWidth name="country" label={translate('country', { ns: 'common' })} />
      <FormTextField fullWidth name="phone" label={translate('phone', { ns: 'common' })} />
      <FormCheckbox name="isGlobalServiceStationProvider" label={translate('isGSSProvider', { ns: 'common' })} />
      <Box className="flex justify-end gap-4 pt-4">
        <FormSubmitButton
          createText={isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        />
        <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
      </Box>
    </AppForm>
  )
}
