import { Typography, useTheme } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { ActivityLogOriginKey, ActivityLogReferenceKey, ActivityType } from 'src/enums'
import { NavigationColumnContainer } from 'src/containers/TopBar/Index.style'
import { useNavigation } from 'src/context/NavigationContext'
import { AppRoutesNavigation } from 'src/app-routes'
import { Flex } from 'src/styles/flexComponent'
import { getFormattedDate } from 'src/utils'
import GenericTable from 'src/components/table/GenericTable'
import { mainComponentsSectionHeight } from '../cage/CageContainer'
import { useGetMainComponentActivityLogs } from 'src/api/activity-log'
import { APIResultCount } from 'src/constant'
import { DynamicTextTypography } from 'src/components/DynamicTextTypography'

export interface IMainComponentHistoriesTableProps {
  customerId: EntityId
  localityId: EntityId
  mainComponentId: EntityId
  mooringId: EntityId
  height?: number
  enablePagination?: boolean
  pop?: () => void
}
export const MainComponentHistoriesTable: React.FC<IMainComponentHistoriesTableProps> = ({
  customerId,
  localityId,
  mainComponentId,
  mooringId,
  height,
  enablePagination,
  pop,
}) => {
  const { t: translate } = useTranslation(['document', 'common', 'equipment'])
  const { navigateTo } = useNavigation()
  const theme = useTheme()

  const { data, isLoading } = useGetMainComponentActivityLogs({
    mainComponentId: mainComponentId,
    page: 0,
    pageSize: APIResultCount.Max,
  })

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'activityType',
        headerName: translate('log-type', { ns: 'activity-logs' }),
        flex: 1,
        renderCell: params => translate(ActivityType[params.row?.activityType], { ns: 'activity-log-type' }),
      },
      {
        field: 'origin',
        headerName: translate('origin', { ns: 'activity-logs' }),
        flex: 1,
        renderCell: params => {
          if (params.value !== null) {
            const originKey = params.value
            return (
              <NavigationColumnContainer
                key={'origin-key'}
                onClick={() => {
                  pop && pop()
                  if (originKey == ActivityLogOriginKey.ServiceStation) {
                    navigateTo(
                      AppRoutesNavigation.serviceStation.serviceStation(customerId, params.row?.serviceStationId),
                    )
                  } else if (originKey == ActivityLogOriginKey.Locality) {
                    navigateTo(AppRoutesNavigation.Localities.locality(customerId, params.row?.localityId))
                  } else if (originKey == ActivityLogOriginKey.Cage) {
                    navigateTo(
                      AppRoutesNavigation.Localities.position(
                        customerId,
                        params.row?.localityId,
                        params.row?.mooringId ?? mooringId, //params.row?.mooringId contains the source mooringId
                        params.row?.positionId,
                      ),
                    )
                  }
                }}
                active={true}
              >
                <Flex.Row justifyContent={'flex-start'} alignItems={'center'}>
                  <Typography variant="h6" fontSize={'14px'}>
                    {originKey == ActivityLogOriginKey.ServiceStation
                      ? params.row?.serviceStationName
                      : originKey == ActivityLogOriginKey.Locality
                      ? params.row?.localityName
                      : originKey == ActivityLogOriginKey.Cage
                      ? params.row?.positionName
                      : '-'}
                  </Typography>
                </Flex.Row>
              </NavigationColumnContainer>
            )
          } else {
            return '-'
          }
        },
      },
      {
        field: 'activityDescription',
        headerName: translate('activity-description', { ns: 'activity-logs' }),
        flex: 1,
        renderCell: params => translate(params.value, { ns: 'activity-log-type-description' }),
      },
      {
        field: 'reference',
        headerName: translate('reference', { ns: 'activity-logs' }),
        flex: 0.75,
        renderCell: params => {
          if (params.value !== null) {
            const referenceKey = params.value
            return (
              <NavigationColumnContainer
                key={'meta-data-navigate-key'}
                onClick={() => {
                  pop && pop()
                  if (referenceKey == ActivityLogReferenceKey.Deviation) {
                    navigateTo(
                      AppRoutesNavigation.Localities.deviation(
                        customerId,
                        localityId,
                        params.row?.metaData['deviationId'],
                      ),
                    )
                  } else if (referenceKey == ActivityLogReferenceKey.ServiceStation) {
                    navigateTo(
                      AppRoutesNavigation.serviceStation.serviceStation(customerId, params.row?.serviceStationId),
                    )
                  } else if (referenceKey == ActivityLogReferenceKey.Locality) {
                    navigateTo(AppRoutesNavigation.Localities.locality(customerId, params.row?.localityId))
                  } else if (referenceKey == ActivityLogReferenceKey.Cage) {
                    navigateTo(
                      AppRoutesNavigation.Localities.position(
                        customerId,
                        params.row?.localityId,
                        mooringId, // ToDo :: need to save this in log and get it back
                        params.row?.positionId,
                      ),
                    )
                  } else if (referenceKey == ActivityLogReferenceKey.Document) {
                    navigateTo(
                      AppRoutesNavigation.Localities.document(
                        customerId,
                        params.row?.localityId,
                        params.row?.metaData['documentId'],
                      ),
                    )
                  } else if (referenceKey == ActivityLogReferenceKey.ForCage) {
                    navigateTo(
                      AppRoutesNavigation.Localities.position(
                        customerId,
                        params.row?.localityId,
                        mooringId,
                        params.row?.metaData['forCageId'],
                      ),
                    )
                  }
                }}
                active={true}
              >
                <Flex.Row justifyContent={'flex-start'} alignItems={'center'}>
                  <Typography variant="h6" fontSize={'14px'}>
                    {referenceKey == ActivityLogReferenceKey.Deviation
                      ? params.row?.metaData['deviationTitle']
                      : referenceKey == ActivityLogReferenceKey.ServiceStation
                      ? params.row?.serviceStationName
                      : referenceKey == ActivityLogReferenceKey.Locality
                      ? params.row?.localityName
                      : referenceKey == ActivityLogReferenceKey.Cage
                      ? params.row?.positionName
                      : referenceKey == ActivityLogReferenceKey.Document
                      ? params.row?.metaData['documentTitle']
                      : referenceKey == ActivityLogReferenceKey.ForCage
                      ? params.row?.metaData['forCageName']
                      : '-'}
                  </Typography>
                </Flex.Row>
              </NavigationColumnContainer>
            )
          } else {
            return '-'
          }
        },
      },
      {
        field: 'logDate',
        headerName: translate('performed', { ns: 'activity-logs' }).toUpperCase(),
        flex: 1,
        renderCell: params => {
          const formattedDate = getFormattedDate(new Date(params.row?.logDate + 'Z'), 'YYYY-MM-DD, h:mm a')
          const performedByUserName = params.row?.performedByUserName || translate('unknown-User', { ns: 'common' })

          return (
            <div>
              <Typography variant="body1" fontWeight={500}>
                {formattedDate}
              </Typography>
              <DynamicTextTypography
                text={performedByUserName}
                maxLength={20}
                variant="caption"
                fontWeight={500}
                color={theme.palette.blueGray[theme.palette.mode]}
              />
            </div>
          )
        },
      },
      {
        field: 'effectiveDate',
        headerName: translate('effective-date', { ns: 'activity-logs' }),
        flex: 1,
        renderCell: params => {
          return params.row?.effectiveDate !== null
            ? getFormattedDate(new Date(params.row?.effectiveDate))
            : getFormattedDate()
        },
      },
    ],
    [customerId, localityId, mooringId, navigateTo, translate],
  )

  return (
    <GenericTable
      id={'main-component-history-table-key'}
      columns={columns}
      data={data}
      loading={isLoading}
      height={height ?? mainComponentsSectionHeight}
      autoHeight={false}
      getRowHeight={() => 44}
      enablePagination={enablePagination ?? true}
      hideDefaultFooterPagination={true}
      enablePaginationButtons={{ showFirstButton: enablePagination ?? true, showLastButton: enablePagination ?? true }}
      pageSize={10}
    />
  )
}
