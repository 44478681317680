import { Link, Stack, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { IMainComponentType } from 'src/enums'
import { MainComponentItem } from '../../dashboard/mainComponent/MainComponentItem'
import { IMainComponentStatus } from 'src/generated-types/main-component-status'
import { useTranslation } from 'react-i18next'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { MainComponentAddComponent } from '../../positions/components/MainComponentAddModal'

interface MainComponentsContainerProps {
  cage: IMainComponentStatus
  textColor?: any
  removePopOver?: boolean
  customerId?: EntityId
  mooringId?: EntityId
  positionId?: EntityId
  localityId?: EntityId
}

export const MainComponentsContainer: FC<MainComponentsContainerProps> = ({
  cage,
  textColor,
  removePopOver = false,
  customerId,
  localityId,
  mooringId,
  positionId,
}) => {
  const theme = useTheme()
  const { t: translate } = useTranslation()
  const { showDialog } = useDialogCtx()

  const onAdd = async () => {
    const added = await showDialog(MainComponentAddComponent, {
      opts: {
        maxWidth: 'sm',
        fullWidth: true,
      },
      componentProps: {
        customerId: customerId as EntityId,
        mooringId: mooringId as EntityId,
        positionId: positionId as EntityId,
        localityId: localityId as EntityId,
      },
    }, undefined, true)
    if (!added) return
  }

  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      gap={1}
      width={'100%'}
      height={'100%'}
      flexWrap={'wrap'}
    >
      {(cage?.nets && cage?.nets?.length > 0) || (cage?.rings && cage?.rings?.length > 0) ? (
        <>
          {cage?.nets?.map((net, index) => (
            <MainComponentItem
              key={index}
              mainComponent={net}
              type={IMainComponentType.Net}
              textColor={textColor}
              removePopOver={removePopOver}
            />
          ))}
          {cage?.rings?.map((ring, index) => (
            <MainComponentItem
              key={index}
              mainComponent={ring}
              type={IMainComponentType.Ring}
              textColor={textColor}
              removePopOver={removePopOver}
            />
          ))}
        </>
      ) : (
        <Stack direction={'column'} marginTop={'-8px'}>
          <Typography
            textOverflow={'flex'}
            flexWrap={'wrap'}
            fontWeight={'400'}
            variant="body2"
            color={theme.palette.secondaryMain[theme.palette.mode]}
          >
            {translate('no-main-components', { ns: 'main-component' })}
          </Typography>
          {localityId && mooringId && positionId && customerId && (
            <Link
              variant="body2"
              fontWeight={'400'}
              component={Typography}
              color={theme.palette.primaryLight[theme.palette.mode]}
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={onAdd}
            >
              {translate('addMainComponent', { ns: 'main-component' })}
            </Link>
          )}
        </Stack>
      )}
    </Stack>
  )
}
