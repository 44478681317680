import { AppRoutesNavigation, AppRoutesRouter } from '../../app-routes'
import { IconType } from 'react-icons'
import {
  AdminPanelSettings,
  ControlCamera,
  DirectionsBoatSharp,
  Functions,
  Group,
  Hub,
  LocationCity,
  LocationOn,
  MarkEmailUnread,
  PeopleOutline,
  Public,
  PunchClockSharp,
  Schema,
  SettingsInputComponent,
  Streetview,
  SupervisedUserCircleRounded,
  SupportAgent,
  SvgIconComponent,
  Task,
  TaskAlt,
  ChargingStation,
  MiscellaneousServices,
  Category,
} from '@mui/icons-material'

export interface IAdminSidebarItems {
  name: string
  path?: (id: EntityId) => string
  icon: SvgIconComponent | IconType
  pathname?: string
  children?: Array<IAdminSidebarItems>
}

export const customerSidebarItems =(isGlobal)=>{
  if(isGlobal){
    return [
        {
          name: 'service-stations',
          path: AppRoutesNavigation.Customer.serviceStations,
          icon: MiscellaneousServices,
          pathname: AppRoutesRouter.Customer.serviceStations,
        },
        {
          name: 'users',
          path: AppRoutesNavigation.Customer.users,
          icon: Group,
          pathname: AppRoutesRouter.Customer.users,
        }
    ] as IAdminSidebarItems[]
  }
  return [
  {
    name: 'facilities',
    icon: LocationOn,
    children: [{
      name: 'localities',
      path: AppRoutesNavigation.Customer.localities,
      icon: LocationCity,
      pathname: AppRoutesRouter.Customer.localities,
    },
    {
      name: 'locality-groups',
      path: AppRoutesNavigation.Customer.localityGroups,
      icon: Streetview,
      pathname: AppRoutesRouter.Customer.localityGroup,
    },
    {
      name: 'service-stations',
      path: AppRoutesNavigation.Customer.serviceStations,
      icon: MiscellaneousServices,
      pathname: AppRoutesRouter.Customer.serviceStations,
    }, {
      name: 'service-station-groups',
      path: AppRoutesNavigation.Customer.serviceStationGroup,
      icon: ChargingStation,
      pathname: AppRoutesRouter.Customer.serviceStationGroup,
    }]
  },
  {
    name: 'access-control',
    icon: ControlCamera,
    children: [
      {
        name: 'users',
        path: AppRoutesNavigation.Customer.users,
        icon: Group,
        pathname: AppRoutesRouter.Customer.users,
      },
      {
        name: 'user-invites',
        path: AppRoutesNavigation.Customer.userInvites,
        icon: MarkEmailUnread,
        pathname: AppRoutesRouter.Customer.userInvites,
      },
      {
        name: 'access-level-group',
        path: AppRoutesNavigation.Customer.accessControls,
        icon: SupportAgent,
        pathname: AppRoutesRouter.Customer.accessControls,
      },
    ],
  },
  {
    name: 'services',
    path: AppRoutesNavigation.Customer.services,
    icon: SupportAgent,
    pathname: AppRoutesRouter.Customer.services,
  },
  {
    name: 'custom-properties',
    path: AppRoutesNavigation.Customer.customProperty,
    icon: Functions,
    pathname: AppRoutesRouter.Customer.customProperty,
  },
  {
    name: 'document-category',
    path: AppRoutesNavigation.Customer.documentCategories,
    icon: Category,
    pathname: AppRoutesRouter.Customer.documentCategories,
  },
  {
    name: 'main-component-types',
    path: AppRoutesNavigation.Customer.mainComponentTypes,
    icon: SettingsInputComponent,
    pathname: AppRoutesRouter.Customer.mainComponentTypes,
  },
  {
    name: 'form-schema',
    path: AppRoutesNavigation.Customer.formSchema,
    icon: Schema,
    pathname: AppRoutesRouter.Customer.formSchemas,
  },
  {
    name: 'main-components',
    path: AppRoutesNavigation.Customer.mainComponents,
    icon: Hub,
    pathname: AppRoutesRouter.Customer.mainComponents,
  },
  {
    name: 'tasks',
    icon: Task,
    children: [
      {
        name: 'tasks',
        path: AppRoutesNavigation.Customer.tasks,
        icon: TaskAlt,
        pathname: AppRoutesRouter.Customer.tasks,
      },
      {
        name: 'resources',
        path: AppRoutesNavigation.Customer.resources,
        icon: DirectionsBoatSharp,
        pathname: AppRoutesRouter.Customer.resources,
      },
      {
        name: 'time-reports',
        path: AppRoutesNavigation.Customer.timeReports,
        icon: PunchClockSharp,
        pathname: AppRoutesRouter.Customer.timeReports,
      },
    ],
  },
  {
    name: 'global-documents',
    icon: Public,
    children: [
      {
        name: 'admin',
        path: AppRoutesNavigation.Customer.globalDocumentsAdmin,
        icon: AdminPanelSettings,
        pathname: AppRoutesRouter.Customer.globalDocumentsAdmin,
      },
      {
        name: 'customer',
        path: AppRoutesNavigation.Customer.globalDocumentsCustomer,
        icon: SupervisedUserCircleRounded,
        pathname: AppRoutesRouter.Customer.globalDocumentsCustomer,
      },
    ],
  },
  {
    name: 'suppliers',
    icon: PeopleOutline,
    path: AppRoutesNavigation.Customer.suppliers,
    pathname: AppRoutesRouter.Customer.suppliers,
  },
] as IAdminSidebarItems[] }
