import { activityLogQueryKey } from 'src/query-keys'
import { queryClient } from '../query-client'
import { useDelete, useGet, usePost } from './reactQuery'
import { IActivityLogs, ICageActivityLog, ICreateCustomCageLog } from 'src/generated-types/activity-logs'
import { IPaginatedResult } from './paginated-result'

const activityLogBaseURL = () => 'activity-logs'

export const activityLogEndpoints = {
  getCageActivityLogs() {
    return `${activityLogBaseURL()}/get-all-cage-activity-logs`
  },
  getMainComponentActivityLogs() {
    return `${activityLogBaseURL()}/get-main-component-activity-logs`
  },
  createCustomCageLog() {
    return `${activityLogBaseURL()}/create-cage-custom-log`
  },
  deleteCustomActivityLog() {
    return `${activityLogBaseURL()}/delete-custom-activity-log`
  },
}

export const invalidationActivityLogCache = {
  getCageActivityLogs(queryParams?: object) {
    queryClient.invalidateQueries(activityLogQueryKey.GET_ACTIVITY_LOGS(queryParams))
  },
}

export const useGetCageActivityLogs = (queryParams?: { positionId: EntityId; page: number; pageSize: number }) =>
  useGet<IPaginatedResult<ICageActivityLog>>(
    activityLogEndpoints.getCageActivityLogs(),
    {
      ...queryParams,
    },
    {
      queryKey: activityLogQueryKey.GET_ACTIVITY_LOGS({
        positionId: queryParams?.positionId,
        page: queryParams?.page,
        pageSize: queryParams?.pageSize,
      }),
    },
  )

export const useGetMainComponentActivityLogs = (queryParams?: {
  mainComponentId: EntityId
  page: number
  pageSize: number
}) =>
  useGet<Array<IActivityLogs>>(
    activityLogEndpoints.getMainComponentActivityLogs(),
    {
      ...queryParams,
    },
    { queryKey: activityLogQueryKey.GET_ACTIVITY_LOGS({ page: queryParams?.page, pageSize: queryParams?.pageSize, mainComponentId: queryParams?.mainComponentId }) },
  )

export const useAddCustomCageLog = () =>
  usePost<ICageActivityLog, ICreateCustomCageLog>(activityLogEndpoints.createCustomCageLog())

export const useDeleteActivityLog = () =>
  useDelete<IActivityLogs>(activityLogEndpoints.deleteCustomActivityLog())
