import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import {
  invalidatePositionDrawingCache,
  positionDrawingEndpoints,
  useDeletePositionDrawing,
  useGetPositionDrawing,
  useUpdatePositionDrawing,
} from '../../../api/position-drawing'
import { FileUrlLoader } from '../../../components/FileUploader'
import { CenteredSpinner } from '../../../components/Spinner'
import { IPositionDrawing } from '../../../generated-types/position-drawing'
import { IPositionDrawingProduct } from '../../../generated-types/position-drawing-product'
import { ShowDialogProps } from '../../../show-dialog'
import { PositionDrawingForm } from './positionDrawingForm'
import { toast } from 'react-toastify'
import { useDialogCtx } from 'src/hooks/context-hooks'
import GenericTable from 'src/components/table/GenericTable'
import { IPositionType } from 'src/generated-types'

export interface UpdatePositionDrawingProps extends ShowDialogProps {
  id: EntityId
}

const UpdatePositionDrawingDialog: React.FC<UpdatePositionDrawingProps> = ({ pop, id }) => {
  const { t } = useTranslation(['admin-position-drawing', 'common'])

  const mutation = useUpdatePositionDrawing(id as EntityId)

  const onSubmit = useCallback(
    async form => {
      if (
        !Array.isArray(form.positionDrawingProducts) ||
        form.positionDrawingProducts.length === 0 ||
        form.positionDrawingProducts.some(x => x.quantity === undefined || x.quantity < 1) ||
        form.positionDrawingProducts.some(x => x.positionDrawingNumber === undefined || x.positionDrawingNumber <= 0)
      ) {
        toast(t('drawingPositionProductValidationError', { ns: 'admin-position-drawing' }), { type: 'error' })
        return
      }
      await mutation.mutateAsync(form, {
        onSuccess: () => {
          pop(true)
          invalidatePositionDrawingCache.getPositionDrawingById(id)
          invalidatePositionDrawingCache.useGetPositionDrawings()
        },
      })
    },
    [id],
  )

  const { data, isLoading } = useGetPositionDrawing(id)
  if (isLoading) return <CenteredSpinner />

  return (
    <PositionDrawingForm
      title={t('updatePositionDrawing')}
      onSubmit={onSubmit}
      onCancel={() => pop()}
      initialValues={data as IPositionDrawing}
    />
  )
}

export const PositionDrawingTable = () => {
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const { t } = useTranslation(['admin-position-drawing', 'common'])

  const STATIC_COLUMNS: GridColumns = [
    { field: 'drawingNumber', headerName: t('drawingNumber'), flex: 1 },
    {
      field: 'category',
      headerName: t('category', { ns: 'common' }),
      flex: 1,
      valueGetter: ({ value }) => t(IPositionType[value], { ns: 'position' }),
    },
  ]

  const { mutate, isLoading: isDeleting, isSuccess, error } = useDeletePositionDrawing()

  useEffect(() => {
    if (isSuccess) {
      invalidatePositionDrawingCache.useGetPositionDrawings()
    }
  }, [isSuccess])

  useEffect(() => {
    if (error && error['data'] == 'PositionDrawingHasConnectedToAPosition') {
      toast(t('positionDrawingHasConnectedToAPosition'), { type: 'error' })
    }
  }, [error])

  const onDelete = useCallback(
    async (id: EntityId) =>
      showConfirmDialog(
        t('deletePositionDrawing', { ns: 'admin-position-drawing' }),
        t('areYouSureDeletePositionDrawing', { ns: 'admin-position-drawing' }),
        {
          acceptText: t('yes', { ns: 'common' }),
          cancelText: t('no', { ns: 'common' }),
        },
        async pop => {
          await mutate(id, {
            onSuccess: () => {
              toast.success(t('deletePositionDrawingSuccess', { ns: 'admin-position-drawing' }))
              pop()
            },
          })
        },
      ),
    [],
  )

  const onEdit = async (id: EntityId) => {
    const updated = await showDialog(
      UpdatePositionDrawingDialog,
      {
        componentProps: { id },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!updated) return
  }

  const columns: GridColumns = useMemo(
    () => [
      {
        headerName: t('file', { ns: 'common' }),
        flex: 1,
        field: 'fileUrl',
        renderCell: params => (!params.value ? '' : <FileUrlLoader fileUrl={params.value} openOnClick />),
      },
      ...STATIC_COLUMNS,
      {
        headerName: t('products', { ns: 'common' }),
        flex: 1,
        field: 'positionDrawingProducts',
        valueFormatter: params =>
          !params.value
            ? ''
            : (params.value as IPositionDrawingProduct[]).map(({ productNumber }) => productNumber).join(', '),
      },
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label={t('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onEdit(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={t('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDelete(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [],
  )

  return (
    <GenericTable
      id={'position-drawing-table'}
      queryUrl={positionDrawingEndpoints.getPositionDrawings()}
      onRowClick={row => {
        onEdit(row.id)
      }}
      enableServerPagination
      columns={columns}
      autoHeight={false}
      height={'calc(100vh - 185px)'}
    />
  )
}
