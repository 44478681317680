import { ICluster } from 'src/generated-types/cluster'
import { ILocality, IPosition, IPositionType } from '../../../../generated-types'
import { getMedian } from 'src/utils'

export function toRad(n: number) {
  return (n * Math.PI) / 180
}

export function toDeg(n: number) {
  return (n * 180) / Math.PI
}

export function getBearing(startLat: number, startLong: number, endLat: number, endLong: number) {
  startLat = toRad(startLat)
  startLong = toRad(startLong)
  endLat = toRad(endLat)
  endLong = toRad(endLong)

  let dLong = endLong - startLong

  const dPhi = Math.log(Math.tan(endLat / 2.0 + Math.PI / 4.0) / Math.tan(startLat / 2.0 + Math.PI / 4.0))
  if (Math.abs(dLong) > Math.PI) {
    if (dLong > 0.0) dLong = -(2.0 * Math.PI - dLong)
    else dLong = 2.0 * Math.PI + dLong
  }

  return (toDeg(Math.atan2(dLong, dPhi)) + 360.0) % 360.0
}

export function getDistance(lat1, lon1, lat2, lon2): number {
  const p = 0.017453292519943295 // Math.PI / 180
  const c = Math.cos
  const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2
  const result = 12742 * Math.asin(Math.sqrt(a)) * 1000
  return +result.toFixed(2) // 2 * R R = 6371 m
}

export function destVincenty(lat1: number, lon1: number, brng: number, dist: number) {
  const a = 6378137,
    b = 6356752.3142,
    f = 1 / 298.257223563, // WGS-84 ellipsiod
    s = dist,
    alpha1 = toRad(brng),
    sinAlpha1 = Math.sin(alpha1),
    cosAlpha1 = Math.cos(alpha1),
    tanU1 = (1 - f) * Math.tan(toRad(lat1)),
    cosU1 = 1 / Math.sqrt(1 + tanU1 * tanU1),
    sinU1 = tanU1 * cosU1,
    sigma1 = Math.atan2(tanU1, cosAlpha1),
    sinAlpha = cosU1 * sinAlpha1,
    cosSqAlpha = 1 - sinAlpha * sinAlpha,
    uSq = (cosSqAlpha * (a * a - b * b)) / (b * b),
    A = 1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq))),
    B = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)))

  let sigma, sigmaP, sinSigma, cosSigma, cos2SigmaM, deltaSigma

  sigma = s / (b * A)
  sigmaP = 2 * Math.PI

  while (Math.abs(sigma - sigmaP) > 1e-12) {
    cos2SigmaM = Math.cos(2 * sigma1 + sigma)
    sinSigma = Math.sin(sigma)
    cosSigma = Math.cos(sigma)
    deltaSigma =
      B *
      sinSigma *
      (cos2SigmaM +
        (B / 4) *
          (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
            (B / 6) * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)))
    sigmaP = sigma
    sigma = s / (b * A) + deltaSigma
  }

  const tmp = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1,
    lat2 = Math.atan2(
      sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1,
      (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp),
    ),
    lambda = Math.atan2(sinSigma * sinAlpha1, cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1),
    C = (f / 16) * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha)),
    L =
      lambda -
      (1 - C) *
        f *
        sinAlpha *
        (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM))),
    revAz = Math.atan2(sinAlpha, -tmp) // final bearing
  return [
    parseFloat(toDeg(lat2).toString()).toFixed(6),
    (parseFloat(lon1.toString()) + parseFloat(toDeg(L).toString())).toFixed(6),
  ]
}

export function midpoint(lat1: number, long1: number, lat2: number, long2: number, per: number) {
  return [lat1 + (lat2 - lat1) * per, long1 + (long2 - long1) * per]
}

export function triangleCentroid(triangle: number[][]) {
  if (triangle.length !== 3) {
    throw new TypeError('must provide triangle array of length 3')
  }

  const dimension = triangle[0].length
  const result = new Array(dimension)
  for (let i = 0; i < dimension; i++) {
    const t0 = triangle[0][i]
    const t1 = triangle[1][i]
    const t2 = triangle[2][i]
    result[i] = (t0 + t1 + t2) / 3
  }
  return result
}

export const getPositionName = (position: IPosition) => {
  if (position.type === IPositionType.Cage) {
    return position?.reference ?? `M${parseInt(position.name) - 900}`
  }

  if (position.reference && position.reference !== '') {
    return position.reference
  } else if (position.name && position.name !== '') {
    return position.name
  } else {
    return position.id
  }
}

export const getClusterArray = (localities: ILocality[], clusterRange: number) => {
  const clusterArray: ICluster[] = []

  localities.map(locality => {
      const mooring = locality.moorings[0]
      if (mooring && mooring.positions[0] ) {
        const positionLon = mooring.positions[0]?.longitude
        const positionLat = mooring.positions[0]?.latitude
        const existingClusterIndex = clusterArray.findIndex(c =>
          isInsideCluster(c, positionLon, positionLat, clusterRange),
        )

        //assign to exists cluster to this mooring
        if (existingClusterIndex != -1) {
          const existingCluster = clusterArray[existingClusterIndex]
          existingCluster.longitude.push(positionLon)
          existingCluster.latitude.push(positionLat)
          existingCluster.number += 1
          existingCluster.hasDeviation = existingCluster.hasDeviation == true ? true : mooring.deviationsCount > 0
          existingCluster.localities.push(locality)
          clusterArray[existingClusterIndex] = existingCluster

        } else {
          //create new cluster if not exists on this location
          const newCluster: ICluster = {
            longitude: [positionLon],
            latitude: [positionLat],
            hasDeviation: mooring.deviationsCount > 0,
            number: 1,
            localities: [locality],
          }
          clusterArray.push(newCluster)
        }
      }
  })

  //Existing cluster not have more than 2 moorings then check with other clusters
  const notClusterArray = clusterArray.filter(c => c.number <= 2)
  notClusterArray.map(cluster => {
    cluster.localities.map(locality => {
      const mooring = locality.moorings[0]
      if(mooring){
      const positionLon = mooring.positions[0]?.longitude
        const positionLat = mooring.positions[0]?.latitude
        const existingClusterIndex = clusterArray.findIndex(
          c =>{
            return !c.latitude.includes(positionLat) &&
            c.localities.length &&
            isInsideCluster(c, positionLon, positionLat, clusterRange)
          }
        )
      
        if (existingClusterIndex != -1) {
          const existingCluster = clusterArray[existingClusterIndex]
          existingCluster.longitude.push(positionLon)
          existingCluster.latitude.push(positionLat)
          existingCluster.number += 1
          existingCluster.hasDeviation = existingCluster.hasDeviation == true ? true : mooring.deviationsCount > 0
          existingCluster.localities.push(locality)

          clusterArray[existingClusterIndex] = existingCluster
        }
      }
    })
  })

  return clusterArray.filter(c => c.number > 2)
}

const isInsideCluster = (cluster: ICluster, positionLon: number, positionLat: number, clusterRange: number) => {
  return (
    Math.abs(getMedian(cluster.latitude) - positionLat) <= clusterRange &&
    Math.abs(getMedian(cluster.longitude) -positionLon) <= clusterRange
  )
}
