import L from 'leaflet'
import { useMemo } from 'react'
import { CircleMarker, Marker, useMap } from 'react-leaflet'
import { ICluster } from 'src/generated-types/cluster'
import { getMidPoint } from 'src/utils'

interface IClusterLayer {
  cluster: ICluster
}

const ClusterLayer = ({ cluster }: IClusterLayer) => {
  const color = cluster.hasDeviation ? '#D32F2F' : '#00536c'
  const map = useMap()

  const latitude = useMemo(() => getMidPoint(cluster.latitude), [cluster])
  const longitude = useMemo(() => getMidPoint(cluster.longitude), [cluster])

  const onClusterClick = () => {
    map.setView([latitude, longitude], 9, { duration: 1000, animate: true })
  }

  return (
    <>
      <CircleMarker
        fillColor={color}
        center={[latitude, longitude]}
        stroke={false}
        radius={40}
        fillOpacity={0.2}
        eventHandlers={{ click: () => onClusterClick() }}
      >
        <CircleMarker fillOpacity={0.4} fillColor={color} center={[latitude, longitude]} stroke={false} radius={32}>
          <CircleMarker
            fillOpacity={0.6}
            fillColor={color}
            center={[latitude, longitude]}
            stroke={false}
            radius={25}
          ></CircleMarker>
        </CircleMarker>
      </CircleMarker>
      <Marker
        eventHandlers={{ click: () => onClusterClick() }}
        position={[latitude, longitude]}
        icon={L.divIcon({
          html: `<p style="color:white;background-color:transparent;font-size:16px;margin-top:-13px;margin-left:-5px;font-weight:400;">${cluster.number}</p>`,
          iconSize: [0, 0],
        })}
      />
    </>
  )
}

export default ClusterLayer
