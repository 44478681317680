import { useTranslation } from 'react-i18next'
import { LayerGroup, LayersControl } from 'react-leaflet'
import { AquacultureRegistry } from '../AquacultureRegistry'
import { ExtremePoints } from '../ExtremePoints'
import { IGroupSelector } from '../MapComponent'

export const AquacultureLayers: React.FC<{ groupSelector: IGroupSelector }> = ({ groupSelector }) => {
  const { t } = useTranslation(['map', 'common'])
  return (
    <>
      <LayersControl.Overlay name={`<b><u>${t('aquaculture-registry')}</u></b>`} checked={groupSelector.aquaculture}>
        <LayerGroup></LayerGroup>
      </LayersControl.Overlay>
      <LayersControl.Overlay name={t('aquaculture-register')} checked={groupSelector.aquaculture}>
        <AquacultureRegistry />
      </LayersControl.Overlay>
      <LayersControl.Overlay name={t('extreme-points')} checked={groupSelector.aquaculture}>
        <ExtremePoints />
      </LayersControl.Overlay>
    </>
  )
}
