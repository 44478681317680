import { Box, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ShowDialogProps } from '../../../../show-dialog'
import { AddLocalityToServiceForm } from './AddLocalityToServiceForm'
import { useAddMooringToService } from '../../../../api/locality-service'
import { invalidateServiceCache } from '../../../../api/service'
import { invalidateLocalityFrequencyCache } from '../../../../api/locality-frequency'
import { invalidateMooringCache } from '../../../../api/moorings'
import { ServiceMooringTable } from './ServiceMooringTable'
import { invalidateLocalityCache } from 'src/api/localities'
import { toast } from 'react-toastify'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { AddCircleOutline } from '@mui/icons-material'
import { useDialogCtx } from 'src/hooks/context-hooks'

interface ServiceMooringContainerProps {
  serviceId: EntityId
}

interface CreateMooringServiceProps extends ShowDialogProps {
  customerId: EntityId
  serviceId: EntityId
}

const AddMooringToServiceDialog: React.FC<CreateMooringServiceProps> = ({ pop, customerId, serviceId }) => {
  const { t: translate } = useTranslation(['customer-locality', 'common'])

  const { mutateAsync, isLoading } = useAddMooringToService(customerId, serviceId)

  const onSubmit = async form => {
    await mutateAsync(form, {
      onSuccess: () => {
        toast.success(translate('mooringAddedSuccessfully', { ns: 'mooring-service' }))
        invalidateServiceCache.getServices(customerId)
        invalidateMooringCache.getMooringsForService(customerId, serviceId)
        invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId, form?.localityId)
        invalidateLocalityCache.getLocalitiesWithInfoCount(customerId as EntityId)
        pop()
      },
      onError: _ => {
        toast.error(translate('deletingError', { ns: 'common' }))
      },
    })
  }

  return (
    <AddLocalityToServiceForm
      onSubmit={form => {
        onSubmit(form)
      }}
      onCancel={() => pop()}
      customerId={customerId}
      serviceId={serviceId}
      title={translate('addMooring', { ns: 'mooring-service' })}
      isAddButtonDisabled={isLoading}
    />
  )
}

export const ServiceMooringContainer = ({ serviceId }: ServiceMooringContainerProps) => {
  const { t: translate } = useTranslation(['mooring-service', 'common'])
  const { id: customerId } = useParams()
  const theme = useTheme()
  const { showDialog } = useDialogCtx()

  const onAddMooring = async () => {
    const added = await showDialog(
      AddMooringToServiceDialog,
      {
        componentProps: {
          customerId: customerId as EntityId,
          serviceId: serviceId as EntityId,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  return (
    <CommonPageLayout
      titleSection={{ title: translate('moorings', { ns: 'mooring-service' }) }}
      topAction={[
        {
          buttonText: translate('addMooring', { ns: 'mooring-service' }),
          buttonIcon: <AddCircleOutline />,
          onClick: onAddMooring,
          variant: 'outlined',
          shadowWeight: null,
          border: 0,
        },
      ]}
    >
      <Box padding={'8px'} bgcolor={getThemeColor(theme, MUIThemeColors.white)} borderRadius={'4px'}>
        <ServiceMooringTable serviceId={serviceId as EntityId} customerId={customerId as EntityId} />
      </Box>
    </CommonPageLayout>
  )
}
