import { useTheme } from '@mui/material/styles'
import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import enlargeIcon from 'src/assets/svg/IconFullscreen.svg'

export const EnlargeComponent: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const theme = useTheme()
  const { t } = useTranslation(['common'])
  const context = useLeafletContext()
  const container = context.layerContainer || context.map
  const enlargeButton = new L.Control({ position: 'topright' })
  enlargeButton.onAdd = function () {
    const div = L.DomUtil.create('div', 'leaflet-control-layers leaflet-control')
    div.innerHTML = `<img style="width: 45px; padding: 12px; cursor: pointer;" src="${enlargeIcon}"/>`
    div.style.backgroundColor = theme.palette.secondaryLight[theme.palette.mode]
    div.style.borderRadius = '4px'
    div.style.border = 'none'
    div.onclick =
      onClick ??
      (() => {
        toast(t('export-error'), { type: 'error' })
      })
    return div
  }
  useEffect(() => {
    enlargeButton.addTo(container as any)
  }, [])
  return <></>
}
