import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { AppForm, AppFormProps, FormFileCoreUpload, FormSelectField, FormTextField } from '../../../components/FormCore'
import { FileType } from '../../../enums'
import { IPositionType } from '../../../generated-types'
import { IPositionDrawing } from '../../../generated-types/position-drawing'
import { IPositionDrawingProduct } from '../../../generated-types/position-drawing-product'
import { useZodForm } from '../../../hooks/zod-form'
import { ProductGrid } from './components/productGridComponent'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { useEffect } from 'react'
import { useGetFile } from '../../../api/file'
import { MainButton } from 'src/components/MainButton'

export interface PositionDrawingForm extends AppFormProps {
  id?: EntityId
  title: string
}

const positionTypes = Object.keys(IPositionType)
  .filter(v => isNaN(Number(v)))
  .map(t => IPositionType[t])

export const PositionDrawingForm: React.FC<PositionDrawingForm> = ({ onSubmit, onCancel, initialValues, title }) => {
  const { t: translate } = useTranslation(['admin-position-drawing', 'common'])
  const positionDrawing = initialValues as IPositionDrawing
  const isUpdateForm = positionDrawing?.id
  const { data: file } = useGetFile(initialValues?.fileId)

  const schema = z.object({
    id: z.number().default(0),
    drawingNumber: z.string().nonempty(translate('enterValidDrawingNumber')).default(''),
    fileId: z.union([z.string(), z.number()]),
    mooringId: z.number().or(z.null()).optional(),
    category: z.number({
      required_error: translate('selectCategory'),
    }),
    positionDrawingProducts: z.array(z.any()).default([] as IPositionDrawingProduct[]),
  })

  const form = useZodForm(schema, { defaultValues: { ...positionDrawing, mooringId: initialValues?.mooringId } })

  useEffect(() => {
    const currentFormValues = form.getValues()

    if (initialValues != undefined && initialValues != null) {
      currentFormValues.positionDrawingProducts = initialValues.positionDrawingProducts
    }

    form.reset(currentFormValues)
  }, [form, initialValues])

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      actions={[
        <MainButton
          key={'action-btn-position-drawing'}
          variant="contained"
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
        >
          {isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        </MainButton>,
      ]}
      removedCloseButton={false}
    >
      <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues}>
        {isUpdateForm && <FormTextField name="id" sx={{ display: 'none' }} />}
        <FormTextField name="drawingNumber" label={translate('drawingNumber')} />
        <FormFileCoreUpload
          label={translate('fileName', { ns: 'common' })}
          name="fileId"
          mapFileKey="id"
          type={FileType.Image}
          initialFiles={file}
          height="125px"
        />
        <FormSelectField
          name="category"
          data={positionTypes.filter(item => item !== IPositionType.Cage)}
          label={translate('category', { ns: 'common' })}
          getOptionLabel={option => translate(IPositionType[option], { ns: 'position' })}
          renderOption={(props: any, option) => (
            <Box {...props} key={option}>
              {translate(IPositionType[option], { ns: 'position' })}
            </Box>
          )}
          defaultValue={positionTypes[initialValues?.category ?? IPositionType.FrameLines]}
        />
        <Box py={1}>
          <ProductGrid />
        </Box>
      </AppForm>
    </GenericDialogLayout>
  )
}
