import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

export const BoatController: React.FC<{ onClick?: () => void; enable: boolean }> = ({ onClick, enable }) => {
  const { t } = useTranslation(['common'])
  const context = useLeafletContext()
  const container = context.layerContainer || context.map
  const boatController = new L.Control({ position: 'topleft' })

  boatController.onAdd = function () {
    const div = L.DomUtil.create('div', 'leaflet-control-layers leaflet-control')
    div.innerHTML = `<img style="width: 30px; height:30px; cursor: pointer;padding:4px 4px;color:red;background-color:#ffffff;" 
    src="/icons/boat.svg"/>`
    div.onclick =
      onClick ??
      (() => {
        toast('Not implemented yet', { type: 'info' })
      })
    return div
  }

  useEffect(() => {
    boatController.addTo(container as any)
  }, [])

  return <></>
}
