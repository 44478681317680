import { useLeafletContext } from '@react-leaflet/core'
import L from 'leaflet'
import 'leaflet.heat'
import { memo, useEffect } from 'react'

export const HeatLayer: React.FC<{ deviationPositions: number[][] | any; layerRadius?: number }> = memo(({
  deviationPositions,
  layerRadius = 45,
}) => {
  const context = useLeafletContext()
  const container = context.layerContainer || context.map
  useEffect(() => {
    if (deviationPositions.length !== 0) {
      const heatLayer = (L as any).heatLayer(deviationPositions, { radius: layerRadius, relative: false })
      heatLayer.addTo(container)
    }
  }, [deviationPositions])
  return <></>
})
