import { ILocality } from 'src/generated-types'
import { Marker, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import locationIcon from 'src/assets/svg/location.svg'
import { useMemo } from 'react'
import { ICluster } from 'src/generated-types/cluster'
import locationDeviationIcon from 'src/assets/svg/location_deviation.svg'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from 'src/app-routes'

interface Props {
  locality: ILocality
  clusters?: ICluster[]
}

const LocalityMarker = (props: Props) => {
  const { locality, clusters } = props
  const navigate = useNavigate()

  const isCluster = useMemo(() => {
    if (clusters && clusters?.length > 0) {
      const insideClusterIndex = clusters.findIndex(c => c.localities.findIndex(l => l.id == locality.id) != -1)

      if (insideClusterIndex != -1) {
        return true
      }
    }

    return false
  }, [clusters, locality])

  const hasDeviations = useMemo(() => {
    const insideClusterIndex = locality.moorings.findIndex(c => c.deviationsCount > 0)

    if (insideClusterIndex != -1) {
      return true
    }
    return false
  }, [locality])

  const circleEvent = useMemo(() => {
    return {
      click() {
        navigate(AppRoutesNavigation.Localities.locality(locality.customerId, locality.id))
      },
    }
  }, [locality])

  return (
    <>
      {!isCluster && locality?.moorings && locality?.moorings?.[0] && locality?.moorings?.[0]?.positions?.[0] ? (
        <Marker
          eventHandlers={circleEvent}
          position={[locality.moorings[0].positions[0].latitude, locality.moorings[0].positions[0].longitude]}
          icon={L.icon({
            iconUrl: hasDeviations ? locationDeviationIcon : locationIcon,
            iconSize: [25, 38],
          })}
        >
          <Tooltip direction={'top'} offset={[0, -10]}>
            {locality?.name}
          </Tooltip>
        </Marker>
      ) : (
        <></>
      )}
    </>
  )
}

export default LocalityMarker
