import { Box } from '@mui/material'
import { useGetCustomersLocalitiesForMap } from '../../api/customer'
import { CenteredSpinner } from '../../components/Spinner'
import { ILocality } from '../../generated-types'
import LocalityLandingMap from './components/localityMap/LocalityLadingMap'

export const LocalitiesMapComponent: React.FC<{ selectedCustomer: EntityId; searchValue: string }> = ({
  selectedCustomer,
  searchValue,
}) => {
  const { data, isLoading } = useGetCustomersLocalitiesForMap(searchValue, selectedCustomer)
  return (
    <Box height={((window.innerHeight - 112) * 2) / 3}>
      {isLoading && <CenteredSpinner />}
      {data && (
        <LocalityLandingMap
          localities={data as ILocality[]}
          key={'locality-main-page-map'}
          customUrl={'https://tile.openstreetmap.org/{z}/{x}/{y}.png'}
        />
      )}
    </Box>
  )
}
