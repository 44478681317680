import { LinearProgress, Stack, StackProps, Typography, useTheme } from '@mui/material'
import { CertificationValidationPeriod } from '../../../../enums'
import { ReactComponent as Clock } from '../../../../assets/svg/clock.svg'
import { ReactComponent as ClockWarning } from '../../../../assets/svg/clock-warning.svg'
import { IMainComponentStatus } from '../../../../generated-types/main-component-status'
import { formatDaysToText } from '../../../../utils'
import { useTranslation } from 'react-i18next'
import { GetRGBA } from '../../../../utils'
import { MainComponentItem } from '../../dashboard/mainComponent/MainComponentItem'
import { IMainComponent } from 'src/generated-types/main-component'

interface MainComponentProgressProps extends StackProps {
  cage: IMainComponentStatus
  status: CertificationValidationPeriod
  textColor?: any
  selectedMainComponent?: IMainComponent
}
export const MainComponentProgress: React.FC<MainComponentProgressProps> = ({
  cage,
  status,
  textColor,
  selectedMainComponent,
  ...rest
}) => {
  const { t: translate } = useTranslation(['main-component'])
  const theme = useTheme()

  const iconColor =
    status != CertificationValidationPeriod.NA
      ? status === CertificationValidationPeriod.LOW
        ? theme.palette.errorMain[theme.palette.mode]
        : theme.palette.primaryMain[theme.palette.mode]
      : theme.palette.primaryMain[theme.palette.mode]

  const progressColor =
    status != CertificationValidationPeriod.NA
      ? status === CertificationValidationPeriod.LOW
        ? 'errorMain'
        : 'primaryMain'
      : 'secondaryMain'

  return (
    <Stack width={'100%'} justifyContent={'flex-start'} gap={0.5} {...rest}>
      <LinearProgress
        sx={{
          backgroundColor: GetRGBA(theme.palette.black[theme.palette.mode], 0.25),
        }}
        variant="determinate"
        color={progressColor}
        value={cage?.certificateValidationTime ?? 0}
      />
      <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} columnGap={'8px'}>
        {selectedMainComponent && (
          <MainComponentItem
            mainComponent={selectedMainComponent}
            type={selectedMainComponent?.type?.componentType}
            removePopOver
            itemTextProps={{ variant: 'body2' }}
          />
        )}
        {selectedMainComponent && <Typography>|</Typography>}
        {status != CertificationValidationPeriod.LOW ? (
          <Clock color={textColor ? textColor : iconColor} />
        ) : (
          <ClockWarning color={textColor ? textColor : iconColor} />
        )}
        <Typography
          variant="body2"
          fontWeight={500}
          color={
            textColor
              ? textColor
              : status === CertificationValidationPeriod.LOW
              ? theme.palette.errorMain[theme.palette.mode]
              : theme.palette.primaryMain[theme.palette.mode]
          }
        >
          {(cage?.nets && cage?.nets.length != 0) ||
          (cage?.rings &&
            cage?.rings?.length != 0 &&
            ((cage?.rings?.concat(cage?.nets ?? []) ?? [])?.map(ring => ring.certificates) ?? [])
              .flat()
              .filter(x => x?.isValid == true).length > 0)
            ? cage?.certificateValidationTime != undefined &&
              cage?.certificateValidationTime >= 0 &&
              cage?.daysLeft != 0
              ? `${formatDaysToText({ days: cage?.daysLeft })} ${translate('left', { ns: 'common' })}`
              : translate('expired', { ns: 'common' })
            : translate('n/a', { ns: 'common' })}
        </Typography>
      </Stack>
    </Stack>
  )
}
