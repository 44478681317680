import { LatLngExpression } from 'leaflet'
import { Polygon, useMap, useMapEvent } from 'react-leaflet'
import { IPosition, IPositionType } from '../../../../../generated-types'
import { fleetOptions, minZoom, opacity } from '../constraints'
import { memo, useEffect, useMemo, useState } from 'react'

export const Fleet: React.FC<{ positions: IPosition[] }> = memo(({ positions }) => {
  const map = useMap()
  const [zoomLevel, setZoomLevel] = useState(Math.round(map.getZoom()))

  useEffect(() => {
    setZoomLevel(Math.floor(map.getZoom()))
  }, [map.getZoom()])

  const handleZoomEnd = event => {
    const newZoomLevel = event.target.getZoom()

    setZoomLevel(Math.round(newZoomLevel))
  }
  useMapEvent('zoomend', handleZoomEnd)

  const pathOptions = {
    weight: zoomLevel - minZoom <= 0 ? 1 : zoomLevel - minZoom,
    fillOpacity: opacity,
    stroke: true,
    fillColor: fleetOptions.fleetColor,
  }

  const cornersLatsAndLongs = useMemo(() => {
    return (
      positions.filter(function (x) {
        return parseInt(x.name) > 500 && parseInt(x.name) < 599
      }).length !== 0
    )
  }, [positions])

  if (!cornersLatsAndLongs) {
    const cornersPositions = positions
      .filter(function (x) {
        return x.type === IPositionType.Corner
      })
      .sort((a, b) => parseInt(a.name) - parseInt(b.name))

    let latLongs: number[][] = []

    cornersPositions.sort((a, b) => parseInt(a.name) - parseInt(b.name))
    if (cornersPositions.length === 4) {
      latLongs = [
        [cornersPositions[0].latitude, cornersPositions[0].longitude],
        [cornersPositions[1].latitude, cornersPositions[1].longitude],
        [cornersPositions[3].latitude, cornersPositions[3].longitude],
        [cornersPositions[2].latitude, cornersPositions[2].longitude],
      ]
    }
    if (cornersPositions.length === 6) {
      latLongs = [
        [cornersPositions[0].latitude, cornersPositions[0].longitude],
        [cornersPositions[1].latitude, cornersPositions[1].longitude],
        [cornersPositions[3].latitude, cornersPositions[3].longitude],
        [cornersPositions[5].latitude, cornersPositions[5].longitude],
        [cornersPositions[4].latitude, cornersPositions[4].longitude],
        [cornersPositions[2].latitude, cornersPositions[2].longitude],
      ]
    }

    return positions.length > 0 ? (
      <Polygon key={positions[0].id} positions={latLongs as LatLngExpression[]} pathOptions={pathOptions} />
    ) : (
      <></>
    )
  } else {
    const innerCorners = positions.filter(function (x) {
      return (
        parseInt(x.name) === 309 || parseInt(x.name) === 310 || parseInt(x.name) === 311 || parseInt(x.name) === 312
      )
    })

    innerCorners.sort((a, b) => parseInt(a.name) - parseInt(b.name))
    if (innerCorners.length === 4) {
      const latLongs = [
        [innerCorners[0].latitude, innerCorners[0].longitude],
        [innerCorners[1].latitude, innerCorners[1].longitude],
        [innerCorners[3].latitude, innerCorners[3].longitude],
        [innerCorners[2].latitude, innerCorners[2].longitude],
      ]
      return <Polygon key={positions[0].id} positions={latLongs as LatLngExpression[]} pathOptions={pathOptions} />
    }
  }
  return <></>
})
