import { IUniqueCustomer } from 'src/generated-types/customer-unique'
import { IUser } from '../generated-types'
import { ICustomer, ICustomerActivitySummary } from '../generated-types/customer'
import { ILocalityMapDto } from '../generated-types/locality-map-dto'
import { queryClient } from '../query-client'
import { customerQueryKey } from '../query-keys'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useGet } from './reactQuery'

const baseUrl = () => 'customers'
const baseAdminUrl = () => 'admin/customers'

export const customerEndpoints = {
  getUserCustomers() {
    return `${baseUrl()}`
  },
  getCustomerUsers(customerId: EntityId) {
    return `${baseUrl()}/${customerId}/users`
  },
  getCustomerUsersForAdmins(customerId: EntityId) {
    return `${baseUrl()}/${customerId}/admin/users`
  },
  getAdminCustomers(page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) {
    return page != apiConsts.Page || pageSize != apiConsts.PageSize
      ? `${baseAdminUrl()}?page=${page}&pageSize=${pageSize}`
      : `${baseAdminUrl()}`
  },

  getUniqueCustomers() {
    return `${baseAdminUrl()}/uniqueCustomers`
  },

  getCustomerAdministrators(customerId: EntityId) {
    return `${baseUrl()}/${customerId}/administrators`
  },
  getCustomerById(id: EntityId) {
    return `${baseUrl()}/${id}`
  },
  getLocalities() {
    return `${baseUrl()}/localities`
  },
  getCustomersNameWithPermissions() {
    return `${baseUrl()}/name-with-permission`
  },
  getLocalitiesForMap() {
    return `${baseUrl()}/localities-map`
  },
  getLocalityAdministrators(customerId: EntityId) {
    return `${baseUrl()}/${customerId}/locality-administrators`
  },
  getCustomersServiceStationsAdministrators() {
    return `${baseUrl()}/service-stations/`
  },
  getCustomersActivityCounts(customerId) {
    return `${baseUrl()}/${customerId}/activity-counts/`
  }
}

export const invalidateCustomer = {
  getCustomerAdministrators(customerId: EntityId) {
    queryClient.invalidateQueries(customerEndpoints.getCustomerAdministrators(customerId))
  },
  getCustomerLocalities() {
    queryClient.invalidateQueries(customerEndpoints.getLocalities())
  },
  getAdminCustomers(page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) {
    queryClient.invalidateQueries(customerEndpoints.getAdminCustomers(page, pageSize))
  },
  getCustomerUsers(customerId?: EntityId, isWithClientUserLinks?: boolean) {
    queryClient.invalidateQueries(customerQueryKey.GET_CUSTOMER_USERS(customerId, isWithClientUserLinks))
  },
  getCustomerUsersForAdmins(customerId?: EntityId, isWithClientUserLinks?: boolean) {
    queryClient.invalidateQueries(customerQueryKey.GET_CUSTOMER_USERS_FOR_ADMINS(customerId, isWithClientUserLinks))
  },
  getCustomerById(id?: EntityId) {
    queryClient.invalidateQueries(customerQueryKey.GET_CUSTOMER(id))
  },
  getLocalitiesForMap(searchValue?: string, selectedCustomer?: EntityId) {
    queryClient.invalidateQueries(customerQueryKey.GET_LOCALITIES_FOR_MAP(searchValue, selectedCustomer))
  },
  getUniqueAdminCustomers(){
    queryClient.invalidateQueries([customerEndpoints.getUniqueCustomers()])
  },
  getInvalidateCustomersServiceStationDetails() {
    queryClient.invalidateQueries([customerEndpoints.getCustomersServiceStationsAdministrators()])
  },
  getCustomersActivityCounts(customerId: EntityId) {
    queryClient.invalidateQueries([customerEndpoints.getCustomersActivityCounts(customerId)])
  },
}

export const useGetUserCustomers = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<ICustomer>>(customerEndpoints.getUserCustomers(), { page, pageSize })

export const useGetCustomerUsers = (customerId: EntityId, isWithClientUserLinks?: boolean) =>
  useGet<Array<IUser>>(customerEndpoints.getCustomerUsers(customerId), { isWithClientUserLinks }, { queryKey: customerQueryKey.GET_CUSTOMER_USERS(customerId, isWithClientUserLinks) })

export const useGetCustomerUsersForAdmins = (customerId: EntityId, isWithClientUserLinks?: boolean) =>
  useGet<Array<IUser>>(customerEndpoints.getCustomerUsersForAdmins(customerId), { isWithClientUserLinks }, { queryKey: customerQueryKey.GET_CUSTOMER_USERS_FOR_ADMINS(customerId, isWithClientUserLinks) })

export const useGetAdminCustomers = (page?: number, pageSize?: number) =>
  useGet<IPaginatedResult<ICustomer>>(customerEndpoints.getAdminCustomers(page, pageSize))

export const useGetUniqueAdminCustomers = () =>
  useGet<IUniqueCustomer[]>(customerEndpoints.getUniqueCustomers())

export const useGetCustomerById = (id: EntityId) => useGet<ICustomer>(customerEndpoints.getCustomerById(id))

export const useGetCustomerAdministrators = (customerId: EntityId) =>
  useGet<IUser[]>(customerEndpoints.getCustomerAdministrators(customerId))

export const useGetCustomersServiceStationsAdministrators = (searchValue?: string) =>
  useGet<ICustomer[]>(customerEndpoints.getCustomersServiceStationsAdministrators(), { searchKey: searchValue })

export const useGetCustomersLocalities = (searchValue: string) =>
  useGet<ICustomer[]>(customerEndpoints.getLocalities(), { search: searchValue })

export const useGetCustomersNameWithPermissions = () =>
  useGet<ICustomer[]>(customerEndpoints.getCustomersNameWithPermissions())

export const useGetCustomersLocalitiesForMap = (searchValue: string, selectedCustomer: EntityId) =>
  useGet<ILocalityMapDto[]>(customerEndpoints.getLocalitiesForMap(), {
    search: searchValue,
    customerId: selectedCustomer,
  },{queryKey: customerQueryKey.GET_LOCALITIES_FOR_MAP(searchValue, selectedCustomer)})

export const invalidateCustomersServiceStationDetails = () =>
  queryClient.invalidateQueries(customerEndpoints.getCustomersServiceStationsAdministrators())

export const useGetCustomersActivityCounts = (customerId: EntityId) =>
  useGet<ICustomerActivitySummary[]>(customerEndpoints.getCustomersActivityCounts(customerId))
