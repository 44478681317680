import { LatLngExpression } from 'leaflet'
import { useEffect } from 'react'
import { useMap } from 'react-leaflet'

export const CenterChangeComponent: React.FC<{ center: LatLngExpression }> = ({ center }) => {
  const map = useMap()
  useEffect(() => {
    if (center) {
      map.setView(center)
    }
  }, [center])
  return <></>
}
