import { IPosition, IPositionType } from '../generated-types'
import { midpoint } from '../screens/localities/components/map/utils'

export const mapCenterCal = (positions: IPosition[]) => {
  const corners = positions
    .filter(p => p.type == IPositionType.Corner)
    .sort((a, b) => parseInt(a.name) - parseInt(b.name))
  if (corners.length > 2) {
    return midpoint(
      corners[0].latitude,
      corners[0].longitude,
      corners[corners.length - 1].latitude,
      corners[corners.length - 1].longitude,
      0.5,
    )
  } else if (corners.length == 1) {
    return [corners[0].latitude, corners[0].longitude]
  }
}
