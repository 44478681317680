import { useTranslation } from 'react-i18next'
import { LayerGroup, LayersControl, useMap } from 'react-leaflet'
import { ILocality, IMooring, IMooringType, IPosition, IPositionType } from '../../../../../generated-types'
import React, { memo, useCallback } from 'react'
import { IGroupSelector } from '../MapComponent'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../../../../../app-routes'
import { CreateMooring } from './CreateMooring'
import { ICluster } from 'src/generated-types/cluster'
import L from 'leaflet'
import ClusterLayer from './ClusterLayer'

interface ILocalityLayersProps {
  positions?: IPosition[]
  mooringType?: IMooringType
  moorings?: IMooring[]
  localities?: ILocality[]
  mapPosition: L.LatLngExpression
  groupSelector: IGroupSelector
  navigateLocalityWhenClick?: boolean
  navigate?: (path: string) => void
  isModalView: boolean
  showCageStatusPopOver?: boolean
  clusters?: ICluster[]
  onPositionSelect?: (positionId: number, positionName: string) => void
}

export const LocalityLayers: React.FC<ILocalityLayersProps> = ({ isModalView, ...rest }) => {
  if (isModalView) {
    return <GenericLocalityLayer {...rest} isModalView={isModalView} />
  }
  return <LocalityLayersWithNavigation {...rest} isModalView={isModalView} />
}

const LocalityLayersWithNavigation: React.FC<ILocalityLayersProps> = props => {
  const navigate = useNavigate()
  return <GenericLocalityLayer {...props} navigate={navigate} />
}

const GenericLocalityLayer: React.FC<ILocalityLayersProps> = memo(
  ({
    positions,
    mooringType,
    mapPosition,
    moorings,
    localities,
    groupSelector,
    navigateLocalityWhenClick,
    navigate,
    isModalView,
    showCageStatusPopOver,
    onPositionSelect,
    clusters,
  }) => {
    const { t } = useTranslation(['map', 'common'])
    const map = useMap()

    const createLayerGroup = useCallback(
      (type: IPositionType) => {
        if (positions) {
          return (
            <LayerGroup>
              <CreateMooring
                mooring={
                  {
                    positions: positions,
                    type: mooringType,
                    deviationsCount: positions.map(x => x.deviationsCount).reduce((a, b) => a + b),
                  } as IMooring
                }
                type={type}
                mapPosition={mapPosition}
                isModalView={isModalView}
                showCageStatusPopOver={showCageStatusPopOver}
                onPositionSelect={onPositionSelect}
              />
            </LayerGroup>
          )
        }
        if (moorings) {
          return (
            <LayerGroup>
              {moorings.map((currentMooring, index) => {
                return (
                  <CreateMooring
                    mooring={currentMooring}
                    type={type}
                    key={index}
                    mapPosition={mapPosition}
                    isFleet={currentMooring.type === IMooringType.Barge}
                    isModalView={isModalView}
                    showCageStatusPopOver={showCageStatusPopOver}
                    onPositionSelect={onPositionSelect}
                  />
                )
              })}
            </LayerGroup>
          )
        }
        if (localities) {
          return (
            <LayerGroup>
              {localities.map(locality =>
                locality.moorings.map((currentMooring, index) => {
                  return (
                    <CreateMooring
                      navigateLocality={
                        navigateLocalityWhenClick
                          ? () => {
                              navigate &&
                                navigate(AppRoutesNavigation.Localities.locality(locality?.customerId, locality?.id))
                            }
                          : undefined
                      }
                      mooring={currentMooring}
                      type={type}
                      key={index}
                      clusters={clusters}
                      mapPosition={mapPosition}
                      isModalView={isModalView}
                      isFleet={currentMooring.type === IMooringType.Barge}
                      showCageStatusPopOver={showCageStatusPopOver}
                      onPositionSelect={onPositionSelect}
                    />
                  )
                }),
              )}

              {clusters?.map((cluster, index) => {
                return <ClusterLayer key={index} cluster={cluster} />
              })}
            </LayerGroup>
          )
        }
      },
      [positions, moorings, localities, map, clusters],
    )

    return (
      <>
        <LayersControl.Overlay name={`<b><u>${t('localities')}</u></b>`} key={1} checked={groupSelector.facility}>
          <LayerGroup></LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name={`<font color='green'>${t('frame-lines')}</font>`} checked={groupSelector.facility}>
          {createLayerGroup(IPositionType.FrameLines)}
        </LayersControl.Overlay>
        <LayersControl.Overlay name={`<font color='#989769'>${t('bridles')}</font>`} checked={groupSelector.facility}>
          {createLayerGroup(IPositionType.Bridle)}
        </LayersControl.Overlay>
        <LayersControl.Overlay
          name={`<font color='gold'>${t('mooring-lines')}</font>`}
          checked={groupSelector.facility}
        >
          {createLayerGroup(IPositionType.MooringLine)}
        </LayersControl.Overlay>
        <LayersControl.Overlay name={`<font color='goldenrod'>${t('corners')}</font>`} checked={groupSelector.facility}>
          {createLayerGroup(IPositionType.Corner)}
        </LayersControl.Overlay>
        <LayersControl.Overlay
          name={`<font color='grey'>${t('cages')}</font><div class='leaflet-control-layers-separator'></div>`}
          checked={groupSelector.facility}
        >
          {createLayerGroup(IPositionType.Cage)}
        </LayersControl.Overlay>
      </>
    )
  },
)
