import { memo, useEffect, useState } from 'react'
import { IPosition, IMooring, ILocality } from '../../../../../generated-types'
import { HeatLayer } from './HeatLayer'
import { mapCenterCal } from '../../../../../utils/mapCenterCal'
import { localityDeviationMultiplier } from '../constraints'

export const LocalityDeviations: React.FC<{
  positions?: IPosition[]
  moorings?: IMooring[]
  localities?: ILocality[]
}> = memo(({ positions, moorings, localities }) => {
  const [localityDeviationPositions, setLocalityDeviationPositions] = useState<number[][]>([])
  useEffect(() => {
    if (positions) {
      const deviationsCount = positions.map(p => p.deviationsCount).reduce((a, b) => a + b, 0)
      if (deviationsCount > 0) {
        setLocalityDeviationPositions([
          [
            ...((mapCenterCal(positions as IPosition[]) as number[]) ?? []),
            deviationsCount * localityDeviationMultiplier,
          ],
        ])
      }
    }
  }, [positions])

  useEffect(() => {
    if (moorings) {
      const deviatedMoorings = moorings.filter(x => x.deviationsCount > 0)
      const deviationPositions: number[][] = []
      if (deviatedMoorings.length > 0) {
        deviatedMoorings.forEach(mooring => {
          deviationPositions.push([
            ...((mapCenterCal(mooring.positions as IPosition[]) as number[]) ?? []),
            mooring.deviationsCount * localityDeviationMultiplier,
          ])
        })
      }
      setLocalityDeviationPositions(deviationPositions)
    }
  }, [moorings])

  useEffect(() => {
    if (localities) {
      const deviatedMoorings =
        localities
          ?.map(x => x.moorings)
          .flat()
          .filter(x => x.deviationsCount > 0) ?? []
      const deviationPositions: number[][] = []
      if (deviatedMoorings.length > 0) {
        deviatedMoorings.forEach(mooring => {
          deviationPositions.push([
            ...((mapCenterCal(mooring.positions as IPosition[]) as number[]) ?? []),
            mooring.deviationsCount * 500,
          ])
        })
      }
      setLocalityDeviationPositions(deviationPositions)
    }
  }, [localities])
  return (
    <>
      <HeatLayer deviationPositions={localityDeviationPositions} layerRadius={50} />
    </>
  )
})
