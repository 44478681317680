import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { invalidateComponentsCache } from '../../../../api/components'
import { invalidatePositionCache, useUpdatePositionDrawing } from '../../../../api/positions'
import { UpdatePositionPositionDrawing } from '../../../../generated-types/position'
import { ShowDialogProps } from '../../../../show-dialog'

import { IPositionType } from '../../../../generated-types'
import { SelectPositionDrawingForm, positionDrawingSchema } from './SelectPositionDrawingForm'
import { IPositionDrawing } from '../../../../generated-types/position-drawing'

export type PositionDrawingModalFormType = z.infer<typeof positionDrawingSchema>

interface PositionDrawingModalProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  positionId?: number
  positionDrawingId: EntityId
  positionType: IPositionType
  setSelectedPositionDrawing?: React.Dispatch<React.SetStateAction<IPositionDrawing | undefined>>
  isUpdateForm?: boolean | undefined
  selectedPositionDrawing?: IPositionDrawing
}

export const AddPositionDrawingModalComponent: React.FC<PositionDrawingModalProps> = ({
  pop,
  customerId,
  localityId,
  mooringId,
  positionId,
  positionDrawingId,
  positionType,
  setSelectedPositionDrawing,
  isUpdateForm,
  selectedPositionDrawing,
}) => {
  const { t } = useTranslation(['position', 'common'])
  const { mutateAsync } = useUpdatePositionDrawing(
    customerId,
    localityId as EntityId,
    mooringId as EntityId,
    positionId as EntityId,
  )
  const onSubmit = useCallback(
    async (form: PositionDrawingModalFormType) => {
      await mutateAsync(
        {
          positionDrawingId: form.positionDrawing?.id ?? null,
          // Replacing products when position drawing is updating(This is because due to products are adding into products table)
          replaceComponents: !!positionDrawingId || isUpdateForm,
        } as UpdatePositionPositionDrawing,
        {
          onSuccess: () => {
            invalidatePositionCache.getPositions(customerId, localityId, mooringId)
            invalidatePositionCache.getPosition(customerId, localityId, mooringId, positionId as EntityId)
            invalidateComponentsCache.getComponents(customerId, positionId as EntityId)
            invalidatePositionCache.getPositionsWithMinimalData(
              customerId as EntityId,
              localityId as EntityId,
              mooringId as EntityId,
            )
            pop(true)
          },
        },
      )
    },
    [customerId, localityId, mooringId, mutateAsync, pop, positionDrawingId, positionId, t],
  )

  return (
    <SelectPositionDrawingForm
      onSubmit={onSubmit}
      onCancel={() => pop()}
      positionDrawingId={positionDrawingId}
      positionType={positionType}
      setSelectedPositionDrawing={setSelectedPositionDrawing}
      isUpdateForm={isUpdateForm}
      customerId={customerId}
      mooringId={mooringId}
      selectedPositionDrawing={selectedPositionDrawing}
    />
  )
}
