/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme } from '@mui/material'
export enum MUIThemeColors {
  primaryLight = 'primaryLight',
  primaryMain = 'primaryMain',
  primaryDark = 'primaryDark',
  secondaryLight = 'secondaryLight',
  secondaryMain = 'secondaryMain',
  secondaryDark = 'secondaryDark',
  errorLight = 'errorLight',
  errorMain = 'errorMain',
  errorDark = 'errorDark',
  warningLight = 'warningLight',
  warningMain = 'warningMain',
  warningDark = 'warningDark',
  infoLight = 'infoLight',
  infoMain = 'infoMain',
  infoDark = 'infoDark',
  successLight = 'successLight',
  successMain = 'successMain',
  successDark = 'successDark',
  white = 'white',
  black = 'black',
  inputSearch = 'inputSearch',
  backgroundMain = 'backgroundMain',
  paleBlue='paleBlue'
}

export interface SharedColorOverrides {
  primaryLight: true
  primaryMain: true
  primaryDark: true
  secondaryLight: true
  secondaryMain: true
  secondaryDark: true
  errorLight: true
  errorMain: true
  errorDark: true
  warningLight: true
  warningMain: true
  warningDark: true
  infoLight: true
  infoMain: true
  infoDark: true
  successLight: true
  successMain: true
  successDark: true
  white: true,
  backgroundMain: true,
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides extends SharedColorOverrides { }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides extends SharedColorOverrides { }
}

declare module '@mui/material/styles' {
  interface Palette {
    primaryLight: Palette['primary']
    primaryMain: Palette['primary']
    primaryDark: Palette['primary']
    secondaryLight: Palette['secondary']
    secondaryMain: Palette['secondary']
    secondaryDark: Palette['secondary']
    errorLight: Palette['error']
    errorMain: Palette['error']
    errorDark: Palette['error']
    warningLight: Palette['warning']
    warningMain: Palette['warning']
    warningDark: Palette['warning']
    infoLight: Palette['info']
    infoMain: Palette['info']
    infoDark: Palette['info']
    successLight: Palette['success']
    successMain: Palette['success']
    successDark: Palette['success']
    //below colors will be removed in the future
    good: Palette['primary']
    attention: Palette['primary']
    tableRow: Palette['primary']
    silent: Palette['primary']
    black: Palette['primary']
    white: Palette['primary']
    inputSearch: Palette['primary']
    searchIcon: Palette['primary']
    steelBlue: Palette['primary']
    gray70: Palette['primary']
    transparentBlack: Palette['primary']
    lightGray: Palette['primary']
    darkTransparentBlack: Palette['primary']
    navyBlue: Palette['primary']
    orange: Palette['primary']
    veryDarkTransparentBlack: Palette['primary']
    grayishBlue: Palette['primary']
    paleBlue: Palette['primary']
    darkGray: Palette['primary']
    azureBlue: Palette['primary']
    gray: Palette['primary']
    darkBlue: Palette['primary']
    darkBluishGreen: Palette['primary']
    blueGray: Palette['primary']
    activeGreen: Palette['primary']
    turquoiseGreen: Palette['primary']
    skyBlue: Palette['primary']
    cerulean: Palette['primary']
    pink: Palette['primary']
    seafoamGreen: Palette['primary']
    cadetBlue: Palette['primary']
    veryDarkGray: Palette['primary']
    inactiveRed: Palette['primary'],
    backgroundMain: Palette['primary']
  }

  interface PaletteOptions {
    primaryLight: PaletteOptions['primary']
    primaryMain: PaletteOptions['primary']
    primaryDark: PaletteOptions['primary']
    secondaryLight: PaletteOptions['secondary']
    secondaryMain: PaletteOptions['secondary']
    secondaryDark: PaletteOptions['secondary']
    errorLight: PaletteOptions['error']
    errorMain: PaletteOptions['error']
    errorDark: PaletteOptions['error']
    warningLight: PaletteOptions['warning']
    warningMain: PaletteOptions['warning']
    warningDark: PaletteOptions['warning']
    infoLight: PaletteOptions['info']
    infoMain: PaletteOptions['info']
    infoDark: PaletteOptions['info']
    successLight: PaletteOptions['success']
    successMain: PaletteOptions['success']
    successDark: PaletteOptions['success']
    //below colors will be removed in the future
    good?: PaletteOptions['primary']
    attention?: PaletteOptions['primary']
    tableRow?: PaletteOptions['primary']
    silent?: PaletteOptions['primary']
    black?: PaletteOptions['primary']
    white?: PaletteOptions['primary']
    inputSearch?: PaletteOptions['primary']
    searchIcon?: PaletteOptions['primary']
    steelBlue?: PaletteOptions['primary']
    gray70?: PaletteOptions['primary']
    transparentBlack?: PaletteOptions['primary']
    lightGray?: PaletteOptions['primary']
    darkTransparentBlack?: PaletteOptions['primary']
    navyBlue?: PaletteOptions['primary']
    orange?: PaletteOptions['primary']
    veryDarkTransparentBlack?: PaletteOptions['primary']
    grayishBlue?: PaletteOptions['primary']
    paleBlue?: PaletteOptions['primary']
    darkGray?: PaletteOptions['primary']
    azureBlue?: PaletteOptions['primary']
    gray?: PaletteOptions['primary']
    darkBlue?: PaletteOptions['primary']
    darkBluishGreen?: PaletteOptions['primary']
    blueGray?: PaletteOptions['primary']
    activeGreen?: PaletteOptions['primary']
    turquoiseGreen?: PaletteOptions['primary']
    skyBlue?: PaletteOptions['primary']
    cerulean?: PaletteOptions['primary']
    pink?: PaletteOptions['primary']
    seafoamGreen?: PaletteOptions['primary']
    cadetBlue?: PaletteOptions['primary']
    veryDarkGray?: PaletteOptions['primary']
    inactiveRed?: PaletteOptions['primary'],
    backgroundMain?: PaletteOptions['primary']
  }
}

export const muiTheme = createTheme({
  typography: {
    fontFamily: ['poppins'].join('.'),
    h1: { fontSize: '44px', fontWeight: 'bold' },
    h2: { fontSize: '36px', fontWeight: 'bold' },
    h3: { fontSize: '32px', fontWeight: 'bold' },
    h4: { fontSize: '28px', fontWeight: 'bold' },
    h5: { fontSize: '24px', fontWeight: 'bold' },
    h6: { fontSize: '21px', fontWeight: 600 },
    subtitle1: { fontSize: '18px', fontWeight: 400 },
    subtitle2: { fontSize: '16px', fontWeight: 400 },
    body1: { fontSize: '14px', fontWeight: '600' },
    body2: { fontSize: '12px', fontWeight: '600' },
    caption: { fontSize: '11px', fontWeight: '600' },
  },
  palette: {
    mode: 'light',
    primaryMain: {
      light: '#00526B',
      main: '#00526B',
    },
    primaryLight: {
      main: '#0074B2',
      light: '#0074B2',
    },
    primaryDark: {
      main: '#00304A',
      light: '#00304A',
    },
    secondaryMain: {
      main: '#838383',
      light: '#838383',
    },
    secondaryLight: {
      main: '#E8E8E8',
      light: '#E8E8E8',
    },
    secondaryDark: {
      main: '#606060',
      light: '#606060',
    },
    errorMain: {
      main: '#D32F2F',
      light: '#D32F2F',
    },
    errorLight: {
      main: '#EF5350',
      light: '#EF5350',
    },
    errorDark: {
      main: '#C62828',
      light: '#C62828',
    },
    warningMain: {
      main: '#ED6C02',
      light: '#ED6C02',
    },
    warningLight: {
      main: '#FF9800',
      light: '#FF9800',
    },
    warningDark: {
      main: '#E65100',
      light: '#E65100',
    },
    infoMain: {
      main: '#6450FF',
      light: '#6450FF',
    },
    infoLight: {
      main: '#D8F0FC',
      light: '#D8F0FC',
    },
    infoDark: {
      main: '#9678A7',
      light: '#9678A7',
    },
    successMain: {
      main: '#2E7D32',
      light: '#2E7D32',
    },
    successLight: {
      main: '#4CAF50',
      light: '#4CAF50',
    },
    successDark: {
      main: '#1B5E20',
      light: '#1B5E20',
    },
    backgroundMain: {
      main: '#F1F5F7',
      light: '#F1F5F7',
    },
    primary: {
      main: '#00526B',
      light: '#498192',
    },
    secondary: {
      main: '#838383',
    },
    background: {
      default: '#E9F0F8',
      paper: '#FFFFFF',
    },
    warning: {
      main: '#E96B87',
      light: '#FF0000',
      '700': '#EF5350',
    },
    good: {
      main: '#7AC7A1',
      light: '#5FE6A3',
      '700': '#4CAF50',
    },
    attention: {
      main: '#F5D98B',
      light: '#FFCD43',
      '700': '#FFB400',
    },
    tableRow: {
      main: '#E6F8FF',
    },
    silent: {
      main: '#F5F5F5',
      light: '#F5F5F5',
    },
    black: {
      main: '#000000',
      light: '#000000',
    },
    white: {
      main: '#ffffff',
      light: '#ffffff',
    },
    inputSearch: {
      main: '#F1F5F7',
      light: '#F1F5F7'
    },
    searchIcon: {
      main: '#99B2C6',
    },
    steelBlue: {
      main: '#4682B4',
    },
    gray70: {
      main: '#707070',
    },
    transparentBlack: {
      main: '#00000040',
      light: '#00000040',
    },
    lightGray: {
      main: '#EBEBEB',
      light: '#EBEBEB',
    },
    darkTransparentBlack: {
      main: '##0000000F',
      light: '##0000000F'
    },
    navyBlue: {
      main: '#00416A',
      light: '#00416A'
    },
    orange: {
      main: '#FF7200',
    },
    veryDarkTransparentBlack: {
      main: '#00000099',
      light: '#00000029',
    },
    grayishBlue: {
      main: '#F1F4F9',
    },
    paleBlue: {
      main: '#D9E1E7',
      light: '#D9E1E7',
    },
    darkGray: {
      main: '#707070',
    },
    azureBlue: {
      main: '#0274B2',
    },
    gray: {
      main: '#C1C1C1',
    },
    darkBlue: {
      main: '#003E65',
    },
    darkBluishGreen: {
      main: '#384455',
    },
    blueGray: {
      main: '#809fb8',
      light: '#809fb8',
    },
    activeGreen: {
      main: '#1AD5984D',
    },
    turquoiseGreen: {
      main: '#1AD598',
    },
    skyBlue: {
      main: '#87CEEB',
    },
    cerulean: {
      main: '#0074B24D',
    },
    pink: {
      main: '#E96B874D',
    },
    seafoamGreen: {
      main: '#7AC7A14D',
    },
    cadetBlue: {
      main: '#809FB8',
      light: '#809FB8'
    },
    veryDarkGray: {
      main: '#484848',
    },
    inactiveRed: {
      main: '#F9C3C4',
    },
  }
})
