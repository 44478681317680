import Box from '@mui/material/Box'
import { useFormFieldError } from '../../components/FormCore'
import { SelectField } from '../../components/SelectField'
import { t as translate } from 'i18next'
import { IDeviationKeywords } from '../../generated-types/deviation-keywords'
import { useController } from 'react-hook-form'

export default function KeywordsSelectField() {
  const { hasError, message } = useFormFieldError('entries')
  const { field } = useController({ name: 'keywords' })

  const deviationKeywords = Object.keys(IDeviationKeywords)
    .filter(v => isNaN(Number(v)))
    .map(t => IDeviationKeywords[t])

  return (
    <SelectField
      name={'keywords'}
      data={deviationKeywords}
      multiple
      label={translate('keywords', { ns: 'common' })}
      helperText={message}
      error={hasError}
      onChange={(_, v) => field.onChange(v)}
      getOptionLabel={option => translate(IDeviationKeywords[option], { ns: 'deviation' })}
      renderOption={(props: any, option) => (
        <Box {...props} key={option.id}>
          {translate(IDeviationKeywords[option], { ns: 'deviation' })}
        </Box>
      )}
      isOptionEqualToValue={(o, v) => o === v}
      defaultValue={field.value ? field.value : []}
    />
  )
}
