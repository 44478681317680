import { LayerGroup, WMSTileLayer } from 'react-leaflet'
import { agricultureZones } from './constraints'

export const AquacultureRegistry: React.FC = () => {
  return (
    <LayerGroup>
      <WMSTileLayer
        url={agricultureZones.agricultureRegistryMapServer}
        layers="flate_ihht_akvakulturregisteret"
        transparent={true}
        zIndex={500}
        format="image/png"
        pane="wms-pane"
      />
      <WMSTileLayer
        url={agricultureZones.agricultureRegistryGeoServer}
        layers="mast:view_3_all_sites"
        transparent={true}
        zIndex={500}
        format="image/png"
        pane="wms-pane"
      />
    </LayerGroup>
  )
}
