import { DeleteOutline } from '@mui/icons-material'
import { Box } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import { useMemo } from 'react'
import { AuthorizationType } from 'src/enums/auth'
import { IFormSchemaDto, IFormSchemaReport } from 'src/generated-types/form-schema'
import { Spinner } from '../Spinner'
import GenericTable from '../table/GenericTable'
import ReportViewer from './ReportViewer'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { ITaskFormSchema } from 'src/generated-types/taskFormSchema'
import { GridActionButton } from '../common/GridActionButton'
import { getUserDetail, UserDetail } from '@lovoldsystem/core.client'
import { getFormattedDate } from 'src/utils'

interface IOverallReportListContainer {
  onComplete: (row: any) => void
  disabled?: boolean
  bulkUploadLoading?: number
  createPermissions: number[]
  context?: React.Context<any> | null
  handleExport: (row: any) => void
  data: IFormSchemaDto[] | undefined
  requiredReports?: ITaskFormSchema[]
  deletePermissions: number[]
  handleDelete: (id: number) => void
  isLoading: boolean
  height?: number | string
}

const OverallReportListContainer: React.FC<IOverallReportListContainer> = ({
  deletePermissions,
  handleDelete,
  isLoading,
  height,
  data,
  requiredReports,
  onComplete,
  disabled,
  handleExport,
  bulkUploadLoading,
  context,
  createPermissions,
}) => {
  const { showDialog } = useDialogCtx()

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'name',
      headerName: t('name', { ns: 'common' }),
      flex: 1,
      renderCell: ({ row }) => row?.formSchemaVersion?.formSchema?.name,
    },
    {
      field: 'description',
      headerName: t('description', { ns: 'common' }),
      flex: 1,
      renderCell: ({ row }) => row?.formSchemaVersion?.formSchema?.description,
    },
    {
      field: 'createdBy',
      headerName: t('createdBy', { ns: 'common' }),
      flex: 1,
      renderCell: ({ row }) => (row?.createdByUser ? getUserDetail(row?.createdByUser, UserDetail.FullName) : ''),
    },
    {
      field: 'createdAt',
      headerName: t('createdAt', { ns: 'common' }),
      minWidth: 170,
      renderCell: ({ row }) =>
        row?.createdAt ? getFormattedDate(new Date(row?.createdAt + 'Z'), 'YYYY-MM-DD, h:mm a') : '',
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      renderCell: params => {
        const row: IFormSchemaReport = params.row
        return row?.createdByUser == undefined ? (
          <GridActionButton
            variant="outlined"
            sx={{ paddingX: '5px' }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              onComplete(row?.formSchemaVersion?.formSchema)
            }}
            disabled={disabled}
            buttonText={t('complete')}
            height={'30px'}
          />
        ) : bulkUploadLoading == row.id ? (
          <Spinner size={40} />
        ) : (
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} width={180}>
            <GridActionButton
              onClick={async e => {
                e.preventDefault()
                e.stopPropagation()
                handleDelete(row.id as number)
              }}
              context={context}
              permissionBinaryValues={deletePermissions}
              authorizationType={AuthorizationType.Disabled}
              disabled={disabled}
              color="primaryMain"
              buttonIcon={<DeleteOutline sx={{ fontSize: '20px' }} />}
              height="32px"
              width="32px"
            />
          </Box>
        )
      },
    },
  ]

  const rows = useMemo(() => {
    let returnArray: IFormSchemaReport[] = []
    data?.map((s, index, array) => {
      if (s.formSchemaReports && s.formSchemaReports.length > 0) {
        returnArray = [...returnArray, ...s.formSchemaReports]
      } else {
        return array
      }
    }) || []
    if (requiredReports) {
      const requiredArray = requiredReports
        ?.map(d => {
          const formSchema = data?.find(r => r?.id == d?.formSchemaId)
          return { id: d.id, formSchemaVersion: formSchema?.formSchemaVersion, values: '' } as IFormSchemaReport
        })
        .filter(
          ra => !returnArray?.find(r => r?.formSchemaVersion?.formSchemaId == ra?.formSchemaVersion?.formSchema?.id),
        )

      return requiredArray?.concat(returnArray)
    }
    return returnArray
  }, [data])

  return (
    <GenericTable
      id={'overall-report-table'}
      columns={STATIC_COLUMNS}
      data={rows}
      loading={isLoading}
      autoHeight={false}
      getDetailPanelHeight={() => 'auto'}
      height={height}
      getRowHeight={() => 40}
      onRowClick={({ row }) => {
        const value = row as IFormSchemaReport
        if (value?.createdAt) {
          showDialog(ReportViewer, {
            componentProps: {
              layouts: JSON.parse(value?.formSchemaVersion?.layout || ''),
              schema: value.formSchemaVersion?.formSchema,
              report: value,
              disabled: disabled,
              createdAt: value?.createdAt,
              createdByUser: value?.createdByUser,
            },
            opts: {
              maxWidth: 'lg',
              fullWidth: true,
            },
          })
        }
      }}
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
    />
  )
}

export default OverallReportListContainer
